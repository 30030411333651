<template>
    <div class="workers-index">
        <welcome-panel>
            <div class="display-3">Employee RAMS Signatures System</div>
            <p class="lead">Welcome {{userName}} to the Employee RAMS Signatures System</p>
        </welcome-panel>
        <br />

        <div class="counters">

            <div class="counter">
                <div class="title">Inductions</div>
                <div>{{pendingRams}} pending</div>
                <div>{{completedRams}} completed</div>
                <div><span class="button" @click="goToMyRams">Go to My RAMS</span></div>
            </div>

            <div class="counter">
                <div class="title">Inductions & Questions</div>
                <div>{{pendingSop}} pending</div>
                <div>{{completedSop}} completed</div>
                <div><span class="button" @click="goToMySop">Go to My SOP</span></div>
            </div>

        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                counters: {},
            };
        },
        async mounted() {
            await this.loadCounters();
        },
        computed: {
            ...mapState('security', ['userName']),
            pendingRams() {
                return (this.counters.pendingRams ? this.counters.pendingRams : 0);
            },
            completedRams() {
                return (this.counters.completedRams ? this.counters.completedRams : 0);
            },
            pendingSop() {
                return (this.counters.pendingSop ? this.counters.pendingSop : 0);
            },
            completedSop() {
                return (this.counters.completedSop ? this.counters.completedSop : 0);
            },
        },
        methods: {
            async loadCounters() {
                var result = await this.$store.dispatch('mywork/getCounters');
                if (result) this.counters = result;
            },
            goToMyRams() {
                this.$router.push('/mywork/rams');
            },
            goToMySop() {
                this.$router.push('/mywork/sop');
            },
        }
    }
</script>
<style>
    .workers-index .counters {
        display: flex;
        justify-content: center;
    }
    .workers-index .counter {
        width: 10rem;
        display: flex;
        flex-direction: column;
        margin: 0.5rem;
        border-radius: 1rem;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: rgba(255,255,255,0.7);
        text-align: center;
        font-size: large;
        font-family: Calibri, Arial;
        line-height: 2.5rem;
    }
    .workers-index .counter .title {
        border-radius: 0.5rem 0.5rem 0 0;
        background-color: #8B9870;
        font-size: x-large;
        font-weight: bold;
        color: #F2F7E8;
        height: 5rem;
        display: flex;
        align-items:center;
        justify-content: center;
    }

    .workers-index .counter .button {
        background-color: #8B9870;
        border-radius: 10px;
        padding: 5px 10px;
        cursor: pointer;
        margin: 1rem;
        color: #F2F7E8;
        font-size: medium;
        line-height: 1rem;
        display: inline-block;
    }

    .workers-index .counter .button:hover {
        background-color: #DBE8C0;
        font-weight: normal;
        color: #4d4d4d;
    }

/*    .workers-rams .header-items-bar {
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
        background-color: rgba(255,255,255,0.4);
        align-items: center;
        flex-wrap: wrap;
    }

    .workers-rams .header-items-bar .control {
        margin: 0 0.5rem;
    }

    .workers-rams .header-items-bar table,
    .workers-rams .header-items-bar button {
        margin: 0.5rem;
    }


    .workers-rams .header div {
        display: inline-block;
    }

    .input-icon {
        width: 1.5rem;
        height: 1.5rem;
        overflow: visible;
    }
    .input-col {
        padding: 0 !important;
    }
    .square-icon {
        color: darkgray;
    }
    .check-green-icon {
        color: forestgreen;
    }
    .check-gray-icon {
        color: darkgray;
    }
    .items span {
        margin-left: 0.2rem;
        display: inline-block;
    }
    .rams-selected {
        margin-left: auto;
    }*/
</style>
