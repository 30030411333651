<template>
    <div :class="{ 'signatures-rams': true, 'read-only': !hasWriteRights }">
        <h1 class="header-title">RAMS Signatures</h1>

        <div class="header-items-bar">

            <b-input-group size="sm" prepend="Filter" class="signature-filter">
                <b-form-input size="sm" v-model="ramFilterText" placeholder="rams" title="enter rams filter" @keyup.enter.native="filterClick" />
                <b-form-input size="sm" v-model="employeeFilterText" placeholder="employee" title="enter employee filter" @keyup.enter.native="filterClick" />
                <b-form-input size="sm" v-model="jobPositionFilterText" placeholder="job position" title="enter job position filter" @keyup.enter.native="filterClick" />
                <b-form-input size="sm" v-model="departmentFilterText" placeholder="department" title="enter department filter" @keyup.enter.native="filterClick" />
                <b-input-group-append>
                    <b-btn @click.stop="filterClick">Go</b-btn>
                </b-input-group-append>
            </b-input-group>

            <b-form-checkbox id="showLeavers" v-model="showLeavers" :value="true" :unchecked-value="false" @change="showLeaversClick">Show leavers</b-form-checkbox>

            <div class="signature-types">
                <table>
                    <tr>
                        <td class="valid-signature">Signed</td>
                        <td class="required-no-valid-signature">Mandatory not signed or expired</td>
                        <!--<td class="recommended-no-valid-signature">Recommended not signed or expired</td>-->
                        <td class="not-required-signature">Not required</td>
                    </tr>
                </table>
            </div>

        </div>

        <div class="employee-assignments-message-text"><span v-if="hasWriteRights">Click a cell to edit the signature date.</span> Employee assignments are marked with 'e' at bottom-right corner.</div>

        <div id="signatures-table" class="fixed-table-container">
            <table>
                <thead>
                    <tr class="header even-row">
                        <th v-for="(cell, index) in matrix.header" :key="'h' + index" :class="cell.css" v-html="cell.html"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in matrix.rows" :key="'r' + index" :class="row.css">
                        <td v-for="(cell, index) in row.cells" :key="'c' + index" @click.stop="clickCell(cell)" :class="cell.css" v-html="cell.html"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <b-input-group size="sm" prepend="Page size" class="table-footer">
            <b-form-select id="pageSize" size="sm" :options="pageOptions" v-model="perPage" @change="paginationChanged" />
            <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" @change="paginationChanged" />
            <div class="footer-span"><span v-if="hasWriteRights && itemsChangedCount > 0">RAMS signatures changed: {{itemsChangedCount}}</span></div>
            <button v-if="hasWriteRights" type="button" class="btn btn-secondary btn-sm" @click="discardChanges" :disabled="itemsChangedCount == 0">Discard changes</button>
            <button v-if="hasWriteRights" type="button" class="btn btn-primary btn-sm" @click="saveChanges" :disabled="itemsChangedCount == 0">Save changes</button>
        </b-input-group>

        <simple-modal ref="enterSignatureDate" :key="'enter-signature-date'" headerTitle="Enter signature date" @save="clickOk" dialog-class="signatures-rams">
            <template slot="modal-content">
                <control-date label="Signature Date" placeholder="Pick a signature date" v-model="editSignatureDate" :has-errors="showErrors && isFutureDate"></control-date>
                <div v-if="showErrors && editSignatureDate && isFutureDate" class="error">The signature date can't be in the future.</div>
                <div class="reinduction-message" v-if="editRam && editRam.requiresReinduction && editRam.reinductionMonths">This RAMS requires reinduction each {{editRam.reinductionMonths}} months.</div>
            </template>
        </simple-modal>

    </div>
</template>

<script>
    //import { mapState } from 'vuex';
    import 'core-js/web/immediate';
    import models from '../shared/models.js';

    export default {
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                pageOptions: [5, 10, 20, 50, 100],
                matrix: { header: [], rows: [] },
                itemsChangedCount: 0,
                editSignatureDate: null,
                editItem: null,
                showErrors: false,
                ramFilterText: '',
                employeeFilterText: '',
                jobPositionFilterText: '',
                departmentFilterText: '',
                ramFilter: '',
                employeeFilter: '',
                jobPositionFilter: '',
                departmentFilter: '',
                showLeavers: false,
                changedItems: {},
                assignmentLevels: [],
                rams: [],
                sortedRams: [],
                signatures: [],
                hasWriteRights: false,
            }
        },
        async created() {
            this.hasWriteRights = await this.checkUserHasWriteRights();
            await Promise.all([
                this.$store.dispatch('getAssignmentLevels'),
                this.$store.dispatch('rams/getRamsDictionary'),
                this.$store.dispatch('rams/getRams'),
                this.loadSignatures(),
            ]).then((values) => {
                this.assignmentLevels = values[0];
                this.rams = values[1];
                this.sortedRams = values[2];
                this.signatures = values[3];
                this.loadMatrix();
            });
        },
        beforeRouteLeave(to, from, next) {
            $virtus.tools.changes.beforeRouteLeave(to, from, next, this.itemsChangedCount > 0);
        },
        computed: {
            //...mapState(['leaverStateId']),
            isFutureDate() {
                return $virtus.tools.dates.isFutureDate(this.editSignatureDate);
            },
            totalRows() {
                return this.filteredRams.length;
            },
            first() {
                return 1 + (this.currentPage - 1) * this.perPage;
            },
            paginatedRams() {
                var rams = [];
                if (this.filteredRams && this.filteredRams.length > 0) {
                    rams = this.filteredRams.slice(this.first - 1, this.first + this.perPage - 1);
                }
                return rams;
            },
            filteredRams() {
                var rams = this.sortedRams || [];
                var ramFilter = this.ramFilter;
                if (ramFilter) {
                    rams = rams.filter(r => { return r.searchName.includes(ramFilter); });
                }
                return rams;
            },
            filteredEmployees() {
                var employees = this.signatures;
                var jobPositionFilter = this.jobPositionFilter;
                var departmentFilter = this.departmentFilter;
                var employeeFilter = this.employeeFilter;
                if (jobPositionFilter || departmentFilter || employeeFilter || !this.showLeavers) {
                    employees = employees.filter(e => {
                        return (!employeeFilter || e.searchName.includes(employeeFilter))
                            && (!jobPositionFilter || e.searchJobPosition.includes(jobPositionFilter))
                            && (!departmentFilter || e.searchDepartment.includes(departmentFilter))
                            && (this.showLeavers || e.shiftId != $virtus.config.LEAVER_SHIFT_ID);
                    });
                }
                return employees;
            },
            editRam() {
                if (this.editItem && this.editItem.ramId && this.rams) {
                    return this.rams[this.editItem.ramId];
                }
                return null;
            },
        },
        methods: {

            async loadSignatures() {
                var items = await this.$store.dispatch('rams/getFullRamSignatures', { showLeavers: this.showLeavers });
                items = items.map((item) => {
                    item.searchName = (item.name ? item.name.toLocaleLowerCase() : '');
                    item.searchJobPosition = (item.jobPosition ? item.jobPosition.toLocaleLowerCase() : '');
                    item.searchDepartment = (item.department ? item.department.toLocaleLowerCase() : '');
                    return item;
                });
                return items;
            },

            clickCell(item) {
                if (!this.hasWriteRights) return;
                if (!item.isHeader) {
                    this.showErrors = false;
                    this.editItem = item;
                    this.editSignatureDate = item.signatureDate;
                    this.$refs.enterSignatureDate.show();
                }
            },

            discardChanges() {
                this.changedItems = {};
                this.itemsChangedCount = 0;
                this.loadMatrix();
                this.showChangesDiscarded();
            },

            showSaveOk() {
                $virtus.tools.messages.showMessage('Success!', 'The RAMS signatures have been saved successfully.');
            },

            showChangesDiscarded() {
                $virtus.tools.messages.showMessage('Changes discarded', 'All changes have been discarded.');
            },

            async saveChanges() {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    let result = await this.$store.dispatch('rams/updateRamSignatures', changedItems);
                    if (result) {
                        this.showSaveOk();
                        this.changedItems = {};
                        this.itemsChangedCount = 0;
                        this.signatures = await this.loadSignatures();
                        this.loadMatrix();
                    }
                }
            },

            async showLeaversClick() {
                var self = this;
                setImmediate(() => {
                    self.loadSignatures().then((value) => {
                        self.signatures = value;
                        self.loadMatrix();
                    });
                }, 0);
            },

            filterClick() {
                this.currentPage = 1;
                this.ramFilter = this.ramFilterText.toLocaleLowerCase();
                this.jobPositionFilter = this.jobPositionFilterText.toLocaleLowerCase();
                this.departmentFilter = this.departmentFilterText.toLocaleLowerCase();
                this.employeeFilter = this.employeeFilterText.toLocaleLowerCase();
                this.loadMatrix();
            },

            paginationChanged() {
                var self = this;
                setImmediate(() => {
                    self.loadMatrix();
                }, 0);
            },

            clickOk() {
                var self = this;
                this.showErrors = true;
                //Enter if there is not date or the date is not in the future (it is a correct date)
                if (!this.editSignatureDate || !this.isFutureDate) {
                    this.$refs.enterSignatureDate.hide();
                    if (!this.editSignatureDate && this.editItem.signatureDate && this.editItem.originalSignatureDate) {
                        $virtus.tools.messages.confirm('Are you sure?', 'You are going to delete the signature date, please confirm the action.', true).then(value => {
                            if (value && value.isConfirmed) {
                                self.changeEditItem();
                            }
                        });
                    } else {
                        this.changeEditItem();
                    }
                }
            },

            changeEditItem() {
                var item = this.editItem;
                var ram = this.rams[this.editItem.ramId];

                var signatureDate = this.editSignatureDate;
                if (signatureDate) {
                    item.signatureDate = signatureDate;
                    item.text = $virtus.tools.dates.formatDate(signatureDate);
                } else {
                    item.text = '';
                    item.signatureDate = null;
                }

                item.html = item.text;
                item.css = 'clickable ' + (item.isEmployeeAssignment ? 'employee-assignment ' : '')
                    + this.getRamSignatureClass(item.assignmentLevelId, item.lastValidIssueDate, signatureDate, ram.reinductionMonths);

                //Set assignment state
                var changedItem = this.changedItems[item.id];
                var changed = (signatureDate != item.originalSignatureDate || item.version != item.originalSignedVersion);

                //Update / create / delete changedItem
                if (changed && changedItem) {
                    //Already changed, update
                    changedItem.signatureDate = signatureDate;
                    changedItem.signedVersion = item.version;
                } else if (!changed && changedItem) {
                    //Not changed, delete changed item
                    this.itemsChangedCount--;
                    delete this.changedItems[item.id];
                } else if (changed && !changedItem) {
                    //Changed now, create changed item
                    this.itemsChangedCount++;
                    this.changedItems[item.id] = {
                        id: item.id,
                        ramId: item.ramId,
                        employeeId: item.employeeId,
                        signatureDate: signatureDate,
                        localSignatureDate: signatureDate, //Store date, other date is changed when sent to server
                        signedVersion: item.version,
                        text: item.text
                    };
                } //Else Not changed, not changed item: do nothing

                //Display changed item
                if (changed) {
                    item.html += '<span class="note"></span>';
                }
            },

            loadMatrix() {
                var self = this;
                $virtus.tools.loader.show();
                var matrix = { header: [], rows: [] };
                var rams = self.paginatedRams;
                var employees = self.filteredEmployees;

                //Create header
                matrix.header.push({
                    id: 0, css: 'head-col-label', html: '<div>&nbsp;</div><div>&nbsp;</div><div><span>Employee</span><span>Job Position</span><span>Department</span></div><div>&nbsp;</div><div>&nbsp;</div>'
                });
                for (var k = 0; k < rams.length; k++) {
                    var ramHeader = rams[k];
                    if (!ramHeader.html) {
                        models.refreshComputedRamFields(ramHeader);
                    }
                    matrix.header.push({
                        id: ramHeader.id,
                        css: ramHeader.css,
                        html: ramHeader.html
                    });
                }

                //Create rows
                for (var i = 0; i < employees.length; i++) {
                    var even = (i % 2);
                    var employee = employees[i];
                    var row = {
                        id: employee.id,
                        css: (even ? 'even-row' : ''),
                        cells: [{
                            id: employee.id,
                            isHeader: true,
                            css: 'head-col',
                            html: '<span title="' + employee.name + '">' + employee.name + '</span><span title="' + employee.jobPosition + '">' + employee.jobPosition + '</span><span title="' + employee.department + '">' + employee.department + '</span>'
                        }]
                    };
                    for (var j = 0; j < rams.length; j++) {
                        var ram = rams[j];
                        var cellId = ram.id + '_' + employee.id;
                        var originalSignatureDate = null, signatureDate = null, assignmentLevelId = null, text = '', html = '', isEmployeeAssignment = false;
                        var signature = employee[ram.id];
                        if (signature) {
                            var data = signature.split(';');
                            if (data.length == 3) {
                                assignmentLevelId = data[0];
                                originalSignatureDate = signatureDate = (data[1] ? new Date(data[1]) : null);
                                isEmployeeAssignment = (data[2] == 'e');
                                text = (signatureDate ? $virtus.tools.dates.formatDate(signatureDate) : '');
                                html = (ram.requiresReinduction && ram.reinductionMonths ? '<div title="Valid until date: ' + $virtus.tools.dates.formatDate(signatureDate, { months: ram.reinductionMonths }) + '">' + text + '</div>' : text);
                            }
                        }

                        var changedItem = self.changedItems[cellId];
                        if (changedItem) {
                            text = changedItem.text;
                            html = changedItem.text + '<span class="note"></span>';
                            signatureDate = changedItem.signatureDate;
                        }

                        var item = {
                            id: cellId,
                            ramId: ram.id,
                            version: ram.version,
                            issueDate: ram.issueDate,
                            lastValidIssueDate: ram.lastValidIssueDate,
                            employeeId: employee.id,
                            jobPositionId: employee.jobPositionId,
                            assignmentLevelId: assignmentLevelId,
                            isEmployeeAssignment: isEmployeeAssignment,
                            signatureDate: signatureDate,
                            originalSignatureDate: originalSignatureDate,
                            originalSignedVersion: (signature ? signature.signedVersion : null),
                            css: 'clickable ' + (isEmployeeAssignment ? 'employee-assignment ' : '') + self.getRamSignatureClass(assignmentLevelId, ram.lastValidIssueDate, signatureDate, ram.reinductionMonths),
                            text: text,
                            html: html,
                        };
                        row.cells.push(item);
                    }
                    matrix.rows.push(row);
                }
                self.matrix = matrix;

                setImmediate(() => {
                    $virtus.tools.fixTable(document.getElementById('signatures-table'));
                    $virtus.tools.loader.close();
                }, 0);
            },

            getRamSignatureClass(assignmentLevelId, issueDate, signatureDate, reinductionMonths) {
                var className = '';
                var isValid = (signatureDate && signatureDate >= issueDate && (!reinductionMonths || $virtus.tools.dates.isFutureDate(signatureDate, { months: reinductionMonths })));
                switch (assignmentLevelId) {
                    case 1, '1': //Required
                        className = (isValid ? 'valid-signature' : 'required-no-valid-signature');
                        break;
                    case 2, '2': //Recommended
                        className = (isValid ? 'valid-signature' : 'recommended-no-valid-signature');
                        break;
                    case 3, '3': //Not required
                        className = 'not-required-signature';
                        break;
                }
                return className;
            },

        }
    }
</script>

<style>
    .signatures-rams .header-items-bar {
        align-items: center;
        margin-bottom: 0;
    }

    .signatures-rams .header-items-bar > * {
        margin-bottom: 5px;
    }

    .signatures-rams .custom-checkbox {
        margin-right: auto;
    }

    .signatures-rams .custom-checkbox .custom-control-label {
        padding-top: 3px;
        padding-right: 10px;
    }

    .signatures-rams .signature-filter {
        width: 28rem;
        margin-right: 10px;
    }

    .signatures-rams .signature-types {
        font-size: x-small;
    }

    .signatures-rams .signature-types td {
        padding: 1px 5px;
    }

    .signatures-rams .table-footer {
        margin-top: 10px;
        align-items: center;
    }

    .signatures-rams .table-footer.input-group > .custom-select,
    .signatures-rams .table-footer > * {
        margin-bottom: 5px;
    }

    .signatures-rams .table-footer .pagination {
        margin-right: auto;
    }

    .signatures-rams .table-footer button.btn-secondary {
    }

    .signatures-rams .table-footer button.btn {
        margin-left: 5px;
    }

    #pageSize {
        flex: none;
        width: 4rem;
    }

    .employee-assignment::after {
        content: 'e';
        font-size: xx-small;
        position: absolute;
        display: inline-block;
        left: 2px;
        bottom: 0;
        margin-bottom: -4px;
    }

    .employee-assignments-message-text {
        font-size: x-small;
        font-style: italic;
    }

    .reinduction {
        font-size: xx-small;
        position: absolute;
        display: inline-block;
        right: 2px;
        bottom: 0;
        margin-bottom: -4px;
        cursor: default;
    }


    .signatures-rams .reinduction-message {
        font-size: x-small;
        font-style: italic;
    }
/*    .signatures-buttons {
        margin-top: 0.25rem;
        text-align: right;
    }*/
</style>
