
var loaderCount = 0;

let loader = {
    show() {
        if (loaderCount <= 0) {
            loaderCount = 0;
            var loader = document.getElementById('loading');
            if (loader) loader.style.display = 'block';
        }
        loaderCount++;
    },

    close() {
        if (loaderCount > 0) {
            loaderCount--;
        }
        if (loaderCount <= 0) {
            var loader = document.getElementById('loading');
            if (loader) loader.style.display = 'none';
        }
    },
    execute(f) {
        this.show();
        let result = f();
        this.close();
        return result;
    }
};

export default loader;
