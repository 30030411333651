//import virtus from '../shared';
//var api = virtus.tools.api;

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {

        //async getHomeCountersInfo({ commit, getters }) {
        //    let result = await api.get('/api/home/counters');
        //    if (result) {
        //        var counters = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0, 24: 0, 25: 0, 26: 0, 27: 0, 28: 0, 29: 0, 30: 0, 31: 0 };
        //        for (var i = 0; i < result.length; i++) {
        //            var item = result[i];
        //            counters[item.counterId] = item.counterValue;
        //        }
        //        result = counters;
        //    }
        //    return result;
        //},

        //async getHomePendingRams({ getters }) {
        //    let result = await api.get('/api/home/pending-rams');
        //    return result;
        //},

        //async getHomePendingSkills({ getters }) {
        //    let result = await api.get('/api/home/pending-skills');
        //    return result;
        //},

        //async getHomePendingSopValidations({ getters }) {
        //    let result = await api.get('/api/home/pending-sop-validations');
        //    return result;
        //},

    }
};
