<template>
    <div class="select-book-training">
        <div class="book-training-button" v-if="readOnly">Training date</div>
        <div class="select-label" v-if="!readOnly">Click to book training (empty date to clear):</div>
        <div class="book-training-bar" v-if="!readOnly">
            <control-date v-model="trainingDate"></control-date>
            <div :class="'book-training-button' + (selected ? ' selected' : '')" @click="clickBookTraining">Book training</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'select-book-training',
        props: ['readOnly'],
        data() {
            return {
                trainingDate: null,
                selected: false,
            }
        },
        async created() {
        },
        computed: {

        },
        watch: {
            trainingDate(/*newDate*/) {
                this.clickBookTraining(true);
            },
        },
        methods: {
            clickBookTraining(select) {
                if (!this.readOnly && select !== false) {
                    this.selected = (select === true || !this.selected);
                } else {
                    this.selected = false;
                }
                this.$emit('click', this.selected ? { trainingDate: this.trainingDate } : null);
            },
            clear() {
                //this.clickBookTraining(false);
                this.selected = false;
            },
        }
    }
</script>

<style>
    .select-book-training .select-label {
        font-size: x-small;
        font-style: italic;
    }

    .select-book-training .book-training-bar {
        display: flex;
    }

    .select-book-training .book-training-bar .form-control {
        font-size: x-small;
        padding: .1rem .5rem;
        height: calc(1.5em + .5rem + 2px);
    }

    .select-book-training .book-training-bar .mx-datepicker {
        width: 6rem;
    }


    .select-book-training .book-training-button {
        padding: .1rem .5rem;
        font-size: x-small;
        margin: 1px;
        position: relative;
        cursor: pointer;
        background-color: darkorange;
    }

    .select-book-training .book-training-button:hover {
        color: black!important;
    }

    .select-book-training .book-training-button.selected::after {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border: 2px solid #6f6f6f;
    }

</style>
