var api = $virtus.tools.api;

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getMonthlySummaryReport(store, data) {
            var url = '/api/admin-reports/monthly-summary?year=' + data.year + '&month=' + data.month + '&download=' + data.download + '&appId=1';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },
        async getTrainingCostsReport(store, data) {
            var url = '/api/admin-reports/training-costs?year=' + data.year + '&download=' + data.download + '&appId=1';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },
    }
};
