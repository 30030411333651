import Vue from 'vue';
import Vuex from 'vuex';
//import virtus from '../shared';
var tools = $virtus.tools;
var api = tools.api;
var cache = tools.cache;
import admin from './admin';
import adminReports from './admin-reports';
import rams from './rams';
import skills from './skills';
import reports from './reports';
import home from './home';
//import visitors from './visitors';
import mywork from './mywork';
import trainingCosts from './training-costs';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        admin,
        adminReports,
        rams,
        skills,
        reports,
        home,
        //visitors,
        mywork,
        trainingCosts,
    },
    state: {
        version: '1.0.11',
        //leaverStateId: 1,
        cache: {},
    //    showViewer: false,
    //    showVideoViewer: false,
    },
    getters: {
        //isIpadUser(state, getters, rootState, rootGetters) {
        //    var userId = rootGetters['security/getUserId'];
        //    return (userId == 21);
        //},
        //isVisitorsUser(state, getters, rootState, rootGetters) {
        //    var userId = rootGetters['security/getUserId'];
        //    return (userId == 22);
        //},
    },
    mutations: {
    },
    actions: {
        async sendContactForm(store, data) {
            let result = await api.post('/api/help/send-contact-form', data);
            return result;
        },

        async getAssignmentLevels({ state }) {
            var items = cache.get(state, 'getAssignmentLevels');

            if (!items) {
                items = await api.get('/api/assignmentlevels');
                cache.set(state, 'getAssignmentLevels', items);
            }
            return items;
        },
    },
});
