<template>
    <div class="main-form monthly-summary">
        <h1 class="header-title">Monthly RAMS SKILLS Summary</h1>
        <div>
            <p>
                This report shows a summary of rams and skills for the selected month and year.
            </p>

            <control-select label="Year" :options="years" v-model="year"></control-select>
            <control-select label="Month" :options="months" v-model="month"></control-select>
        </div>
        <div v-show="showErrors && missingDate" class="error">Year and Month fields are mandatory.</div>
        <div v-show="showErrors && dateInFuture" class="error" >The date can't be in future.</div>
        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="download">Download</button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                year: null,
                month: null,
                showErrors: false
            }
        },
        async created() {
            var lastMonth = this.today.minus({ months: 1 });
            this.year = lastMonth.year;
            this.month = lastMonth.month;
        },
        computed: {
            today() {
                return $virtus.tools.dates.getToday();
            },
            months() {
                return $virtus.tools.dates.getMonthOptions(true);
            },
            years() {
                return $virtus.tools.dates.getYearOptions();
            },
            missingDate() {
                return (!this.year || !(this.month >= 0));
            },
            dateInFuture() {
                return (!this.missingDate && (this.year > this.today.year || (this.year == this.today.year && this.month > this.today.month)));
            }
        },
        methods: {
            download() {
                this.showErrors = true;
                if (!this.missingDate && !this.dateInFuture) {
                   this.$store.dispatch('adminReports/getMonthlySummaryReport', { download: true, year: this.year, month: this.month });
                }
            },
        }
    }
</script>

<style>
/*    .monthly-summary .control-text {
        display: inline-block;
    }

    .monthly-summary .control-text input {
        font-size: small;
        width: 3rem;
        padding: 0 2px 0 8px;
        height: 1.5rem;
    }*/

</style>
