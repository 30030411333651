import Vue from 'vue';
import './axios-custom';
import api from "./api";
import changes from "./changes";
import cookies from "./cookies";
import dates from "./dates";
import fixTable from "./fix-table";
import loader from "./loader";
import loading from "./loading.vue";
import images from "./images";
import messages from "./messages";
import validation from "./validation";
import cache from "./cache";
import stores from "./stores";
//import storage from "./storage";


function install(Vue) {
    if (install.installed) return;
    install.installed = true;
    Vue.component("loading", loading);
}

let tools = {
    install,
    api,
    changes,
    cookies,
    dates,
    fixTable,
    loader,
    messages,
    validation,
    cache,
    stores,
    //storage,
    images,
    components: {
        loading,
    },
};

Vue.use(tools);

export default tools;
