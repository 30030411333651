<template>
    <b-jumbotron class="home-header">
        <div class="home-text">
            <slot name="default">
                <div class="display-3">Welcome</div>
                <p class="lead"></p>
            </slot>
        </div>
        <div class="home-logo-1">
            <span v-if="siteLogos && siteLogos.length > 0">
                <img v-for="(image, index) in siteLogos" :key="index" :src="image" />
            </span>
            <img src="/images/logo-virtus-3.png" />
        </div>
    </b-jumbotron>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'welcome-panel',
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {
        },
        computed: {
            ...mapState('security', ['siteId']),
            siteLogos() {
                var siteId = this.siteId;
                var logos = $virtus.config.sites.filter(x => x.Id == siteId).logos;
                return logos;
            }
        },
        methods: {
        }
    }
</script>
<style>
    .jumbotron {
        margin-bottom: 0;
        background-color: white;
    }

    .home-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 0.5rem;
        text-align: center;
    }

    .home-header p {
        margin: 0;
    }

    .home-header .home-logo-1,
    .home-header .home-text {
        display: inline-block;
    }

    .home-header .home-text .display-3 {
        font-size: 2rem;
        font-weight: 600;
    }

    .home-header .home-text .lead {
        font-size: 1rem;
    }

    .home-header .home-logo-1 img {
        height: 4rem;
        margin: 0.25rem;
    }

    .home-header .home-text {
        margin: 0.25rem;
    }

    @media (max-width: 1100px) {
        .home-header .home-text .display-3 {
            font-size: 1.5rem;
        }

        .home-header .home-text .lead {
            font-size: 0.75rem;
        }

        .home-header .home-text {
            margin: 0.25rem;
        }

        .home-header .home-logo-1 {
        }

        .home-header .home-logo-1 img {
            height: 3.5rem;
        }
    }

    @media (max-width: 950px) {
        .home-header .home-logo-1 img {
            margin: 0.2rem;
            height: 3rem;
        }
    }

    @media (max-width: 660px) {
        .home-header {
            flex-direction: column;
        }
    }

</style>
