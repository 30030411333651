
let cookies = {
    setCookie(cookieName, cookieValue, nDays) {
        if (nDays) {
            var d = new Date();
            d.setTime(d.getTime() + (nDays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
        } else {
            document.cookie = cookieName + "=" + cookieValue + ";path=/";
        }
    },
    deleteCookie(cookieName) {
        document.cookie = cookieName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    readCookie(cookieName) {
        var b = document.cookie.match('(^|[^;]+)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
};

export default cookies;
