<template>
    <div class="control-file">
        <div class="dropbox" v-if="!fileToUpload">
            <input type="file" id="fileUpload" ref="fileUpload" @change="handleFilesUpload()" accept="pdf/*" class="input-file">
            <div class="message" v-html="message"></div>
        </div>
        <div class="file-actions" v-if="fileToUpload">
            <div>{{fileToUpload ? fileToUpload.name : ''}}</div>
            <div class="remove-file" v-on:click="removeFile()">Remove</div>
            <div v-if="showOpenButton && fileToUpload && !fileToUpload.isNew" class="download-file" v-on:click="openFile()">Open</div>
            <div v-if="showDownloadButton && fileToUpload && !fileToUpload.isNew" class="download-file" v-on:click="downloadFile()">Download</div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'control-file',
        props: ['value', 'text', 'showDownload', 'showOpen'],
        data() {
            return {
                message: 'Drag your file here <br/> or click to browse',
                fileToUpload: null,
                showDownloadButton: false,
                showOpenButton: false,
            };
        },
        mounted() {
            if (this.text) {
                this.message = this.text;
            }
            if (this.value) {
                this.fileToUpload = this.value;
            }
            if (this.showDownload) {
                this.showDownloadButton = this.showDownload;
            }
            if (this.showOpen) {
                this.showOpenButton = this.showOpen;
            }
        },
        watch: {
            value(newValue) {
                this.fileToUpload = newValue;
            },
            showDownload(show) {
                this.showDownloadButton = show;
            },
            showOpen(show) {
                this.showOpenButton = show;
            },
        },
        methods: {
            handleFilesUpload() {
                this.fileToUpload = this.$refs.fileUpload.files[0];
                this.fileToUpload.isNew = true;
                this.$emit('input', this.fileToUpload);
            },
            removeFile() {
                this.fileToUpload = null;
                this.$emit('input', null);
            },
            downloadFile() {
                this.$emit('download', this.fileToUpload);
            },
            openFile() {
                this.$emit('open', this.fileToUpload);
            },
        }
    };
</script>
<style>
    .control-file .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        height: 100px;
        position: relative;
        cursor: pointer;
    }

        .control-file .dropbox .input-file {
            opacity: 0; /* invisible but it's there! */
            width: 100%;
            height: 100px;
            position: absolute;
            cursor: pointer;
        }

        .control-file .dropbox:hover {
            background: lightblue; /* when mouse over to the drop zone, change color */
        }

        .control-file .dropbox .message {
            font-size: 1.2em;
            text-align: center;
            padding: 20px 0;
            margin-left: 0;
        }

    .control-file .file-actions {
        border: 1px solid #dcdfe6;
        padding: 10px;
        background-color: #f8f9fa;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .control-file .remove-file {
        font-size: small;
        border-radius: 10px;
        color: #dc3545;
        border: 1px solid #dc3545;
        background-color: rgba(255,0,0,.1);
        cursor: pointer;
        margin: 1px 1px 1px auto;
        padding: 0 .5rem;
    }

        .control-file .remove-file:hover {
            margin: 0 0 0 auto;
            background-color: #dc3545;
            color: white;
        }

    .control-file .download-file {
        font-size: small;
        border-radius: 10px;
        color: #6c757d;
        border: 1px solid #6c757d;
        background-color: rgba(77,77,77,.1);
        cursor: pointer;
        margin: 1px 1px 1px 5px;
        padding: 0 .5rem;
    }

        .control-file .download-file:hover {
            background-color: #6c757d;
            color: white;
        }
</style>
