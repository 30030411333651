<template>
    <admin-page :actions="actions" :fields="fields" :texts="texts"></admin-page>
</template>
<script>

    export default {
        name: 'admin-shifts',
        props: [],
        data() {
            return {
                fields: [
                    {
                        key: 'name', label: 'Shift name', sortable: true, validations: { required: true, maxLength: 500 },
                        placeholder: 'Enter shift name'
                    }
                ],
                actions: {
                    get: 'admin/getShiftsNoLeaver',
                    insert: 'admin/insertShift',
                    update: 'admin/updateShift'
                },
                texts: {
                    listTitle: 'Shifts',
                    newTitle: 'Add New Shift',
                    addButton: 'Add Shift',
                    deleteButton: 'Remove',
                    itemName: 'shift',
                },
            }
        },
        created() {

        },
        mounted() {
        },
        computed: {


        },
        methods: {


        }
    }
</script>
<style scoped>
</style>
