<template>
    <!--<div class="ram-viewer-button">
        <router-link v-if="ramId" :to="url" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
            <a :class="'btn btn-sm btn-' + (primary ? 'primary' : 'secondary')" :href="href" @click="navigate"><icon :name="icon" class="viewer-icon"></icon><span>{{buttonText}}</span></a>
        </router-link>
    </div>-->
    <span v-if="ramId" :class="'ram-viewer-button btn btn-sm btn-' + (primary ? 'primary' : 'secondary')" @click="navigate">
        <icon :name="icon" class="viewer-icon"></icon>
        <span>{{buttonText}}</span>
    </span>
</template>
<script>

    export default {
        name: 'ram-viewer-button',
        props: ['ramId', 'text', 'primary', 'hasVideo', 'showTimer'],
        data() {
            return {
            }
        },
        async created() {
        },
        computed: {
            url() {
                var key = encodeURIComponent(btoa(JSON.stringify({
                    ramId: this.ramId,
                    showTimer: this.showTimer
                })));
                var url = '/viewer/' + key;
                return url;
            },
            icon() {
                return (this.isVideo ? 'film' : 'file');
            },
            buttonText() {
                return (this.text ? this.text : (this.isVideo ? 'Watch' : 'View'));
            },
        },
        methods: {
            navigate() {
                this.$store.dispatch('rams/viewRam', { ramId: this.ramId, hasVideo: this.hasVideo, showTimer: this.showTimer, returnPath: this.$route.fullPath });
            }
        }
    }

</script>
<style scoped>
    .viewer-icon {
        position: relative;
        top: 1px;
        margin-right: 5px;
    }
    .btn {
        padding: 0 10px;
        border-radius: 10px;
    }
</style>
