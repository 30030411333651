<template>
    <div class="main-form">
        <h1 class="header-title">{{title}}</h1>

        <div :key="index" v-for="(field, index) in editableFields" :class="field.class">
            <div class="control-label" :for="field.key" v-if="showNoEditableField(field) || showEditableBoolField(field)">{{field.label}}</div>
            <div :id="field.key" v-if="showNoEditableField(field)" :class="field.controlClass">{{formatField(field)}}</div>
            <control-text v-if="showEditableTextField(field)" v-model="item[field.key]" :label="field.label"
                          :id="field.key" size="sm" :errors="field.errors" @keyup.native="checkErrors"
                          :placeholder="field.placeholder" :rows="field.rows" :control-class="field.controlClass"></control-text>
            <control-select v-if="showEditableSelectField(field)" v-model="item[field.value]" :label="field.label"
                            :id="field.key" :options="field.options" size="sm" @change="checkErrors"
                            :placeholder="field.placeholder" :errors="field.errors" :control-class="field.controlClass" />
            <control-date v-if="showEditableDateField(field)" v-model="item[field.key]"
                          :id="field.key" size="sm" :placeholder="field.placeholder" @input="checkErrors"
                          :label="field.label" :errors="field.errors" :control-class="field.controlClass"></control-date>
            <control-checkbox v-if="showEditableBoolField(field)" v-model="item[field.key]"
                              :id="field.key" :errors="field.errors" :control-class="field.controlClass">{{field.placeholder}}</control-checkbox>
            <div v-if="showFileField(field)">
                <div class="control-label">{{field.label}}</div>
                <control-file v-model="item[field.key]" :text="field.placeholder" :show-download="field.download" @download="field.download(item)" :show-open="field.open" @open="field.open(item)"></control-file>
            </div>
        </div>

        <slot name="moreFormFields" :item="item" :index="index"></slot>

        <div class="buttons">
            <slot name="moreFormActions" :item="item" :index="index"></slot>
            <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
            <button type="button" class="btn btn-primary" @click="save">Save</button>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'

    export default {
        name: 'form-page',
        props: ['fields', 'title'],
        data() {
            return {
                errors: {},
                item: {},
                index: null,
                showErrors: false,
            }
        },
        created() {
        },
        mounted() {
            this.init();
        },
        computed: {
            dateFields() {
                return this.fields.filter(x => { return x.dataType == 'date'; }).map(x => { return x.key; });
            },
            formIsValid() {
                if (this.showErrors) {
                    for (var i in this.fields) {
                        var field = this.fields[i];
                        var errors = field.errors;
                        if (errors && errors.length > 0) {
                            return false;
                        }
                    }
                }
                return true;
            },
            editableFields() {
                return this.fields.filter(f => !f.noeditable && !f.isCustom);
            },
        },
        methods: {
            init(itemToEdit) {
                this.item = (itemToEdit && itemToEdit.item ? JSON.parse(JSON.stringify(itemToEdit.item)) : {});
                this.index = (itemToEdit && itemToEdit.index ? itemToEdit.index : null);
                for (var i in this.fields) {
                    var field = this.fields[i];
                    var value = (itemToEdit && itemToEdit.item[field.key] ? itemToEdit.item[field.key] : null);
                    Vue.set(this.item, field.key, value);
                    if (field.value) Vue.set(this.item, field.value, value);
                    Vue.set(field, 'errors', null);
                }
                this.showErrors = false;
                var key = this.fields[0].key;
                Vue.nextTick(function () {
                    document.getElementById(key).focus();
                });
            },
            save() {
                this.showErrors = true;
                this.checkErrors();
                if (this.formIsValid) {
                    this.fillSelectFieldsName();
                    this.showErrors = false;
                    this.$emit('save', this.item);
                }
            },
            fillSelectFieldsName() {
                var selectFields = this.fields.filter(field => field.dataType == 'select');
                var item = this.item;
                for (var i = 0; i < selectFields.length; i++) {
                    var field = selectFields[i];
                    var newText = null;
                    var newId = item[field.value];
                    if (newId) {
                        var selectedOption = field.options.find(option => option.value == newId);
                        if (selectedOption) {
                            newText = selectedOption.text;
                        }
                    }
                    item[field.key] = newText;
                }
            },
            cancel() {
                this.showErrors = false;
                this.$emit('cancel');
            },

            showNoEditableField(field) {
                return (field.noeditable);
            },
            showEditableField(field) {
                return (!field.noeditable);
            },
            showEditableSelectField(field) {
                return (this.showEditableField(field) && field.dataType == 'select');
            },
            showEditableTextField(field) {
                return (this.showEditableField(field) && field.dataType != 'select' && field.dataType != 'date' && field.dataType != 'file' && field.dataType != 'bool');
            },
            showEditableDateField(field) {
                return (this.showEditableField(field) && field.dataType == 'date');
            },
            showEditableBoolField(field) {
                return (this.showEditableField(field) && field.dataType == 'bool');
            },
            showFileField(field) {
                return (field.dataType == 'file');
            },
            checkErrors() {
                for (var i in this.fields) {
                    var field = this.fields[i];
                    var errors = [];
                    if (this.showErrors) {
                        var fieldInError = false;
                        var item = this.item;
                        var value = item[field.key];
                        var validateField = (field.validations || field.dataType == 'date');
                        if (validateField) {
                            var validations = (field.validations || {});
                            if (validations.required) {
                                if ((field.dataType == 'select' && !item[field.value])
                                    || (field.dataType != 'select' && !value)) {
                                    errors.push('The field ' + field.label + ' is required.');
                                    fieldInError = true;
                                }
                            }
                            if (!fieldInError && validations.maxLength) {
                                if (field.dataType != 'select' && value.length > validations.maxLength) {
                                    errors.push('The maximum length for field ' + field.label + ' is ' + validations.maxLength + '.');
                                    fieldInError = true;
                                }
                            }
                            if (!fieldInError && field.dataType == 'date' && value) {
                                if (!$virtus.tools.dates.isValid(value)) {
                                    errors.push('The field ' + field.label + ' is not a valid date.');
                                    fieldInError = true;
                                }
                            }
                            if (!fieldInError && validations.greaterThan && value) {
                                var otherFieldKey = validations.greaterThan;
                                var otherField = this.fields.find(field => field.key == otherFieldKey);
                                var otherValue = item[otherFieldKey];
                                if (otherValue && otherValue >= value) {
                                    errors.push('The field ' + field.label + ' must be greater than field ' + otherField.label + '.');
                                    fieldInError = true;
                                    otherField.isError = true;
                                }
                            }
                            if (!fieldInError && validations.lessThan && value) {
                                var otherFieldKey2 = validations.lessThan;
                                var otherField2 = this.fields.find(field => field.key == otherFieldKey2);
                                var otherValue2 = item[otherFieldKey2];
                                if (otherValue2 && otherValue2 <= value) {
                                    errors.push('The field ' + field.label + ' must be less than field ' + otherField2.label + '.');
                                    fieldInError = true;
                                    otherField2.isError = true;
                                }
                            }

                            if (!fieldInError && validations.greaterOrEqualThan && value) {
                                var otherFieldKey3 = validations.greaterOrEqualThan;
                                var otherField3 = this.fields.find(field => field.key == otherFieldKey3);
                                var otherValue3 = item[otherFieldKey3];
                                if (otherValue3 && otherValue3 > value) {
                                    errors.push('The field ' + field.label + ' must be equal or greater than field ' + otherField3.label + '.');
                                    fieldInError = true;
                                    otherField3.isError = true;
                                }
                            }
                            if (!fieldInError && validations.lessOrEqualThan && value) {
                                var otherFieldKey4 = validations.lessOrEqualThan;
                                var otherField4 = this.fields.find(field => field.key == otherFieldKey4);
                                var otherValue4 = item[otherFieldKey4];
                                if (otherValue4 && otherValue4 < value) {
                                    errors.push('The field ' + field.label + ' must be equal or less than field ' + otherField4.label + '.');
                                    fieldInError = true;
                                    otherField4.isError = true;
                                }
                            }
                        }
                        Vue.set(field, 'errors', (errors.length > 0 ? errors : null));
                    }
                }
            },
            formatField(field) {
                var value = this.item[field.key];
                if (field.dataType == 'date') {
                    value = (value ? $virtus.tools.dates.formatDate(value) : '');
                } else if (field.dataType == 'bool') {
                    value = (value === true ? 'Yes' : (value === false ? 'No' : ''));
                }
                return value;
            },

        }
    }
</script>

<style>

    .main-form .control-label {
        margin-top: 1rem;
        margin-bottom: 0.25rem;
    }

    .is-invalid .el-input__inner {
        border-color: #dc3545;
    }
</style>

