import models from '../record/shared/models.js';
//import virtus from '../shared';
var tools = $virtus.tools;
var api = tools.api;
var stores = tools.stores;

export default {
    namespaced: true,
    state: {
    },
    getters: {

    },
    mutations: {

    },
    actions: {

        async addSkill(store, data) {
            let result = await api.post('/api/skills', data);
            if (result) {
                models.refreshComputedSkillFields(result);
            }
            return result;
        },

        async editSkill(store, data) {
            let result = await api.put('/api/skills/' + data.id, data);
            return result;
        },

        async removeSkill(store, data) {
            let result = await api.delete('/api/skills/' + data.id);
            return result;
        },

        async updateSkillAssignments(store, data) {
            var options = {
                dateFields: ['trainingDate'],
            };
            let result = await api.post('/api/skills/assignments/', data, options);
            return result;
        },

        async updateSkillSignatures(store, data) {
            var options = {
                dateFields: ['expirationDate', 'validFromDate']
            };
            let result = await api.post('/api/skills/signatures/', data, options);
            return result;
        },

        async saveTempSkillSignatureFile(store, data) {
            var options = {
                isFormData: true,
            };
            let result = await api.post('/api/skills/signatures/file', data, options);
            return result;
        },

        async checkIfSkillSignatureFileExists(store, data) {
            let result = await api.get('/api/skills/signatures/file-exists?employeeId=' + data.employeeId + '&skillId=' + data.skillId);
            return result;
        },

        async getEmployeesSkillsAssignments() {
            let result = await api.get('/api/skills/employees/assignments');
            return result;
        },

        async updateEmployeesSkillsAssignments(store, data) {
            var options = {
                dateFields: ['trainingDate'],
            };
            let result = await api.post('/api/skills/employees/assignments', data, options);
            return result;
        },


        async getFullSkillSignatures(store, data) {
            let result = await api.get('/api/skills/signatures/full?showLeavers=' + (data && data.showLeavers));
            return result;
        },

        async getFullSkills({ dispatch }) {
            var items = await Promise.all([
                dispatch('getSkillsDictionary'),
                dispatch('getSkillAssignments'),
                dispatch('getSkillSignatures')
            ]).then((values) => {
                var skills = values[0];
                var assignments = values[1];
                var signatures = values[2];

                //Initialize assignments and signatures
                for (var i in skills) {
                    var skill3 = skills[i];
                    skill3.assignments = {};
                    skill3.signatures = {};
                }

                //Update assignments
                for (var j = 0; j < assignments.length; j++) {
                    var newAssignment = assignments[j];
                    var skill = skills[newAssignment.skillId];
                    if (skill) {
                        skill.assignments[newAssignment.jobPositionId] = newAssignment;
                    }
                }

                //Update signatures
                for (var k = 0; k < signatures.length; k++) {
                    var newSignature = signatures[k];
                    var skill2 = skills[newSignature.skillId];
                    if (skill2) {
                        skill2.signatures[newSignature.employeeId] = newSignature;
                    }
                }
                return skills;
            });
            return items;
        },




        async getSkills() {
            var items = await api.get('/api/skills');
            items = items.map(skill => models.refreshComputedSkillFields(skill));
            return items;
        },


        async getSkillsDictionary({ dispatch }) {
            var skills = await dispatch('getSkills');
            var items = stores.convertArrayToDictionary(skills);
            return items;
        },

        async getSortedFullSkills({ dispatch }) {
            var dictionary = await dispatch('getFullSkills');
            var items = stores.convertDictionaryToArray(dictionary);
            items.sort(function (a, b) { return a.description.toLocaleLowerCase().localeCompare(b.description.toLocaleLowerCase()); });
            return items;
        },

        //async getOptionsFromSkills({ dispatch }) {
        //    var items = await dispatch('getSkills');
        //    var options = stores.getOptionsFromArray(items, 'id', 'description');
        //    return options;
        //},

        async getSkillAssignments() {
            var items = await api.get('/api/skills/assignments');
            items = items.map(assignment => models.refreshComputedAssignmentFields(assignment));
            return items;
        },

        async getSkillSignatures() {
            var options = {
                dateFields: ['expirationDate']
            };
            var items = await api.get('/api/skills/signatures', options);
            items = items.map(signature => models.refreshComputedSkillSignatureFields(signature));
            return items;
        },

        async getSkillSignatureFile(store, data) {
            var url = '/api/skills/signatures/file?employeeId=' + data.employeeId + '&skillId=' + data.skillId + '&download=' + data.download + '&appId=2';
            let result = await (data.download ? api.download(url) : api.open(url));
            return result;
        },

    }
};
