<template>
    <div :class="{ 'rams-assignments': true, 'read-only': !hasWriteRights }">
        <h1 class="header-title">RAMS Assignments</h1>

        <div class="header-items-bar">
            <div class="header-item signature-msg" v-if="hasWriteRights">Click assignment level for quick edit:</div>

            <div class="header-item assignment-levels-bar">
                <table id="assignment-levels">
                    <tr>
                        <td id="assignment-level-clear" :class="{ 'selected': (selectedAssignmentLevel && selectedAssignmentLevel.id == 0), 'clickable': hasWriteRights }" @click.stop="clickAssignmentLevel({ id: 0})">Clear</td>
                        <td :key="index" v-for="(level, index) in assignmentLevels" @click.stop="clickAssignmentLevel(level)" :id="'assignment-level-' + level.id" :class="{ 'selected': (selectedAssignmentLevel && level.id == selectedAssignmentLevel.id), clickable: hasWriteRights }">{{level.id + ' - ' + level.name}}</td>
                    </tr>
                </table>
            </div>

            <div class="header-item">
                <b-input-group size="sm" prepend="Filter">
                    <b-form-input id="ramFilter" size="sm" v-model="ramFilterText" placeholder="rams" title="enter rams filter" @keyup.enter.native="filterClick" />
                    <b-form-input id="jobPositionFilter" v-model="jobPositionFilterText" placeholder="job position" title="enter job position filter" @keyup.enter.native="filterClick" />
                    <b-input-group-append>
                        <b-btn @click.stop="filterClick">Go</b-btn>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <div class="header-item">
                <b-input-group size="sm" prepend="Page size">
                    <b-form-select id="pageSize" size="sm" :options="pageOptions" v-model="perPage" @change="paginationChanged" />
                    <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" @change="paginationChanged" />
                </b-input-group>
            </div>

            <div class="clear"></div>

        </div>

        <div id="assignments-table" class="fixed-table-container">
            <table>
                <thead>
                    <tr class="header even-row">
                        <th v-for="cell in matrix.header" :key="'h_' + cell.id" :class="cell.css" v-html="cell.html"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in matrix.rows" :key="'r_' + row.id" :class="row.css">
                        <td v-for="cell in row.cells" :key="'c_' + cell.id" @click.stop="clickCell(cell)" :class="cell.css" :title="cell.text" v-html="cell.html"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="buttons" v-if="hasWriteRights">
            <span style="float:left;" v-show="itemsChangedCount > 0">RAMS assignments changed: {{itemsChangedCount}}</span>
            <span>
                <button type="button" class="btn btn-secondary btn-sm" @click="discardChanges" :disabled="itemsChangedCount == 0">Discard changes</button>
                <button type="button" class="btn btn-primary btn-sm" @click="saveChanges" :disabled="itemsChangedCount == 0">Save changes</button>
            </span>
        </div>

    </div>
</template>

<script>
    import 'core-js/web/immediate';
    import Vue from 'vue';
    import models from '../shared/models.js';

    export default {
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                pageOptions: [5, 10, 20, 50, 100],
                selectedAssignmentLevel: null,
                matrix: { header: [], rows: [] },
                itemsChangedCount: 0,
                ramFilterText: '',
                jobPositionFilterText: '',
                ramFilter: '',
                jobPositionFilter: '',
                changedItems: {},
                assignmentLevels: [],
                rams: {},
                sortedRams: [],
                jobPositions: [],
                hasWriteRights: false,
            }
        },
        async created() {
            this.hasWriteRights = await this.checkUserHasWriteRights();
            await Promise.all([
                this.$store.dispatch('getAssignmentLevels'),
                this.$store.dispatch('rams/getFullRams'),
                this.$store.dispatch('admin/getJobPositions'),
            ]).then((values) => {
                this.assignmentLevels = values[0];
                this.rams = values[1];
                this.jobPositions = values[2];
                this.sortedRams = models.getSortedRams(this.rams);
                this.loadMatrix();
            });
        },
        beforeRouteLeave(to, from, next) {
            $virtus.tools.changes.beforeRouteLeave(to, from, next, this.itemsChangedCount > 0);
        },
        computed: {
            totalRows() {
                return this.filteredRams.length;
            },
            first () {
                return 1 + (this.currentPage - 1) * this.perPage;
            },
            paginatedRams() {
                var rams = this.filteredRams.slice(this.first - 1, this.first + this.perPage - 1);
                return rams;
            },
            filteredRams() {
                var rams = this.sortedRams;
                var ramFilter = this.ramFilter;
                if (ramFilter) {
                    rams = rams.filter(r => { return r.searchName.includes(ramFilter); });
                }
                return rams;
            },
            filteredJobPositions() {
                var jobPositions = this.jobPositions;
                var jobPositionFilter = this.jobPositionFilter;
                if (jobPositionFilter) {
                    jobPositions = jobPositions.filter(jp => { return jp.search.includes(jobPositionFilter); });
                }
                return jobPositions;
            },
        },
        methods: {
            clickCell: function (item) {
                if (!this.hasWriteRights) return;
                if (!item.isHeader && this.selectedAssignmentLevel != null && item.assignmentLevelId != this.selectedAssignmentLevel.id) {
                    var assignmentLevelId = 0;

                    //Update item
                    if (this.selectedAssignmentLevel.id > 0 ) {
                        assignmentLevelId = this.selectedAssignmentLevel.id;
                        item.assignmentLevelId = assignmentLevelId;
                        item.css = 'clickable assignment-level-' + assignmentLevelId;
                        item.html = assignmentLevelId;
                    } else {
                        item.assignmentLevelId = 0;
                        item.css = 'clickable';
                        item.html = '';
                    }

                    //Set assignment state
                    var changedItem = this.changedItems[item.id];
                    var changed = (item.assignmentLevelId != item.originalAssignmentLevelId);

                    //Update / create / delete changedItem
                    if (changed && changedItem) {
                        //Already changed, update
                        changedItem.assignmentLevelId = assignmentLevelId;
                        changedItem.text = (assignmentLevelId > 0 ? assignmentLevelId : '');
                        changedItem.css = (assignmentLevelId > 0 ? 'assignment-level-' + assignmentLevelId : '');
                    } else if (!changed && changedItem) {
                        //Not changed, delete changed item
                        this.itemsChangedCount--;
                        delete this.changedItems[item.id];
                    } else if (changed && !changedItem) {
                        //Changed now, create changed item
                        this.itemsChangedCount++;
                        this.changedItems[item.id] = {
                            id: item.id,
                            ramId: item.ramId,
                            jobPositionId: item.jobPositionId,
                            assignmentLevelId: assignmentLevelId,
                            text: (assignmentLevelId > 0 ? assignmentLevelId : ''),
                            css: (assignmentLevelId > 0 ? 'assignment-level-' + assignmentLevelId : '')
                        };
                    } //Else Not changed, not changed item: do nothing

                    //Display changed item
                    if (changed) {
                        item.html += '<span class="note"></span>';
                    }
                }
            },
            clickAssignmentLevel: function (level) {
                if (!this.hasWriteRights) return;
                if (!this.selectedAssignmentLevel || level.id != this.selectedAssignmentLevel.id) {
                    this.selectedAssignmentLevel = level;
                } else {
                    this.selectedAssignmentLevel = null;
                }
            },

            discardChanges: function () {
                this.changedItems = {};
                this.itemsChangedCount = 0;
                this.loadMatrix();
                this.showChangesDiscarded();
            },

            showSaveOk: function () {
                $virtus.tools.messages.showMessage('Success!', 'The RAMS assignments have been saved successfully.');
            },

            showChangesDiscarded: function () {
                $virtus.tools.messages.showMessage('Changes discarded', 'All changes have been discarded.');
            },

            saveChanges: async function () {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    let result = await this.$store.dispatch('rams/updateRamAssignments', changedItems);
                    if (result) {
                        this.showSaveOk();
                        for (var i = 0; i < changedItems.length; i++) {
                            var item = changedItems[i];
                            var assignments = this.rams[item.ramId].assignments;
                            if (item.assignmentLevelId && item.assignmentLevelId > 0) {
                                Vue.set(assignments, item.jobPositionId, item);
                            } else {
                                Vue.delete(assignments, item.jobPositionId);
                            }
                        }
                        this.changedItems = {};
                        this.itemsChangedCount = 0;
                        this.loadMatrix();
                    }
                }
            },

            filterClick: function () {
                this.currentPage = 1;
                this.ramFilter = this.ramFilterText.toLocaleLowerCase();
                this.jobPositionFilter = this.jobPositionFilterText.toLocaleLowerCase();
                this.loadMatrix();
            },

            paginationChanged: function () {
                this.loadMatrix();
            },
            loadMatrix: function () {
                var self = this;
                $virtus.tools.loader.show();

                var matrix = { header: [], rows: [] };
                var rams = [];
                var jobPositions = [];
                //var assignmentLevels = self.assignmentLevels;

                function part1() {
                    var a = new Date();
                    console.log("part 1 - start");
                    //Apply filters
                    rams = self.paginatedRams;
                    jobPositions = self.filteredJobPositions;

                    setImmediate(part2);
                    console.log("part 1 - end " + (new Date() - a) + ' ms');
                }

                function part2() {
                    var a = new Date();
                    console.log("part 2 - start");
                    //Create header
                    matrix.header.push({ id: 0, css: 'head-col-label', html: '<div>&nbsp;</div><div>&nbsp;</div><div>Job Position</div><div>&nbsp;</div><div>&nbsp;</div>' });
                    for (var i = 0; i < rams.length; i++) {
                        var ram = rams[i];
                        if (!ram.html) {
                            models.refreshComputedRamFields(ram);
                        }
                        matrix.header.push({
                            id: ram.id,
                            css: ram.css,
                            html: ram.html
                        });
                    }
                    setImmediate(part3);
                    console.log("part 2 - end " + (new Date() - a) + ' ms');
                }

                function part3() {
                    var a = new Date();
                    console.log("part 3 - start");

                    //Create rows
                    for (var i = 0; i < jobPositions.length; i++) {
                        var even = (i % 2);
                        var jobPosition = jobPositions[i];
                        var row = {
                            id: jobPosition.id,
                            css: (even ? 'even-row' : ''),
                            cells: [{
                                id: jobPosition.id,
                                text: jobPosition.name,
                                html: jobPosition.name,
                                isHeader: true,
                                css: 'head-col'
                            }]
                        };
                        for (var j = 0; j < rams.length; j++) {
                            var ram = rams[j];
                            var cellId = ram.id + '_' + jobPosition.id;
                            var assignmentLevelId = 0, html = '', css = '';
                            var assignment = ram.assignments[jobPosition.id];
                            var changedItem = self.changedItems[cellId];
                            if (changedItem) {
                                html = changedItem.text + '<span class="note"></span>';
                                css = changedItem.css;
                                assignmentLevelId = changedItem.assignmentLevelId;
                            } else if (assignment) {
                                html = assignment.text;
                                css = assignment.css;
                                assignmentLevelId = assignment.assignmentLevelId;
                            }
                            row.cells.push({
                                id: cellId,
                                ramId: ram.id,
                                jobPositionId: jobPosition.id,
                                assignmentLevelId: assignmentLevelId,
                                originalAssignmentLevelId: (assignment ? assignment.assignmentLevelId : 0),
                                html: html,
                                css: 'clickable ' + css
                            });
                        }
                        matrix.rows.push(row);
                    }

                    self.matrix = matrix;
                    setImmediate(part4);
                    console.log("part 3 - end " + (new Date() - a) + ' ms');
                }

                function part4() {
                    var a = new Date();
                    console.log("part 4 - start");
                    $virtus.tools.fixTable(document.getElementById('assignments-table'));
                    $virtus.tools.loader.close();
                    console.log("part 4 - end " + (new Date() - a) + ' ms');
                }

                //Start long running task
                setImmediate(part1);
            },
        }
    }
</script>

<style>


</style>
