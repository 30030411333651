<template>
    <div class="video-button">
        <router-link v-if="videoPath" :to="path" target="_blank" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
            <a :class="'btn btn-sm btn-' + (primary ? 'primary' : 'secondary')" :href="href" @click="navigate"><icon name="film" class="film-icon"></icon><span>{{buttonText}}</span></a>
        </router-link>        
    </div>
</template>
<script>

    export default {
        name: 'video-button',
        props: ['videoPath', 'returnPath', 'text', 'primary'],
        data() {
            return {
            }
        },
        async created() {
        },
        computed: {
            path() {
                var key = encodeURIComponent(btoa(JSON.stringify({
                    videoPath: this.videoPath,
                    returnPath: this.returnPath
                })));
                var path = '/viewer/video/' + key;
                return path;
            },
            buttonText() {
                return (this.text ? this.text : 'Watch');
            },
        },
        methods: {
        }
    }

</script>
<style scoped>
    .film-icon {
        position: relative;
        top: 1px;
        margin-right: 5px;
    }
    .btn {
        padding: 0 10px;
        border-radius: 10px;
    }
</style>
