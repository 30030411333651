import Vue from 'vue';

import carousel from "./carousel.vue";
import containerPage from "./container-page.vue";
import controlCheckbox from "./control-checkbox.vue";
import controlCheckboxGroup from "./control-checkbox-group.vue";
import controlChoice from "./control-choice.vue";
import controlCollection from "./control-collection.vue";
import controlDate from "./control-date.vue";
import controlDatetime from "./control-datetime.vue";
import controlItems from "./control-items.vue";
import controlSelect from "./control-select.vue";
import controlTags from "./control-tags.vue";
import controlText from "./control-text.vue";
import controlFile from "./control-file.vue";
import fileUpload from "./file-upload.vue";
import fullTable from "./full-table.vue";
import simpleModal from "./simple-modal.vue";
import formPage from "./form-page.vue";
import adminPage from "./admin-page.vue";
import welcomePanel from "./welcome-panel.vue";
import homeCounters from "./home-counters.vue";
import ramViewer from "./ram-viewer.vue";
import ramViewerButton from "./ram-viewer-button.vue";
import ramViewerHeader from "./ram-viewer-header.vue";
import store from "./store";

//Import css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
//import 'element-ui/lib/theme-chalk/index.css';

//External components
import BootstrapVue from 'bootstrap-vue';
//import ElementUI from 'element-ui';
//import locale from 'element-ui/lib/locale/lang/en';
import VueChartkick from 'vue-chartkick';
import Icon from 'vue-awesome/components/Icon';
import VueSignaturePad from 'vue-signature-pad';
import VueTagsInput from '@johmun/vue-tags-input';

//Import icons
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/square';
import 'vue-awesome/icons/regular/square';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/spinner';
import 'vue-awesome/icons/user-circle';
import 'vue-awesome/icons/trash-alt';
import 'vue-awesome/icons/ban';
import 'vue-awesome/icons/lock';
import 'vue-awesome/icons/file';
import 'vue-awesome/icons/film';


function install(Vue) {
    if (install.installed) return;
    install.installed = true;
    Vue.component("carousel", carousel);
    Vue.component("container-page", containerPage);
    Vue.component("control-checkbox", controlCheckbox);
    Vue.component("control-checkbox-group", controlCheckboxGroup);
    Vue.component("control-choice", controlChoice);
    Vue.component("control-collection", controlCollection);
    Vue.component("control-date", controlDate);
    Vue.component("control-datetime", controlDatetime);
    Vue.component("control-items", controlItems);
    Vue.component("control-select", controlSelect);
    Vue.component("control-tags", controlTags);
    Vue.component("control-text", controlText);
    Vue.component("control-file", controlFile);
    Vue.component("file-upload", fileUpload);
    Vue.component("full-table", fullTable);
    Vue.component("simple-modal", simpleModal);
    Vue.component("form-page", formPage);
    Vue.component("admin-page", adminPage);
    Vue.component("welcome-panel", welcomePanel);
    Vue.component("home-counters", homeCounters);
    Vue.component("ram-viewer", ramViewer);
    Vue.component("ram-viewer-button", ramViewerButton);
    Vue.component("ram-viewer-header", ramViewerHeader);

    //External components
    Vue.use(BootstrapVue);
    //Vue.use(ElementUI, { locale });
    Vue.use(VueSignaturePad);
    Vue.use(VueChartkick);
    Vue.component(VueTagsInput.name, VueTagsInput);
    Vue.component('icon', Icon);
}

function configure(items) {
    if (items && items.store) {
        if (!items.store._modules.root._children.controls) {
            items.store.registerModule('controls', controls.store);
            items.store.commit('controls/checkChartsLibIsLoaded');
        }
    }
}

let controls = {
    install,
    configure,
    store,
    components: {
        carousel,
        containerPage,
        controlCheckbox,
        controlCheckboxGroup,
        controlChoice,
        controlCollection,
        controlDate,
        controlDatetime,
        controlItems,
        controlSelect,
        controlTags,
        controlText,
        controlFile,
        fileUpload,
        fullTable,
        simpleModal,
        formPage,
        adminPage,
        welcomePanel,
        homeCounters,
        ramViewer,
        ramViewerButton,
        ramViewerHeader,
    },
};

Vue.use(controls);

export default controls;
