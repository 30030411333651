<template>
    <div class="admin-employees">
        <admin-page ref="adminPage" :actions="actions" :fields="fields" :texts="texts" :updateReturnItem="true"
                    :noMainForm="true" @updated="employeeUpdated">
            <template v-slot:moreActions="{ item, index, showEditItem }">
                <icon v-if="item.hasLogin" class="user-has-login" name="user-circle"></icon>
                <b-button v-if="showRightsButton" size="sm" variant="secondary" :disabled="showEditItem || userIsDisabled(item)"
                          @click.stop="showRights(item, index)" class="mr-1" right>Rights</b-button>
            </template>
        </admin-page>

        <!-- Add rights popup-->
        <simple-modal ref="showAddRights" name="showAddRights" headerTitle="Manage employee rights" @opened="rightsOpened" dialogClass="popup-manage-rights">
            <template slot="modal-content">
                <div id="add-rights-popup" class="fixed-table-container">
                    <p>
                        Please, enter pin or email and one profile to enable <b>{{popup.employeeName}}</b> to do login.
                        <span v-if="canEditProfile">To remove user rights you can do click on trash icon to clear the profile.</span>
                        <span v-else>The user has an admin profile, you can not edit the profile.</span>
                    </p>

                    <control-text ref="pin" v-model="popup.pin" label="Pin" placeholder="Enter employee pin number"></control-text>

                    <control-text v-model="popup.email" label="Email" placeholder="Enter employee email" :hasErrors="showErrors && emailNotValid"></control-text>
                    <div v-if="showErrors && emailNotValid" class="error">The email field is not valid.</div>

                    <br />
                    <div class="control-label with-buttons">
                        Employee Profile
                        <icon v-if="canEditProfile" name="trash-alt" @click="clearProfiles" title="Clear employee profiles"></icon>
                    </div>
                    <div class="site-name">{{getSiteName}}</div>

                    <control-select v-if="canEditProfile" v-model="popup.profileId" placeholder="-- No profile --" :options="visibleProfiles"></control-select>
                    <div v-else>{{profileName}}</div>

                    <!--<table class="profiles">
        <tr>
            <td class="header-text"></td>
            <td class="header" v-for="site in userSites" :key="'h' + site.id">{{site.name}}</td>
        </tr>
        <tr v-for="app in userApps" :key="'a' + app.id">
            <td class="header">{{app.name}}</td>
            <td v-for="site in userSites" :key="'s' + site.id">
                <control-select v-if="canEditProfile(app.id, site.id)" v-model="popup.profiles[app.id + '_' + site.id].profileId"
                    placeholder="-- No profile --" :options="visibleProfiles"></control-select>
                <div v-else>{{canReadProfile(app.id, site.id) ? getProfileName(app.id, site.id) : 'x'}}</div>
            </td>
        </tr>
    </table>-->

                    <div v-if="userCanDoLogin" class="login-message">
                        <icon name="user-circle" class="user-login-icon"></icon>
                        The user can do login.
                        <control-checkbox v-if="popup.email && !emailNotValid" v-model="popup.sendChangePasswordEmail">Send email to change the password</control-checkbox>
                    </div>
                    <div v-else class="login-message">
                        <icon class="user-login-icon">
                            <icon name="ban" color="red" scale="1.5"></icon>
                            <icon name="user-circle"></icon>
                        </icon>
                        The user cannot do login. It needs pin or email and one profile.
                    </div>
                    <div v-if="popup.passwordChanged" class="password-changed-message">
                        <icon name="lock"></icon>
                        Password has been changed.
                    </div>
                </div>
            </template>
            <template slot="modal-buttons">
                <button type="button" class="btn btn-secondary" @click="setPassword" :disabled="!userCanDoLogin">Set Password</button>
                <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
                <button type="button" class="btn btn-primary" @click="save">Save</button>
            </template>
        </simple-modal>

        <simple-modal ref="changePassword" name="changePassword" header-title="Change employee password" class="" @save="savePassword" @opened="passwordOpened">
            <template slot="modal-content">
                <p>Please, enter new password for <b>{{popup.employeeName}}</b> and click Save button.</p>
                <control-text ref="password" v-model="popup.password" placeholder="Enter password" :hasErrors="showErrorsPassword && (missingPassword || !isValidPassword)"></control-text>
                <div v-if="showErrorsPassword && missingPassword" class="error">The password field is mandatory.</div>
                <div v-if="showErrorsPassword && !missingPassword && !isValidPassword" class="error">The password must contains at least 8 characters and a capital letter.</div>
            </template>
        </simple-modal>

    </div>
</template>
<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: 'admin-employees',
        props: [],
        data() {
            return {
                fields: [
                    { key: 'name', label: 'Name', sortable: true, validations: { required: true }, placeholder: 'Enter employee name' },
                    { key: 'surname', label: 'Surname', sortable: true, validations: { required: true }, placeholder: 'Enter employee surname' },
                    { key: 'jobPosition', label: 'Job Position', sortable: true, dataType: 'select', value: 'jobPositionId', options: [],
                        validations: { required: true }, placeholder: 'Select job position'
                    },
                    { key: 'department', label: 'Department', sortable: true, noeditable: true },
                    { key: 'area', label: 'Area', sortable: true, noeditable: true },
                    {
                        key: 'shift', label: 'Shift/Status', sortable: true, dataType: 'select', value: 'shiftId', options: [],
                        validations: { required: true }, placeholder: 'Select shift'
                    },
                    { key: 'endDate', label: 'End date', sortable: true, dataType: 'date', placeholder: 'Select end date' },
                    { key: 'agencyStaff', label: 'Agency Staff', sortable: true, placeholder: 'Enter agency staff' },
                ],
                actions: {
                    get: 'admin/getEmployees',
                    insert: 'admin/insertEmployee',
                    update: 'admin/updateEmployee'
                },
                texts: {
                    listTitle: 'Employees',
                    newTitle: 'Add New Employee',
                    addButton: 'Add Employee',
                    deleteButton: 'Remove',
                    itemName: 'employee',
                },

                popup: {
                    pin: null,
                    email: null,
                    sendChangePasswordEmail: false,
                    password: null,
                    employeeName: null,
                    passwordChanged: false,
                    profileId: null,
                    //profiles: {
                    //    '1_1': { appId: 1, siteId: 1, profileId: null },
                    //    '1_2': { appId: 1, siteId: 2, profileId: null },
                    //    '1_3': { appId: 1, siteId: 3, profileId: null },
                    //    '1_4': { appId: 1, siteId: 4, profileId: null },
                    //    '1_5': { appId: 1, siteId: 5, profileId: null },
                    //    '2_1': { appId: 2, siteId: 1, profileId: null },
                    //    '2_2': { appId: 2, siteId: 2, profileId: null },
                    //    '2_3': { appId: 2, siteId: 3, profileId: null },
                    //    '2_4': { appId: 2, siteId: 4, profileId: null },
                    //    '2_5': { appId: 2, siteId: 5, profileId: null },
                    //},
                },
                showErrors: false,
                showErrorsPassword: false,
                editEmployee: null,
            }
        },
        async created() {
            await Promise.all(
                this.fields[2].options = await this.$store.dispatch('admin/getOptionsFromJobPositions'),
                this.fields[5].options = await this.$store.dispatch('admin/getOptionsFromShifts')
            );
        },
        mounted() {
        },
        computed: {
            ...mapState('security', ['userProfiles']),
            ...mapGetters('security', ['getAppId', 'getSiteId', 'getSiteName', 'isPlatformAdmin']),
            emailNotValid() {
                return (!!this.popup.email && !$virtus.tools.validation.validateEmail(this.popup.email));
            },
            isValidPassword() {
                return (!this.missingPassword && this.popup.password.length >= 8 && this.popup.password != this.popup.password.toLowerCase());
            },
            missingPassword() {
                return !this.popup.password;
            },
            isValidForm() {
                return (!this.emailNotValid);
            },
            //userApps() {
            //    var userApps = $virtus.config.apps.filter(a => {
            //        return !!this.userProfiles.find(p => p.appId == a.id && (p.profileId == this.profiles.PlatformAdmin || p.profileId == this.profiles.Administrator));
            //    });
            //    return userApps;
            //},
            //userSites() {
            //    var userSites = $virtus.config.sites.filter(s => {
            //        return !!this.userProfiles.find(p => p.siteId == s.id && (p.profileId == this.profiles.PlatformAdmin || p.profileId == this.profiles.Administrator));
            //    });
            //    userSites = userSites.filter(s => { return s.id == this.getSiteId });
            //    return userSites;
            //},
            userCanDoLogin() {
                return ((!!this.popup.pin || (!!this.popup.email && !this.emailNotValid)) && this.popup.profileId);
            },
            //anyProfileSelected() {
            //    for (var i in this.popup.profiles) {
            //        if (this.popup.profiles[i].profileId > 0) return true;
            //    }
            //    return false;
            //},
            profiles() {
                return $virtus.security.profiles;
            },
            visibleProfiles() {
                var profiles = $virtus.security.profileOptions;
                if (this.isPlatformAdmin) {
                    var adminProfile = $virtus.security.allProfiles[1];
                    profiles.push({ id: adminProfile.id, name: adminProfile.name });
                }
                return $virtus.tools.stores.getOptionsFromArray(profiles, 'id', 'name');
            },
            profileName() {
                var profile = $virtus.security.allProfiles.find(x => x.id === this.popup.profileId);
                if (profile) {
                    return profile.name;
                } else {
                    return '-- No profile --';
                }
            },
            canEditProfile() {
                return (this.isPlatformAdmin || !this.popup.profileId || this.popup.profileId === this.profiles.Manager || this.popup.profileId === this.profiles.Worker);
            },
            showRightsButton() {
                return (this.$route.meta.showRights !== false);
            },
        },
        methods: {
            clearProfiles() {
                this.popup.profileId = null;
            //    this.setProfiles(null);
            },
            //setProfiles(profiles) {
            //    this.popup.password = null;
            //    this.popup.passwordChanged = false;
            //    for (var i in this.apps) {
            //        for (var j in this.sites) {
            //            var profileId = null;
            //            var appId = this.apps[i].id;
            //            var siteId = this.sites[j].id;
            //            var profile = (profiles ? profiles.find(p => p.appId == appId && p.siteId == siteId) : null);
            //            if (profile && profile.profileId) profileId = profile.profileId;
            //            this.popup.profiles[appId + '_' + siteId].profileId = profileId;
            //        }
            //    }
            //},
            //canEditProfile(appId, siteId) {
            //    var canEdit = !!this.userProfiles.find(p => p.appId == appId && p.siteId == siteId
            //        && (p.profileId == this.profiles.PlatformAdmin || p.profileId == this.profiles.Administrator));
            //    return canEdit;
            //},
            //canReadProfile(appId, siteId) {
            //    return !!this.userProfiles.find(p => p.appId == appId && p.siteId == siteId && (p.profileId == this.profiles.Manager));
            //},
            userIsDisabled(item) {
                return (item.shiftId == $virtus.config.LEAVER_SHIFT_ID || (item.endDate && !$virtus.tools.dates.isFutureDate(item.endDate)));
            },
            //getProfileName(appId, siteId) {
            //    var profile = this.visibleProfiles.find(ap => ap.appId == appId && ap.siteId == siteId);
            //    if (profile) {
            //        return profile.name;
            //    } else {
            //        return '-- No profile --';
            //    }
            //},
            async showRights(item) {
                this.showErrors = false;
                this.editEmployee = item;
                this.popup.employeeName = item.fullName;
                this.popup.sendChangePasswordEmail = false;
                this.popup.password = null;
                this.popup.passwordChanged = false;
                var userRights = await this.$store.dispatch('security/getUserRightsInfo', { userId: item.id });
                this.popup.pin = userRights.pin;
                this.popup.email = userRights.email;
                var profile = (userRights.userProfiles ? userRights.userProfiles.find(x => x.siteId == this.getSiteId) : null);
                this.popup.profileId = (profile ? profile.profileId : null);
                //this.setProfiles(userRights.userProfiles);
                this.$refs.showAddRights.show();
            },
            rightsOpened() {
                this.$refs.pin.focus();
            },
            async save(/*item*/) {
                this.showErrors = true;
                if (this.isValidForm) {
                    var userId = this.editEmployee.id;
                    var profiles = [];
                    if (this.popup.profileId) {
                        profiles.push({ siteId: this.getSiteId, profileId: this.popup.profileId });
                    }
                    //for (var i in this.popup.profiles) {
                    //    var profile = this.popup.profiles[i];
                    //    profile.profileId = (profile.profileId ? profile.profileId : 0);
                    //    profiles.push(profile);
                    //}
                    var result = await this.$store.dispatch('security/updateUserRightsInfo', {
                        userId: userId,
                        pin: this.popup.pin,
                        email: this.popup.email,
                        userProfiles: profiles,
                        sendChangePasswordEmail: this.popup.sendChangePasswordEmail,
                        password: this.popup.password,
                        passwordChanged: this.popup.passwordChanged,
                    });
                    if (result) {
                        $virtus.tools.messages.showSaveOk('employee rights info', 'updated');
                        //this.editEmployee.pin = this.popup.pin;
                        //this.editEmployee.email = this.popup.email;
                        //this.editEmployee.profileId = this.popup.profileId;
                        this.editEmployee.hasLogin = ((this.popup.pin || this.popup.email) && this.popup.profileId);
                        this.cancel();
                    }
                }
            },
            cancel() {
                this.editEmployee = null;
                this.$refs.showAddRights.hide();
            },
            async setPassword() {
                this.showErrorsPassword = false;
                this.popup.password = null;
                this.popup.passwordChanged = false;
                this.$refs.changePassword.show();
            },
            passwordOpened() {
                this.$refs.password.focus();
            },
            async savePassword() {
                this.showErrorsPassword = true;
                if (this.isValidPassword && !this.missingPassword) {
                    this.popup.passwordChanged = true;
                    this.$refs.changePassword.hide();
                }
            },
            employeeUpdated(employee) {
                //Clear pin number if it is in use by an inactive user
                var items = this.$refs.adminPage.items;
                for (var i in items) {
                    var item = items[i];
                    if (item.id != employee.id && item.pin == employee.pin) {
                        item.pin = null;
                    }
                }
            },
        }
    }
</script>
<style>

/*    @media (min-width: 576px) {
        .popup-manage-rights.modal-dialog {
            max-width: 1000px;
        }
    }*/

    .admin-employees p {
        text-align: justify;
    }

    .admin-employees .modal-body {
        padding: 1rem 1rem 0 1rem;
    }

    .login-message {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .login-message .user-login-icon {
        margin: 0 0.5rem 0 0;
    }

    .login-message .control-checkbox {
        margin-left: auto;
    }

    .password-changed-message {
        padding: 0.25rem 0;
    }
    .password-changed-message svg {
        margin: 0 0.5rem 0 0;
    }

    .user-has-login.fa-icon {
        color: gray;
        vertical-align: middle;
        margin: 0.25rem;
    }

    .user-has-login:hover {
        color: darkgreen;
    }

/*    .profiles {
        width: 100%;
    }

    .profiles .header-text {
        background-color: cadetblue;
        color: white;
        font-weight: 600;
        text-align: center;
    }*/

    .popup-manage-rights .site-name {
        font-weight: 600;
        background-color: cadetblue;
        color: white;
        text-align: center;
        padding: 0.25rem;
    }

    .control-label.with-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .control-label.with-buttons svg {
        cursor: pointer;
    }

    .control-label.with-buttons svg:hover {
        color: cadetblue;
    }

</style>
