<template>
    <div class="file-upload">
        <div class="dropbox" v-if="!fileToUpload">
            <input type="file" id="fileUpload" ref="fileUpload" @change="handleFilesUpload()" accept="pdf/*" class="input-file">
            <div class="message" v-html="message"></div>
        </div>
        <div class="file-actions" v-if="fileToUpload">
            <div>{{fileToUpload ? (newName ? newName : fileToUpload.name) : ''}} <span class="remove-file" v-on:click="removeFile()">Remove</span></div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'fileUpload',
        props: ['initialFile', 'text', 'confirmDelete'],
        data() {
            return {
                message: 'Drag your file here <br/> or click to browse',
                fileToUpload: null,
                newName: null,
            };
        },
        mounted() {
            if (this.text) {
                this.message = this.text;
            }
            if (this.initialFile) {
                this.fileToUpload = { name: this.initialFile };
            } else {
                this.fileToUpload = null;
            }
        },
        watch: {
            initialFile(newValue) {
                this.newName = newValue;
            },
        },
        methods: {
            handleFilesUpload() {
                this.newName = null;
                this.fileToUpload = this.$refs.fileUpload.files[0];
                this.$emit('fileSelected', this.fileToUpload);
            },
            async removeFile() {
                var allowDelete = { isConfirmed: true };
                if (this.confirmDelete) {
                    allowDelete = await $virtus.tools.messages.confirm(this.confirmDelete, 'Are you sure? Do you really want to delete!', true);
                }
                if (allowDelete && allowDelete.isConfirmed) {
                    this.fileToUpload = null;
                    this.$emit('fileRemoved');
                }
            }
        }
    };
</script>
<style>
    .dropbox {
        outline: 2px dashed grey; /* the dash box */
        outline-offset: -10px;
        background: lightcyan;
        color: dimgray;
        padding: 10px 10px;
        height: 100px;
        position: relative;
        cursor: pointer;
    }

        .dropbox .input-file {
            opacity: 0; /* invisible but it's there! */
            width: 100%;
            height: 100px;
            position: absolute;
            cursor: pointer;
        }

        .dropbox:hover {
            background: lightblue; /* when mouse over to the drop zone, change color */
        }

        .dropbox .message {
            font-size: 1.2em;
            text-align: center;
            padding: 20px 0;
            margin-left: 0;
        }

    .file-actions {
        border: 1px solid #dcdfe6;
        padding: 10px;
        background-color: #f8f9fa;
    }

    span.remove-file {
        color: red;
        cursor: pointer;
        float: right;
    }
</style>
