<template>
    <div :class="{ 'assignments-skills': true, 'read-only': !hasWriteRights }">
        <h1 class="header-title">Skill Assignments</h1>

        <div class="header-items-bar">
            <select-assignment-level ref="selectAssignmentLevel" class="header-item" @click="clickAssignmentLevel"></select-assignment-level>

            <!--<select-book-training ref="selectBookTraining" class="header-item" @click="clickBookTraining"></select-book-training>-->

            <div class="header-item">
                <b-input-group size="sm" prepend="Filter">
                    <b-form-input id="skillFilter" size="sm" v-model="skillFilterText" placeholder="skill" title="enter skill filter" @keyup.enter.native="filterClick" />
                    <b-form-input id="jobPositionFilter" v-model="jobPositionFilterText" placeholder="job position" title="enter job position filter" @keyup.enter.native="filterClick" />
                    <b-input-group-append>
                        <b-btn @click.stop="filterClick">Go</b-btn>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <div class="header-item">
                <b-input-group size="sm" prepend="Page size">
                    <b-form-select id="pageSize" size="sm" :options="pageOptions" v-model="perPage" @change="paginationChanged" />
                    <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" @change="paginationChanged" />
                </b-input-group>
            </div>

            <div class="clear"></div>

        </div>

        <div id="assignments-table" class="fixed-table-container">
            <table>
                <thead>
                    <tr class="header even-row">
                        <th v-for="cell in matrix.header" :key="'h_' + cell.id" :class="cell.css" v-html="cell.html"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in matrix.rows" :key="'r_' + row.id" :class="row.css">
                        <td v-for="cell in row.cells" :key="'c_' + cell.id" @click.stop="clickCell(cell)" :class="cell.css" :title="cell.text" v-html="cell.html"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="buttons" v-if="hasWriteRights">
            <span style="float:left;" v-show="itemsChangedCount > 0">Skill assignments changed: {{itemsChangedCount}}</span>
            <span>
                <button type="button" class="btn btn-secondary btn-sm" @click="discardChanges" :disabled="itemsChangedCount == 0">Discard changes</button>
                <button type="button" class="btn btn-primary btn-sm" @click="saveChanges" :disabled="itemsChangedCount == 0">Save changes</button>
            </span>
        </div>

    </div>
</template>

<script>
    import 'core-js/web/immediate';
    import skillCell from './skill-cell.js';
    import models from '../shared/models.js';

    export default {
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                pageOptions: [5, 10, 20, 50, 100],
                selectedAssignmentLevel: null,
                //selectedTrainingDate: null,
                matrix: { header: [], rows: [] },
                itemsChangedCount: 0,
                skillFilterText: '',
                jobPositionFilterText: '',
                skillFilter: '',
                jobPositionFilter: '',
                changedItems: {},
                assignmentLevels: [],
                skills: {},
                sortedSkills: [],
                jobPositions: [],
                hasWriteRights: false,
            }
        },
        async created() {
            this.hasWriteRights = await this.checkUserHasWriteRights();
            await Promise.all([
                this.$store.dispatch('getAssignmentLevels'),
                this.$store.dispatch('skills/getFullSkills'),
                this.$store.dispatch('admin/getJobPositions'),                
            ]).then((values) => {
                this.assignmentLevels = values[0];
                this.skills = values[1];
                this.jobPositions = values[2];
                this.sortedSkills = models.getSortedSkills(this.skills);
                this.loadMatrix();
            });
        },
        beforeRouteLeave(to, from, next) {
            $virtus.tools.changes.beforeRouteLeave(to, from, next, this.itemsChangedCount > 0);
        },
        computed: {
            totalRows() {
                return this.filteredSkills.length;
            },
            first() {
                return 1 + (this.currentPage - 1) * this.perPage;
            },
            paginatedSkills() {
                var skills = this.filteredSkills.slice(this.first - 1, this.first + this.perPage - 1);
                return skills;
            },
            filteredSkills() {
                var skills = this.sortedSkills;
                var skillFilter = this.skillFilter;
                if (skillFilter) {
                    skills = skills.filter(r => { return r.search.includes(skillFilter); });
                }
                return skills;
            },
            filteredJobPositions() {
                var jobPositions = this.jobPositions;
                var jobPositionFilter = this.jobPositionFilter;
                if (jobPositionFilter) {
                    jobPositions = jobPositions.filter(jp => { return jp.search.includes(jobPositionFilter); });
                }
                return jobPositions;
            },
        },

        methods: {
            async clickCell(item) {
                if (!this.hasWriteRights) return;
                if (!item.isHeader) {
                    item.click();
                } 
            },

            clickAssignmentLevel(level) {
                //this.selectedTrainingDate = null;
                this.selectedAssignmentLevel = level;
            //    this.$refs.selectBookTraining.clear();
            },

            //clickBookTraining(trainingDate) {
            //    this.selectedAssignmentLevel = null;
            //    this.selectedTrainingDate = trainingDate;
            //    this.$refs.selectAssignmentLevel.clear();
            //},

            discardChanges() {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    changedItems.forEach(x => x.discardChanges());
                }
                this.changedItems = {};
                this.itemsChangedCount = 0;
                this.showChangesDiscarded();
            },

            showSaveOk() {
                $virtus.tools.messages.showMessage('Success!', 'The skill assignments have been saved successfully.');
            },

            showChangesDiscarded() {
                $virtus.tools.messages.showMessage('Changes discarded', 'All changes have been discarded.');
            },

            async saveChanges() {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    var data = changedItems.map(x => x.getData());
                    let result = await this.$store.dispatch('skills/updateSkillAssignments', data);
                    if (result) {
                        this.showSaveOk();
                        changedItems.forEach(x => x.saveChanges());
                        this.changedItems = {};
                        this.itemsChangedCount = 0;
                    }
                }
            },

            filterClick() {
                this.currentPage = 1;
                this.skillFilter = this.skillFilterText.toLocaleLowerCase();
                this.jobPositionFilter = this.jobPositionFilterText.toLocaleLowerCase();
                this.loadMatrix();
            },

            paginationChanged() {
                this.loadMatrix();
            },

            loadMatrix() {
                var self = this;
                $virtus.tools.loader.show();

                var matrix = { header: [], rows: [] };
                var skills = [];
                var jobPositions = [];

                function part1() {
                    //var a = new Date();
                    //console.log("part 1 - start");
                    //Apply filters
                    skills = self.paginatedSkills;
                    jobPositions = self.filteredJobPositions;

                    setImmediate(part2);
                    //console.log("part 1 - end " + (new Date() - a) + ' ms');
                }

                function part2() {
                    //var a = new Date();
                    //console.log("part 2 - start");
                    //Create header
                    matrix.header.push({ id: 0, css: 'head-col-label', html: '<div>Job Position</div>' });
                    for (var i = 0; i < skills.length; i++) {
                        var skill = skills[i];
                        if (!skill.html) {
                            models.refreshComputedSkillFields(skill);
                        }
                        matrix.header.push({
                            id: skill.id,
                            html: skill.html
                        });
                    }
                    setImmediate(part3);
                    //console.log("part 2 - end " + (new Date() - a) + ' ms');
                }

                function part3() {
                    //var a = new Date();
                    //console.log("part 3 - start");

                    //Create rows
                    for (var i = 0; i < jobPositions.length; i++) {
                        var even = (i % 2);
                        var jobPosition = jobPositions[i];
                        var row = {
                            id: jobPosition.id,
                            css: (even ? 'even-row' : ''),
                            cells: [{
                                id: jobPosition.id,
                                text: jobPosition.name,
                                html: jobPosition.name,
                                isHeader: true,
                                css: 'head-col'
                            }]
                        };
                        for (var j = 0; j < skills.length; j++) {
                            var skill = skills[j];
                            row.cells.push(new skillCell(self, jobPosition, skill));
                        }
                        matrix.rows.push(row);
                    }

                    self.matrix = matrix;
                    setImmediate(part4);
                    //console.log("part 3 - end " + (new Date() - a) + ' ms');
                }

                function part4() {
                    //var a = new Date();
                    //console.log("part 4 - start");
                    $virtus.tools.fixTable(document.getElementById('assignments-table'));
                    $virtus.tools.loader.close();
                    //console.log("part 4 - end " + (new Date() - a) + ' ms');
                }

                //Start long running task
                setImmediate(part1);
            },
        }
    }
</script>

<style>


</style>
