<template>
    <div>
        <admin-page :actions="actions" :fields="fields" :texts="texts" sort-by="description"></admin-page>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                fields: [
                    { key: 'description', label: 'Description', sortable: true, validations: { required: true, maxLength: 500 } }
                ],
                actions: {
                    get: 'skills/getSkills',
                    insert: 'skills/addSkill',
                    update: 'skills/editSkill',
                    delete: 'skills/removeSkill',
                },
                texts: {
                    listTitle: 'Skills',
                    newTitle: 'Add New Skill',
                    addButton: 'Add Skill',
                    deleteButton: 'Remove',
                    itemName: 'skill',
                    askDelete: 'This action will delete the skill and related assignments and signatures. Are you sure?',
                },
            }
        },
        async created() {
        },
        computed: {

        },
        methods: {

        }
    }
</script>

<style>
</style>
