
function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}

function saveByteArray(fileName, byte, mimeType) {
    window.URL = window.URL || window.webkitURL;
    var os = getMobileOperatingSystem();
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, fileName);
    } else if (os == "iOS") {
        var out = new Blob([byte], { type: mimeType });
        var reader = new FileReader();
        reader.onload = function (/*e*/) {
            window.location.href = reader.result;
            //window.open(reader.result);
        }
        reader.readAsDataURL(out);
    } else {
        var blob = new Blob([byte], { type: mimeType });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        //link.href=window.webkitURL.createObjectURL(blob);
        //a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        link.download = fileName.substr(fileName.lastIndexOf('/') + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

let stores = {
    convertArrayToDictionary(items) {
        var dictionary = {};
        for (var i in items) {
            var item = items[i];
            dictionary[item.id] = item;
        }
        return dictionary;
    },
    convertDictionaryToArray(dictionary) {
        var items = [];
        for (var i in dictionary) {
            var item = dictionary[i];
            items.push(item);
        }
        return items;
    },

    downloadPDF(fileName, data) {
        //const linkSource = 'data:application/pdf;base64,' + data;
        //const downloadLink = document.createElement("a");
        //downloadLink.href = linkSource;
        //downloadLink.download = fileName;
        //downloadLink.click();
        //var bytes = base64ToArrayBuffer(data);
        saveByteArray(fileName, data, 'application/pdf; charset=utf-8');
    },

    downloadExcelFile(fileName, data) {
        saveByteArray(fileName, data, 'application/octet-stream; charset=utf-8');
        //downloadFile(fileName, data, 'application/octet-stream; charset=utf-8');
    },

    getOptionsFromArray(array, key, text) {
        var options = [];
        var items = array || [];
        for (var i = 0; i < items.length; i++) {
            options.push({ value: items[i][key], text: items[i][text] });
        }
        return options;
    }

};

export default stores;
