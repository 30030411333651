import axios from 'axios';
import messages from './messages';


// Add a request interceptor
axios.interceptors.request.use(
    config => {
        if (!config.headers['Content-Type']) {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    error => {
        console.log(error);
        messages.error('Sorry!', 'There was an error. Please, try again.');
        return Promise.reject(error);
    }
);


//Add a response interceptor
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        var errorHandled = false;
        if (error && error.config && error.config.customErrorHandler) {
            errorHandled = error.config.customErrorHandler(error);
        }

        var showGenericError = true;

        if (!errorHandled && error && error.response && error.response.status === 401) {
            showGenericError = false; //We manage this error from security package
        }

        var url = new URL(error.config.url);
        if (url.pathname == '/api/security/logout' && error.response && error.response.status === 403) {
            showGenericError = false; //The user was not logged in
        }

        //Custom error message from server
        if (!errorHandled && error && error.response && error.response.status == 409 && error.response.data) {
            var data = error.response.data;
            var title = 'Error';
            var message = data;
            if (data.title && data.message) {
                title = data.title;
                message = data.message;
            }
            messages.error(title, message);
            showGenericError = false;
        }

        //Generic error message
        if (!errorHandled && showGenericError) {
            messages.error('Sorry!', 'There was an error. Please, try again.');
        }
        return Promise.reject(error);
    }
);

