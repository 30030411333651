
let validation = {
    validateEmail(email) {
        var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/;
        if (!reg.test(email)) return false;
        return true;
    },
    validatePassword(password) {
        var reg = /^(?!(.*[^a-zA-Z0-9]){1,})(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){1,}).{8,}$/;
        if (!reg.test(password)) return false;
        return true;
    },
    onlyNumbers(e) {
        if (e.charCode === 0 || /\d/.test(String.fromCharCode(e.charCode))) {
            return true;
        } else {
            e.preventDefault();
        }
    }
};

export default validation;
