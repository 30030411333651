
function employeeSkillCell(viewModel, employee, skill, employeeAssignments) {
    var self = this;
    self.id = skill.id + '_' + employee.id;
    var assignment = employeeAssignments[skill.id];
    var originalEmployeeAssignment = !!assignment;
    var isEmployeeAssignment = originalEmployeeAssignment;
    var jobPositionAssignment = skill.assignments[employee.jobPositionId];
    var originalAssignmentLevelId = (assignment ? assignment.assignmentLevelId : (jobPositionAssignment ? jobPositionAssignment.assignmentLevelId : 0));
    self.assignmentLevelId = originalAssignmentLevelId;
    var originalTrainingDate = (assignment ? assignment.trainingDate : (jobPositionAssignment ? jobPositionAssignment.trainingDate : null));
    var trainingDate = originalTrainingDate;
    var changed = false;
    self.employee = employee;
    self.skill = skill;
    self.html = '';
    self.text = '';
    self.css = '';
    self.click = async function () {
        var update = true;

        if (viewModel.selectedAssignmentLevel != null) {
            if (viewModel.selectedAssignmentLevel.id > 0) {
                self.assignmentLevelId = viewModel.selectedAssignmentLevel.id;
                isEmployeeAssignment = true;
            } else { //Clear selected
                trainingDate = null;
                isEmployeeAssignment = false;
                self.assignmentLevelId = (jobPositionAssignment ? jobPositionAssignment.assignmentLevelId : 0);
            }
        } else if (viewModel.selectedTrainingDate != null) {
            if (viewModel.selectedTrainingDate.trainingDate != null && self.assignmentLevelId > 0) {
                trainingDate = viewModel.selectedTrainingDate.trainingDate;
                isEmployeeAssignment = true;
            } else { //Clear training date
                trainingDate = null;
            }
        } else {
            update = false;
        }

        if (update) {
            await updateChangedItem();
            updateHtml();
        }
    };
    self.getData = function () {
        return {
            skillId: skill.id,
            employeeId: employee.id,
            assignmentLevelId: (isEmployeeAssignment ? self.assignmentLevelId : 0), //Set assignment level to 0 for job position levels
            trainingDate: trainingDate
        };
    };
    self.saveChanges = function () {
        originalAssignmentLevelId = self.assignmentLevelId;
        originalTrainingDate = trainingDate;
        changed = false;
        if (!viewModel.employeesAssignments[employee.id]) viewModel.employeesAssignments[employee.id] = {};
        var assignments = viewModel.employeesAssignments[employee.id];
        if (self.assignmentLevelId && self.assignmentLevelId > 0) {
            assignments[skill.id] = {
                assignmentLevelId: self.assignmentLevelId,
                trainingDate: trainingDate,
            };
        } else {
            delete assignments[skill.id];
        }
        updateHtml();
    };
    self.discardChanges = function () {
        self.assignmentLevelId = originalAssignmentLevelId;
        trainingDate = originalTrainingDate;
        isEmployeeAssignment = originalEmployeeAssignment;
        changed = false;
        updateHtml();
    };

    //Init
    updateHtml();

    async function updateChangedItem() {
        //Set assignment state
        var changedItem = viewModel.changedItems[self.id];
        changed = (self.assignmentLevelId != originalAssignmentLevelId || trainingDate != originalTrainingDate || isEmployeeAssignment != originalEmployeeAssignment);
        if (!changed && changedItem) {
            //Not changed, delete changed item
            viewModel.itemsChangedCount--;
            delete viewModel.changedItems[self.id];
        } else if (changed && !changedItem) {
            //Changed now, create changed item
            viewModel.itemsChangedCount++;
            viewModel.changedItems[self.id] = self;
        } //Else Not changed, not changed item: do nothing
    }

    function updateHtml() {
        if (self.assignmentLevelId > 0) {
            self.text = self.assignmentLevelId;
            self.html = self.assignmentLevelId;
            self.css = 'clickable assignment-level-' + self.assignmentLevelId;
        } else {
            self.text = '';
            self.html = '';
            self.css = 'clickable';
        }
        if (isEmployeeAssignment) {
            self.html += '<span>(employee)</span>';
            self.css += ' employee';
        }
        if (changed) {
            self.html += '<span class="note"></span>';
        }
        if (trainingDate) {
            self.html += '<span class="training-date">' + $virtus.tools.dates.format(trainingDate, 'dd/MM') + '</span>';
        }
    }
}

export default employeeSkillCell;
