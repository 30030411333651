<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

    export default {
        name: 'container-page',
        data() {
            return {

            }
        },
        computed: {
        }
    }
</script>

<style>
</style>
