<template>
    <div class="control control-checkbox">
        <div class="control-label" v-if="label">{{label}}</div>
        <div :class="(controlClass ? controlClass : '') + (showErrorState ? ' is-invalid' : '')">
            <span v-if="position != 'right' && position != 'bottom'" class="check">
                <span :style="{ display: (!checked ? 'inline' : 'none') }" @click="check()" :class="readOnly ? 'read-only' : 'cursor-hand'">
                    <icon name="regular/square" class="input-icon square-icon unchecked"></icon>
                </span>
                <span :style="{ display: (checked ? 'inline' : 'none') }" @click="unckeck()" :class="readOnly ? 'read-only' : 'cursor-hand'">
                    <icon class="input-icon">
                        <icon name="regular/square" class="square-icon child-icon"></icon>
                        <icon name="check" scale="1.5" :class="(readOnly ? 'check-gray-icon' : 'check-green-icon') + ' child-icon'"></icon>
                    </icon>
                </span>
            </span>
            <slot></slot>
            <span v-if="position == 'right'" class="check">
                <span :style="{ display: (!checked ? 'inline' : 'none') }" @click="check()" :class="readOnly ? 'read-only' : 'cursor-hand'">
                    <icon name="regular/square" class="input-icon square-icon unchecked"></icon>
                </span>
                <span :style="{ display: (checked ? 'inline' : 'none') }" @click="unckeck()" :class="readOnly ? 'read-only' : 'cursor-hand'">
                    <icon class="input-icon">
                        <icon name="regular/square" class="square-icon child-icon"></icon>
                        <icon name="check" scale="1.5" :class="(readOnly ? 'check-gray-icon' : 'check-green-icon') + ' child-icon'"></icon>
                    </icon>
                </span>
            </span>
            <div v-if="position == 'bottom'" class="check">
                <span :style="{ display: (!checked ? 'inline' : 'none') }" @click="check()" :class="readOnly ? 'read-only' : 'cursor-hand'">
                    <icon name="regular/square" class="input-icon square-icon unchecked"></icon>
                </span>
                <span :style="{ display: (checked ? 'inline' : 'none') }" @click="unckeck()" :class="readOnly ? 'read-only' : 'cursor-hand'">
                    <icon class="input-icon">
                        <icon name="regular/square" class="square-icon child-icon"></icon>
                        <icon name="check" scale="1.5" :class="(readOnly ? 'check-gray-icon' : 'check-green-icon') + ' child-icon'"></icon>
                    </icon>
                </span>
            </div>
            <div v-if="errors">
                <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'control-checkbox',
        props: ['label', 'value', 'position', 'hasErrors', 'readOnly', 'errors', 'controlClass'],
        data() {
            return {
                checked: false,
            };
        },
        mounted() {
            this.checked = this.value;
        },
        computed: {
            showErrorState() {
                return (this.errors || this.hasErrors);
            },
        },
        watch: {
            value(newValue) {
                this.checked = newValue;
            },
        },
        methods: {
            check() {
                if (!this.readOnly) {
                    this.checked = true;
                    this.$emit('input', true);
                }
            },
            unckeck() {
                if (!this.readOnly) {
                    this.checked = false;
                    this.$emit('input', false);
                }
            }
        }
    };
</script>

<style>
    .control-checkbox .read-only svg {
    }

    .control-checkbox .check {
        /*        position: relative;
            top: 0.3rem;
    */ margin: 0.2rem;
    }

    .control-checkbox .check svg {
        vertical-align: -0.35rem;
        padding: 0.1rem;
    }

</style>
