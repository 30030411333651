<template>
    <div class="main-form">
        <h1 class="header-title">Skill Signatures</h1>
        <div>
            <p>
                This report shows all the skill signatures for all the employees.
            </p>
        </div>
        <div class="buttons">
            <!--<button type="button" class="btn btn-secondary btn-sm" @click="download(false)">Open</button>-->
            <button type="button" class="btn btn-primary btn-sm" @click="download(true)">Download</button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
            }
        },
        async created() {
        },
        computed: {
        },
        methods: {
            download(download) {
                this.$store.dispatch('reports/getSkillSignaturesReport', { download });
            },
        }
    }
</script>

<style>
</style>
