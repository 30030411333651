<template>
    <div class="control control-tags">
        <div class="control-label" v-if="label">{{label}}</div>
        <div v-if="readOnly" class="read-only">
            <span :key="index" v-for="(tag, index) in tags" class="tag-item">{{tag.text}}</span>
        </div>
        <div v-else>
            <vue-tags-input ref="tagsInput" v-model="tag" :tags="tags" @tags-changed="tagsChanged" :placeholder="placeholder" :autocomplete-min-length="0" :add-on-key="[13,',',';','\n']"
                            :autocomplete-items="items" :class="{ 'is-invalid': errors }" :delete-on-backspace="false" :add-on-blur="!keyboardElement"
                            :max-tags="maxTags" :add-only-from-autocomplete="onlyAutocomplete" @input="onInput" @focus="showKeyboard"></vue-tags-input>
        </div>
        <div v-if="errors">
            <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'control-tags',
        props: ['label', 'value', 'placeholder', 'hasErrors', 'readOnly', 'items', 'blockedItems', 'maxTags', 'onlyAutocomplete', 'errors', 'required'],
        data() {
            return {
                tag: '',
                tags: [],
                element: null,
                keyboardElement: false, //To avoid errors from virtual keyboard
            };
        },
        mounted() {
            this.loadValues();
        },
        computed: {
            //...mapState(['keyboardValue', 'keyboardElement']),
            textValue() {
                return this.getPlainText(this.tags);
            },
            autocompleteOpen() {
                return (this.$refs.tagsInput && this.$refs.tagsInput.autocompleteOpen);
            },
        },
        watch: {
            value() {
                this.loadValues();
            },
            //keyboardValue(newValue) {
            //    if (this.keyboardElement && this.element && this.element.keyboardElementId == this.keyboardElement.keyboardElementId) {
            //        if (newValue && newValue.endsWith('\n')) {
            //            this.tags.push({ text: this.tag });
            //            this.tag = '';
            //            this.$store.commit('setKeyboardValue', '');
            //            this.tagsChanged(this.tags);
            //        } else {
            //            this.tag = newValue;
            //        }
            //    }
            //},
        },
        methods: {
            onInput(/*newValue*/) {
                //if (this.keyboardElement) {
                //    this.$store.commit('setKeyboardValue', newValue);
                //}
            },
            loadValues() {
                if (this.value != this.textValue) {
                    this.tags = (this.value ? this.value.split(';').map(i => { return { text: i, classes: (this.blockedItems && this.blockedItems.includes(';' + i + ';') ? 'tag-blocked' : '') }; }) : []);
                }
            },
            tagsChanged(newTags) {
                var text = this.getPlainText(newTags);
                this.$emit('input', text);
            },
            getPlainText(tags) {
                return (tags && tags.length > 0 ? tags.map(i => i.text).join(';') : '');
            },
            showKeyboard(e) {
                this.element = e.target;
                //if (this.keyboardElement) {
                //    this.$store.commit('setKeyboardValue', this.tag);
                //    this.$store.commit('showKeyboard', this.element);
                //}
            },
        }
    };
</script>

<style>
    .control-tags div.read-only {
        margin: 0.2rem 0;
        border-radius: 0.25rem;
        border: 1px solid grey;
        padding: 0.3rem 0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        flex: 1 1 auto;
        font-size: 0.9rem;
        min-height: 1.8rem;
    }


    .control-tags .read-only .tag-item {
        cursor: default;
        background-color: #5C6BC0;
        color: #fff;
        border-radius: 2px;
        padding: 3px 6px;
        margin: 2px 3px 1px 3px;
    }

    .control-tags .vue-tags-input {
        width: 100%;
        max-width: 100% !important;
        margin: 0.2rem !important;
        border-radius: .25rem;
    }

    .control-tags .ti-input {
        border-radius: .25rem;
    }

    .control-tags.is-invalid .ti-input {
        border-color: #dc3545;
    }

    .control-tags .ti-tag {
        font-size: 1rem;
    }

    .control-tags .is-invalid {
        border: 1px solid #dc3545;
    }

    .control-tags .ti-autocomplete {
        max-height: 300px;
        overflow-y: auto;
    }

    .control-tags .tag-blocked .ti-actions {
        display: none !important;
    }

    .control-tags .tag-blocked {
        position: relative;
    }

        .control-tags .tag-blocked:before {
            content: "Already signed";
            padding: 4px;
            display: none;
            position: absolute;
            top: 20px;
            left: 20px;
            width: 86px;
            text-align: center;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 8pt;
            color: #505050;
            background-color: #FFFFE0;
            border: 1px solid #505050;
            -moz-border-radius: 2px;
            -webkit-border-radius: 2px;
            -ms-border-radius: 2px;
            border-radius: 2px;
            z-index: 100;
        }

        .control-tags .tag-blocked:hover:before {
            display: block;
        }
</style>
