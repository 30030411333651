
export const allProfiles = [
    { id: 1, key: 'PlatformAdmin', name: 'Platform Admin', visible: false },
    { id: 2, key: 'Administrator', name: 'Administrator', visible: false },
    { id: 3, key: 'Manager', name: 'Manager', visible: true },
    { id: 5, key: 'Worker', name: 'Worker', visible: true },
    { id: 6, key: 'IPadSignatureUser', name: 'iPad Signature User', visible: false },
    { id: 8, key: 'SkillManager', name: 'Skill Manager', visible: true },
];

export const profiles = Object.assign({}, ...allProfiles.map(x => ({ [x.key]: x.id })));

export const profileOptions = allProfiles.filter(x => x.visible).map(x => ({ id: x.id, name: x.name }));

export default profiles;