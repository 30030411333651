<template>
    <div class="page-container">
        <welcome-panel>
            <div class="display-3">{{title}}</div>
            <p class="lead">{{description}}</p>
        </welcome-panel>

        <home-counters :show-admin="showAdmin"></home-counters>

    </div>
</template>

<script>

    export default {
        data() {
            return {
            }
        },
        computed: {
            showAdmin() {
                return (this.appId == 1); // 1 = Administration
            },
            title() {
                return (this.showAdmin ? 'VIRTUS ADMINISTRATION' : 'HSE RECORD' );
            },
            description() {
                return (this.showAdmin ? 'Application for managing shared information' : 'Application for managing procedures and RAMS');
            }
        },
        methods: {

        }
    }
</script>

<style>

</style>
