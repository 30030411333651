import Vue from 'vue';
import Router from 'vue-router';
//import store from './store';

var security = $virtus.security;
var controls = $virtus.controls;
var profiles = security.profiles;
var containerPage = controls.components.containerPage;
var ramViewer = controls.components.ramViewer;

Vue.use(Router);

var navigation = [
    //ADMIN
    { title: 'Home', path: '/admin', component: () => import('./home/home.vue'), exact: true, meta: { onlyAdmin: true } },
    { title: 'Management', path: '/admin/management', component: containerPage, meta: { onlyAdmin: true }, children: [
        { title: 'Employees', path: '/admin/management/employees', component: () => import('./admin/management/admin-employees.vue'), meta: { onlyAdmin: true, showRights: true } },
        { title: 'Job Positions', path: '/admin/management/jobpositions', component: () => import('./admin/management/admin-job-positions.vue'), meta: { onlyAdmin: true } },
        { title: 'Areas', path: '/admin/management/areas', component: () => import('./admin/management/admin-areas.vue'), meta: { onlyAdmin: true } },
        { title: 'Departments', path: '/admin/management/departments', component: () => import('./admin/management/admin-departments.vue'), meta: { onlyAdmin: true } },
        { title: 'Shifts', path: '/admin/management/shifts', component: () => import('./admin/management/admin-shifts.vue'), meta: { onlyAdmin: true } },
    ]},
    { title: 'Reports', path: '/admin/reports', component: containerPage, meta: { onlyUsers: [1,23] }, children: [
        { title: 'Monthly RAMS SKILLS Summary', path: '/admin/reports/monthly-summary', component: () => import('./admin/reports/reports-monthly-summary.vue'), meta: { onlyUsers: [1, 23] } },
        { title: 'Training Costs Summary', path: '/admin/reports/training-costs-summary', component: () => import('./admin/reports/reports-training-costs.vue'), meta: { onlyUsers: [1, 23] } }
    ]},
    //RECORD
    { title: 'Home', path: '/record', component: () => import('./home/home.vue'), exact: true, meta: { read: [profiles.Manager, profiles.SkillManager] } },
    { title: 'Management', path: '/record/management', component: containerPage, meta: { read: [profiles.Manager, profiles.SkillManager] }, children: [
        { title: 'RAMS / TBT', path: '/record/management/rams', component: () => import('./record/management/management-rams.vue'), meta: { onlyAdmin: true } },
        { title: 'Skills Training', path: '/record/management/skills', component: () => import('./record/management/management-skills.vue'), meta: { write: profiles.SkillManager } },
        { title: 'Training Costs', path: '/record/management/training-costs', component: () => import('./record/management/training-costs.vue'), meta: { write: [profiles.Manager, profiles.SkillManager] } },
        { title: 'Employees', path: '/record/management/employees', component: () => import('./admin/management/admin-employees.vue'), meta: { write: profiles.SkillManager, showRights: false } },
    ]},
    { title: 'Assignments', path: '/record/assignments', component: containerPage, meta: { read: profiles.SkillManager }, children: [
        { title: 'Employees RAMS Assignment', path: '/record/assignments/employees/rams', component: () => import('./record/assignments/assignments-employees-rams.vue'), meta: { onlyAdmin: true } },
        { title: 'Employees Skills Assignment', path: '/record/assignments/employees/skills', component: () => import('./record/assignments/assignments-employees-skills.vue'), meta: { write: profiles.SkillManager } },
        { title: 'RAMS Assignment', path: '/record/assignments/rams', component: () => import('./record/assignments/assignments-rams.vue'), meta: { onlyAdmin: true } },
        { title: 'Skills Assignment', path: '/record/assignments/skills', component: () => import('./record/assignments/assignments-skills.vue'), meta: { write: profiles.SkillManager } }
    ]},
    { title: 'Signatures', path: '/record/signatures', component: containerPage, meta: { write: [profiles.Manager, profiles.SkillManager] }, children: [
        { title: 'RAMS Signsheet', path: '/record/signatures/rams', component: () => import('./record/signatures/signatures-rams.vue'), meta: { read: profiles.Manager } },
        { title: 'Skills Signsheet', path: '/record/signatures/skills', component: () => import('./record/signatures/signatures-skills.vue'), meta: { read: profiles.Manager, write: profiles.SkillManager } },
        { title: 'RAMS / TBT Validation', path: '/record/signatures/sop', component: () => import('./record/signatures/sop-validations.vue'), meta: { write: profiles.Manager } },
    ]},
    { title: 'Reports', path: '/record/reports', component: containerPage, meta: { read: [profiles.Manager, profiles.SkillManager] }, children: [
        { title: 'RAMs to be updated', path: '/record/reports/rams/tobeupdated', component: () => import('./record/reports/reports-rams-to-be-updated.vue'), meta: { read: profiles.Manager } },
        { title: 'Required RAMS signatures', path: '/record/reports/rams/requiredbyperson', component: () => import('./record/reports/reports-required-rams-by-person.vue'), meta: { read: profiles.Manager } },
        { title: 'Required Skill signatures', path: '/record/reports/skills/requiredbyperson', component: () => import('./record/reports/reports-required-skills-by-person.vue'), meta: { read: [profiles.Manager, profiles.SkillManager] } },
        { title: 'Employee signed sheets', path: '/record/reports/employee/signedsheets', component: () => import('./record/reports/reports-employee-signed-sheets.vue'), meta: { read: profiles.Manager } },
        { title: 'RAM signed sheets', path: '/record/reports/rams/signedsheets', component: () => import('./record/reports/reports-ram-signed-sheets.vue'), meta: { read: profiles.Manager } },
        { title: 'RAM signatures', path: '/record/reports/rams/signatures', component: () => import('./record/reports/reports-ram-signatures.vue'), meta: { read: profiles.Manager } },
        { title: 'Skill signatures', path: '/record/reports/skills/signatures', component: () => import('./record/reports/reports-skill-signatures.vue'), meta: { read: [profiles.Manager, profiles.SkillManager] } },
        { title: 'Expiring Skills', path: '/record/reports/skills/expiring', component: () => import('./record/reports/reports-expiring-skills.vue'), meta: { read: [profiles.Manager, profiles.SkillManager] } },
    ]},
    { title: 'Help', path: '/record/help', component: containerPage, meta: { read: profiles.Manager }, children: [
        { title: 'Contact', path: '/record/help/contact', component: () => import('./record/help/contact-form.vue'), meta: { read: profiles.Manager } },
    ]},
    { path: '/record/signatures/sop/:sopTestId', component: () => import('./record/signatures/sop-test-review.vue'), meta: { write: profiles.Manager } },
    //WORKER
    { title: 'Home', path: '/mywork', component: () => import('./mywork/index.vue'), meta: { allProfiles: true } },
    { title: 'My RAMS', path: '/mywork/rams', component: () => import('./mywork/rams-index.vue'), meta: { allProfiles: true } },
    { path: '/mywork/rams/sign', component: () => import('./mywork/rams-sign.vue'), meta: { allProfiles: true } },
    { title: 'My SOP', path: '/mywork/sop', component: () => import('./mywork/sop-index.vue'), meta: { allProfiles: true } },
    { path: '/mywork/sop/:ramId/:sopTestId', component: () => import('./mywork/sop-test.vue'), meta: { allProfiles: true } },
    //{ title: 'Signatures', path: '/mywork/signatures', component: containerPage, meta: { allProfiles: true }, children: [
    //    { title: 'My RAMS Signatures', path: '/mywork/signatures/rams', component: () => import('./mywork/rams-index.vue'), meta: { allProfiles: true } },
    //    { title: 'My SOP tests', path: '/mywork/signatures/sop', component: () => import('./mywork/sop-index.vue'), meta: { allProfiles: true } },
    //]},
    //SHARED
    { path: '/viewer/:key', component: ramViewer, meta: { allProfiles: true, viewer: true } },
    //{ path: '/viewer/video/:key', component: () => import('./record/shared/video-viewer.vue'), meta: { allProfiles: true } },

    //VISITORS
//    { path: '/visitors/signatures', component: () => import('./visitors/index.vue'), allowed: { exact: profiles.IPadVisitor } },
//    { path: '/visitors/signatures/data', component: () => import('./visitors/visitors-data.vue'), allowed: { exact: profiles.IPadVisitor } },
//    { path: '/visitors/signatures/sign', component: () => import('./visitors/visitors-sign.vue'), allowed: { exact: profiles.IPadVisitor } },
//    { path: '/visitors/signatures/form', component: () => import('./visitors/visitors-form.vue'), allowed: { exact: profiles.IPadVisitor } },
//    { path: '/visitors/signatures/video/:lang', component: () => import('./visitors/visitors-video.vue'), allowed: { exact: profiles.IPadVisitor } },
];

var router = new Router({
    mode: 'history',
    base: __dirname,
    routes: navigation
});

router.beforeEach(async (to, from, next) => {
    //var navigate = false;
    //if (to.meta.anonymous) {
    //    navigate = true;
    //} else {
    //    var userId = store.getters['security/getUserId'];
    //    if (userId) {
    //        if (to.meta.onlyUsers) {
    //            if (!Array.isArray(to.meta.onlyUsers)) to.meta.onlyUsers = [to.meta.onlyUsers];
    //            navigate = to.meta.onlyUsers.includes(userId);
    //        } else {
    //            var profile = store.getters['security/getCurrentProfileId'];
    //            var isAdmin = (profile == profiles.PlatformAdmin || profile == profiles.Administrator);
    //            if (to.meta.onlyAdmin) {
    //                navigate = isAdmin;
    //            } else if (to.meta.allProfiles) {
    //                navigate = (isAdmin || profile == profiles.Manager || profile == profiles.Worker);
    //            } else if (to.meta.allowed) {
    //                if (!Array.isArray(to.meta.allowed)) to.meta.allowed = [to.meta.allowed];
    //                navigate = isAdmin || to.meta.allowed.includes(profile);
    //            }
    //        }
    //    }
    //}
    var navigate = await $virtus.security.userCanAccessRoute(to);
    //store.state.showViewer = (to.name == 'viewer');
    if (!navigate) {
        var query = (to.path == '/' ? '' : { redirect: to.path });
        router.push({ path: '/login', query });
    } else {
        next();
    }
});

export default router;
