<template>
    <form @submit.prevent="login" autocomplete="off">
        <simple-modal ref="forgottenPasssword" name="forgottenPasssword" @opened="opened" :clickToClose="false" buttonText="Send email"
                      @save="sendForgottenPasswordEmail" @cancel="cancel">
            <template slot="modal-header">
                <header class="modal-header bg-dark text-light">
                    <h5 class="modal-title">Forgotten Password</h5>
                </header>
            </template>
            <template slot="modal-content">
                <br />
                If you forgot your password and your account has email, we can send you an email with instructions to set a new password.
                If you use a pin number to enter the application you need to contact the administrator. 
                <br />
                <control-text label="Email" v-model.trim="email" ref="email" placeholder="Please, enter your email"></control-text>
                <p v-if="showErrors && missingEmail" class="error">Email field is required.</p>
                <p v-if="showErrors && emailNotValid && !missingEmail" class="error">The email field is not valid.</p>

                <p v-if="showForgottenPasswordError" class="error">There was an error sending you the forgotten password email. Please, try again or contact the administrator.</p>

            </template>
        </simple-modal>
    </form>
</template>
<script>

    export default {
        name: 'forgotten-password',
        props: [],
        data() {
            return {
                email: null,
                showErrors: false,
                showForgottenPasswordError: false,
            }
        },
        created() {
        },
        mounted() {
            this.$refs.forgottenPasssword.show();
        },
        computed: {
            missingEmail() {
                return (!this.email);
            },
            emailNotValid() {
                return (!!this.email && !$virtus.tools.validation.validateEmail(this.email));
            },
            isValidForm() {
                return (!this.missingEmail && !this.emailNotValid);
            },
        },
        methods: {
            async sendForgottenPasswordEmail() {
                this.showErrors = true;
                this.showForgottenPasswordError = false;
                if (this.isValidForm) {
                    var result = await this.$store.dispatch('security/forgottenPassword', {
                        email: this.email,
                    });
                    if (result) {
                        await $virtus.tools.messages.success('Email sent', 'We have sent an email with instructions to set a new password.');
                        this.$router.push('/logout');
                    } else {
                        this.showForgottenPasswordError = true;
                    }
                }
            },
            opened() {
                this.$refs.email.focus();
            },
            cancel() {
                this.$router.push('/login');
            },
        }
    }
</script>
<style>

</style>
