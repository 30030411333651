<template>
    <div class="main-form training-costs">
        <h1 class="header-title">Training Costs Summary</h1>
        <div>
            <p>
                This report shows a summary of training costs by manager for the selected year.
            </p>

            <control-select label="Year" :options="years" v-model="year"></control-select>
        </div>
        <div v-show="showErrors && missingDate" class="error">Year field is mandatory.</div>
        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="download">Download</button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                year: null,
                showErrors: false
            }
        },
        async created() {
            this.year = this.today.year;
        },
        computed: {
            today() {
                return $virtus.tools.dates.getToday();
            },
            years() {
                return $virtus.tools.dates.getYearOptions();
            },
            missingDate() {
                return (!this.year);
            },
        },
        methods: {
            download() {
                this.showErrors = true;
                if (!this.missingDate) {
                   this.$store.dispatch('adminReports/getTrainingCostsReport', { download: true, year: this.year });
                }
            },
        }
    }
</script>

<style>

</style>
