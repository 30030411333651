
<template>
    <div class="main-nav">
        <b-navbar toggleable="md" type="dark" variant="dark" sticky>
            <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

            <b-navbar-brand href="#">{{title}}</b-navbar-brand>

            <b-collapse is-nav id="nav_collapse">
                <b-navbar-nav>
                    <slot></slot>

                    <span v-for="item in leftMenuItems" :key="item.id">
                        <b-nav-item :to="item.path" v-if="!item.children" :exact="item.exact">{{item.title}}</b-nav-item>
                        <b-nav-item-dropdown v-else :text="item.title" :class="{'active': subIsActive(item.path)}">
                            <b-dropdown-item v-for="child in item.children" :key="child.id" :to="child.path">{{child.title}}</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </span>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item v-for="item in rightMenuItems" :key="item.id" :to="item.path" right>{{item.title}}</b-nav-item>
                    <b-nav-item-dropdown class="user-menu" right>
                        <template #button-content>
                            <div class="user-menu-item">
                                <icon name="user-circle" scale="1.5"></icon>
                                <span>{{userName}}<br /><small>{{getSiteName}}</small></span>
                            </div>
                        </template>
                        <div v-if="showAppsOptions">
                            <div class="menu-group-title">Applications</div>
                            <b-dropdown-item v-for="app in userApps" :key="app.appId" @click="changeApp(app.appId)" :class="{ selected: app.selected }">{{app.appName}}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div v-if="showSitesOptions">
                            <div class="menu-group-title">Sites</div>
                            <b-dropdown-item v-for="site in userSites" :key="site.siteId" @click="changeSite(site.siteId)" :class="{ selected: site.selected }">{{site.siteName}}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <b-dropdown-item to="/logout">Logout</b-dropdown-item>
                    </b-nav-item-dropdown>

                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        name: 'menu-bar',
        data() {
            return {
                menuItems: [],
                leftMenuItems: [],
                rightMenuItems: []
            }
        },
        async mounted() {
            this.menuItems = await this.loadMenuItems();
            this.leftMenuItems = await this.loadLeftMenuItems();
            this.rightMenuItems = await this.loadRightMenuItems();
        },
        computed: {
            ...mapState('security', ['userName']),
            ...mapGetters('security', ['isLoggedIn', 'getUserApps', 'getUserSites', 'getSiteName']),
            isAdmin() {
                return (this.appId == 1); // 1 = Administration
            },
            isWorker() {
                return (this.appId == 3); // 3 = MyWork
            },
            title() {
                return (this.isAdmin ? 'VIRTUS ADMINISTRATION' : 'HSE RECORD');
            },
            userApps() {
                return $virtus.tools.stores.convertDictionaryToArray(this.getUserApps);
            },
            userSites() {
                return $virtus.tools.stores.convertDictionaryToArray(this.getUserSites);
            },
            showAppsOptions() {
                return (this.userApps && this.userApps.length > 1);
            },
            showSitesOptions() {
                return (this.userSites && this.userSites.length > 1);
            },
        },
        methods: {
            subIsActive(input) {
                const paths = Array.isArray(input) ? input : [input];
                var isActive = paths.some(path => {
                    return this.$route.path.indexOf(path) === 0; // current path starts with this path string
                });
                return isActive;
            },
            async loadMenuItems() {
                var menuItems = [];
                var items = this.$router.options.routes;
                if (this.isAdmin) {
                    menuItems = items.filter(function (item) {
                        return item.title && item.path.startsWith('/admin');
                    });
                } else if (this.isWorker) {
                    menuItems = items.filter(function (item) {
                        return item.title && item.path.startsWith('/mywork');
                    });
                } else {
                    menuItems = items.filter(function (item) {
                        return item.title && item.path.startsWith('/record');
                    });
                }
                var userMenuItems = await this.getUserMenuItems(menuItems);
                return userMenuItems;
            },
            async loadLeftMenuItems() {
                var items = await this.menuItems.filter(function (item) {
                    return !item.alignRight;
                });
                return items;
            },
            async loadRightMenuItems() {
                var items = await this.menuItems.filter(function (item) {
                    return item.alignRight;
                });
                return items;
            },
            async getUserMenuItems(menuItems) {
                var items = [];
                for (var i in menuItems) {
                    var item = menuItems[i];
                    var children = [];
                    if (await $virtus.security.userCanAccessRoute(item)) {
                        if (item.children) {
                            children = await this.getUserMenuItems(item.children);
                        }
                        if (!item.children || children.length > 0) {
                            items.push({
                                path: item.path,
                                title: item.title,
                                children: (children.length > 0 ? children : null),
                                alignRight: item.alignRight,
                                exact: item.exact
                            });
                        }
                    }
                }
                return items;
            },
            changeSite(siteId) {
                this.$store.dispatch('security/setSiteId', siteId);
                //this.$router.go(this.$router.currentRoute);
            },
            changeApp(appId) {
                this.$store.dispatch('security/goToApp', appId);
            },
        }
    }
</script>

<style>
    .user-menu a {
        display: flex;
        align-items: center;
    }

    .main-nav .dropdown-menu {
        padding: 2px 0;
    }

    li:not(.selected) .dropdown-item:hover {
        background-color: #D7E1EE;
        cursor: hand;
    }

    .user-menu .menu-group-title {
        background-color: #343a40;
        font-size: x-small;
        color: white;
    }

    .user-menu .selected .dropdown-item,
    .user-menu .selected .dropdown-item:hover {
        background-color: lightsteelblue;
        cursor: default;
    }

    .user-menu .selected a {
        font-weight: bold;
    }

    .main-nav .dropdown-divider {
        margin: 2px 0;
    }

    .user-menu-item {
        line-height: 0.8rem;
        font-size: small;
        display: flex;
        align-items: center;
        text-align: center;
    }

        .user-menu-item small {
            font-size: x-small;
        }

        .user-menu-item .fa-icon {
            margin: 0 0.5rem;
        }

    .navbar-nav {
        margin-left: 4rem;
        font-size: large;
    }

    .main-nav .navbar {
        padding: 0.2rem 0.5rem;
    }

    .logo {
        max-height: 1.5rem;
        margin-left: 1rem;
    }

    .main-nav .navbar-toggler {
        padding: .1rem .25rem;
    }


    @media (max-width: 1440px) {
        #nav_collapse .navbar-nav:first-child > span {
            margin-right: 0.5rem;
        }
    }

    @media (max-width: 1050px) {
        #nav_collapse .navbar-nav:first-child > span {
            margin-right: 0;
        }

        .navbar-brand {
            margin: 0;
            font-size: small;
        }

        .navbar-nav {
            font-size: small;
        }
    }


    @media (max-width: 767px) {
        #nav_collapse .navbar-nav:first-child > span {
            margin-right: 0;
        }

        .navbar-brand {
            margin: 0;
            font-size: 1.25rem;
        }

        .navbar-nav {
            font-size: large;
        }
    }
</style>
