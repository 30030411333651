import models from '../record/shared/models.js';
//import virtus from '../shared';
var tools = $virtus.tools;
var api = tools.api;
var stores = tools.stores;

export default {
    namespaced: true,
    state: {
    },
    getters: {

    },
    mutations: {
    },
    actions: {

        //RAMS
        async createUpdateRam(store, data) {
            var options = {
                isFormData: true,
                dateFields: ['issueDate', 'lastValidIssueDate', 'reviewDate'],
                arrayFields: ['sopQuestions'],
            };
            let result = await api.post('/api/rams', data, options);
            if (result) {
                models.refreshComputedRamFields(result);
            }
            return result;
        },

        async removeRam(store, data) {
            let result = await api.delete('/api/rams/' + data.id);
            return result;
        },

        async updateRamAssignments(store, data) {
            let result = await api.post('/api/rams/assignments/', data);
            return result;
        },

        async updateRamSignatures(store, data) {
            var options = {
                dateFields: ['signatureDate'],
            };
            let result = await api.post('/api/rams/signatures/', data, options);
            return result;
        },

        async getRamHistory(store, data) {
            let result = await api.get('/api/rams/' + data.ramId + '/history');
            return result;
        },

        async getRamsFilename(store, data) {
            let result = await api.get('/api/rams/' + data.ramId + '/filename');
            return result;
        },

        async getRamsFile({ rootState } , data) {
            var url = '/api/rams/' + data.ramId + '/download-file?download=' + data.download + '&appId=' + rootState.security.appId;
            let result = await (data.onlyBytes ? api.getFile(url) :(data.download ? api.download(url) : api.open(url, { iframe: data.iframe })));
            return result;
        },

        async checkExistingRamsFiles(store, data) {
            var options = {
                params: { ramsIds: data }
            }
            let result = await api.get('/api/rams/check-files', options);
            return result || [];
        },

        async saveRamsFile(store, data) {
            var options = {
                isFormData: true
            };
            let result = await api.post('/api/rams/' + data.id + '/file', data, options);
            return result;
        },



        async getEmployeesRamsAssignments() {
            let result = await api.get('/api/rams/employees/assignments');
            return result;
        },

        async updateEmployeesRamsAssignments(store, data) {
            let result = await api.post('/api/rams/employees/assignments', data);
            return result;
        },


        async getFullRamSignatures(store, data) {
            let result = await api.get('/api/rams/signatures/full?showLeavers=' + (data && data.showLeavers));
            return result;
        },

        async getFullRams({ dispatch }) {
            var items = await Promise.all([
                    dispatch('getRamsDictionary'),
                    dispatch('getRamAssignments'),
                    dispatch('getRamSignatures')
                ]).then((values) => {
                    var rams = values[0];
                    var assignments = values[1];
                    var signatures = values[2];

                    //Initialize assignments and signatures
                    for (var i in rams) {
                        var ram3 = rams[i];
                        ram3.assignments = {};
                        ram3.signatures = {};
                    }

                    //Update assignments
                    for (var j = 0; j < assignments.length; j++) {
                        var newAssignment = assignments[j];
                        var ram = rams[newAssignment.ramId];
                        if (ram) {
                            ram.assignments[newAssignment.jobPositionId] = newAssignment;
                        }
                    }

                    //Update signatures
                    for (var k = 0; k < signatures.length; k++) {
                        var newSignature = signatures[k];
                        var ram2 = rams[newSignature.ramId];
                        if (ram2) {
                            ram2.signatures[newSignature.employeeId] = newSignature;
                        }
                    }

                    return rams;
                });
            return items;
        },


        async getRams() {
            var options = {
                dateFields: ['issueDate', 'lastValidIssueDate', 'reviewDate']
            };
            var items = await api.get('/api/rams', options);
            items = items.map(ram => models.refreshComputedRamFields(ram));
            return items;
        },

        async getRamsDictionary({ dispatch }) {
            var rams = await dispatch('getRams');
            var items = stores.convertArrayToDictionary(rams);
            return items;
        },

        async getSortedFullRams({ dispatch }) {
            var dictionary = await dispatch('getFullRams');
            var items = Object.values(dictionary);
            items.sort(function (a, b) { return a.reference.toLocaleLowerCase().localeCompare(b.reference.toLocaleLowerCase()); });
            return items;
        },
        async getOptionsFromRams({ dispatch }) {
            var items = await dispatch('getRams');
            var options = [];
            for (var i = 0; i < items.length; i++) {
                var item = items[i];
                options.push({ value: item.id, text: item.reference + '. ' + item.description });
            }
            options.sort(function (a, b) { return a.text.toLocaleLowerCase().localeCompare(b.text.toLocaleLowerCase()); });
            return options;
        },

        async getRamAssignments() {
            var items = await api.get('/api/rams/assignments');
            items = items.map(assigment => models.refreshComputedAssignmentFields(assigment));
            return items;
        },

        async getRamSignatures() {
            var options = {
                dateFields: ['signatureDate']
            };
            var items = await api.get('/api/rams/signatures', options);
            items = items.map(signature => models.refreshComputedRamSignatureFields(signature));
            return items;
        },

        async getRamSopQuestions(store, data) {
            var items = await api.get('/api/rams/' + data.id + '/sop-questions');
            return items;
        },

        async getAdminSopTests(store, data) {
            var items = await api.get('/api/rams/sop-tests?onlyPendingToValidate=' + data.onlyPendingToValidate + '&filter=' + encodeURIComponent(data.filter ? data.filter : ''));
            return items;
        },

        async getAdminSopTest(store, data) {
            var items = await api.get('/api/rams/sop-tests/' + data.sopTestId);
            return items;
        },

        async saveSopTestComments(store, data) {
            var result = await api.post('/api/rams/sop-tests/' + data.sopTestId, data.comments);
            return result;
        },

        async validateSopTest(store, data) {
            var result = await api.post('/api/rams/sop-tests/' + data.sopTestId + '/validate', data);
            return result;
        },

        async rejectSopTest(store, data) {
            var result = await api.post('/api/rams/sop-tests/' + data.sopTestId + '/reject', data);
            return result;
        },

        async getSopTestFile(store, data) {
            var url = '/api/rams/sop-tests/' + data.sopTestId + '/file?download=' + data.download + '&appId=2';
            let result = await (data.download ? api.download(url) : api.open(url));
            return result;
        },

        async showRamVideo(/*store, data*/) {
            //var config = encodeURIComponent(btoa({
            //    ramId: data.ramId,
            //    returnPath: data.returnPath
            //}));

            //'/videos/rams/ram-' + ramId + '.mp4';
            //var url = '/viewer/video/' + config;
            //debugger;
        },
        async getRamViewTime(store, data) {
            var items = await api.get('/api/rams/' + data.ramId + '/viewtime');
            return items;
        },
        async updateRamViewTime(store, data) {
            var result = await api.post('/api/rams/' + data.RamId + '/viewtime', data, { noLoader: true });
            return result;
        },
        async viewRam(store, data) {
            var router = $virtus.router;
            var key = encodeURIComponent(btoa(JSON.stringify({
                ramId: data.ramId,
                hasVideo: data.hasVideo,
                showTimer: data.showTimer,
                returnPath: router.currentRoute.fullPath
            })));
            router.push('/viewer/' + key);
        }

    }
};
