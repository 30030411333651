<template>
    <div :class="{ 'control': true, 'control-select': true, 'has-first-option': hasFirstOption }">
        <div v-if="position == 'right'">
            <b-container>
                <b-row>
                    <b-col class="mr-auto" v-if="label">
                        <div class="control-label">{{label}}</div>
                    </b-col>
                    <b-col cols="auto">
                        <div v-if="readOnly" class="read-only">{{value ? value : '&nbsp;'}}</div>
                        <div v-if="!readOnly">
                            <b-form-select v-model="textValue" :options="options" @input="onInput" :state="errors ? false : null"
                                           :class="{ 'first-option-selected': firstOptionSelected }">
                                <template slot="first" v-if="hasFirstOption">
                                    <option :value="null">{{placeholder}}</option>
                                </template>
                            </b-form-select>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <div class="control-label" v-if="label">{{label}}</div>
            <div v-if="readOnly" class="read-only">{{value ? value : '&nbsp;'}}</div>
            <div v-if="!readOnly">
                <b-form-select v-model="textValue" :options="options" @input="onInput" :state="errors ? false : null"
                               :class="{ 'first-option-selected': firstOptionSelected }">
                    <template slot="first" v-if="hasFirstOption">
                        <option :value="null">{{placeholder}}</option>
                    </template>
                </b-form-select>
            </div>
        </div>
        <div v-if="errors">
            <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'control-select',
        props: ['label', 'value', 'placeholder', 'hasErrors', 'readOnly', 'position', 'options', 'errors'],
        data() {
            return {
                textValue: null,
            };
        },
        created() {
            this.setValue();
        },
        computed: {
            hasFirstOption() {
                return this.placeholder;
            },
            firstOptionSelected() {
                return (this.hasFirstOption && this.textValue == null && this.textValue != 0);
            }
        },
        watch: {
            value() {
                this.setValue();
            },
        },
        methods: {
            onInput(newValue) {
                this.$emit('input', newValue);
                this.$emit('changed', newValue);
            },
            setValue() {
                this.textValue = this.value;
            },
        }
    };
</script>

<style>

    .control-select select {
        cursor: pointer;
    }

    .control-select select.first-option-selected {
        color: #c0c4cc;
    }

    .control-select select option {
        color: #495057;
    }

    .control-select.has-first-option select option:first-child {
        color: #c0c4cc;
    }

    .control-select div.read-only {
        margin: 0.2rem 0;
        border-radius: 0.25rem;
        border: 1px solid grey;
        padding: 0.3rem 0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        flex: 1 1 auto;
        font-size: 0.9rem;
    }
</style>
