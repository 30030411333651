<template>
    <div class="training-costs">
        <h1 class="header-title">Training Costs</h1>
        <div class="table header">
            <div class="table-row">
                <div class="table-item">
                    <div>Manager</div>
                    <div class="username">{{userName}}</div>
                </div>
                <div class="table-item">
                    <div>Year</div>
                    <control-select :options="years" v-model="year" @changed="loadTrainingCosts"></control-select>
                </div>
            </div>
        </div>

        <div class="table">
            <div class="table-row" v-for="(n, row) in 3" :key="row">
                <div class="table-item" v-for="(m, col) in 4" :key="col">
                    <div class="control-label">{{months[4 * row + col].text}}</div>
                    <control-text v-model="monthlyCosts[4 * row + col + 1]" placeholder="enter cost" type="number"></control-text>
                </div>
            </div>
        </div>

        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="save">Save</button>
        </div>

    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                year: null,
                monthlyCosts: [],
            };
        },
        async created() {
            this.year = this.today.year;
            await this.loadTrainingCosts();
        },
        computed: {
            ...mapState('security', ['userName']),
            today() {
                return $virtus.tools.dates.getToday();
            },
            months() {
                return $virtus.tools.dates.getMonthOptions();
            },
            years() {
                return $virtus.tools.dates.getYearOptions();
            },
        },
        methods: {
            async loadTrainingCosts() {
                var existing = await this.$store.dispatch('trainingCosts/getTrainingCosts', { year: this.year });
                this.monthlyCosts = [];
                for (var i in this.months) {
                    var month = this.months[i];
                    var item = (existing ? existing.find(x => x.month == month.value) : null);
                    this.monthlyCosts[month.value] = (item ? item.cost : null);
                }
            },
            async save() {
                //var data = this.monthlyCosts.map((cost, index) => { return { month: index, year: this.year, cost }; });
                var data = this.months.map((m) => { return { month: m.value, year: this.year, cost: this.monthlyCosts[m.value] }; });
                var result = await this.$store.dispatch('trainingCosts/createUpdateTrainingCosts', data);
                if (result) {
                    $virtus.tools.messages.showSaveOk('training costs', 'saved');
                }
            },

        }
    }
</script>
<style>

    .training-costs .username {
        padding: 0.8rem 0 0 1rem;
        font-size: 2rem;
        line-height: 2rem;
        width: 100%;
    }

    .training-costs .header {
        background-color: #8B9870;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }


    .training-costs .table {
        display: table;
        width: 100%;
    }

    .training-costs .table-row {
        display: table-row;
    }

    .training-costs .table-item {
        padding: 0.2rem;
        display: table-cell;
    }

    @media (max-width: 500px) {
        .training-costs .table {
            display: block;
        }

        .training-costs .table-row {
            display: block;
        }

        .training-costs .table-item {
            display: block;
        }
    }

</style>
