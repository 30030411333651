<template>
    <div class="sop-validations">
        <h1 class="header-title">SOP Tests Validation</h1>

        <div class="header-items-bar">
            <control-text v-model="filter" placeholder="filter text"></control-text>
            <control-checkbox v-model="onlyPendingToValidate">Show only SOP pending to validate</control-checkbox>
            <button type="button" class="btn btn-primary btn-sm ml-auto" @click="getSops">Show SOPs</button>
        </div>

        <div class="clear"></div>
        <br />

        <div class="rams-select">
            <div class="list">
                <b-container fluid>
                    <b-row class="header">
                        <b-col cols="auto" class="title">SOP Tests</b-col>
                    </b-row>
                </b-container>
                <b-container class="items" fluid v-if="hasSops">
                    <b-row align-v="center" :key="index" v-for="(sop, index) in sops" class="item">
                        <b-col cols="auto" :class="getSopClass(sop) + ' sop-state'"><span v-html="sop.state.replace(' ', '<br/>')"></span></b-col>
                        <b-col class="sop-body">
                            <div class="sop-header">
                                <div>{{sop.reference}}. Version {{sop.version}}</div>
                                <div>{{sop.employeeName}}</div>
                            </div>
                            <div class="sop-description">{{sop.description}}</div>
                        </b-col>
                        <b-col cols="auto" class="ml-auto">
                            <video-button v-if="sop.hasVideo && sop.hasFile" :video-path="getVideoPath(sop)"></video-button>
                            <button v-if="!sop.hasVideo && sop.hasFile" type="button" class="btn btn-secondary btn-sm" @click="downloadRam(sop, false)">Open RAM</button>
                            <div v-if="!sop.hasFile" class="no-file">No file</div>
                        </b-col>
                        <b-col v-if="sop.id" cols="auto"><button type="button" class="btn btn-secondary btn-sm" @click="downloadSopTestFile(sop, false)">Open SOP</button></b-col>
                        <b-col cols="auto"><button type="button" class="btn btn-primary btn-sm" @click="editSop(sop)">{{canBeEdited(sop) ? 'Edit' : 'Show'}}</button></b-col>
                    </b-row>
                </b-container>
                <div class="legend" v-if="hasSops">
                    <p><i>Click 'Review' button to review, add comments and validate/reject the SOP test.</i></p>
                </div>
                <div class="no-items" v-if="!hasSops">There are no SOP to show.</div>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                filter: null,
                onlyPendingToValidate: true,
                sops: [],
            };
        },
        async mounted() {
            //if (this.keepRams && this.lastSearch) {
            //    this.filter = this.lastSearch.filter;
            //    this.onlyRamsNotSigned = this.lastSearch.onlyNotSigned;
            //    this.$store.commit('mywork/setKeepRams', false);
            //} else {
            //    await this.showRamsToSelect();
            //}
            await this.getSops();
        },
        computed: {
            ...mapState('mywork', ['rams', 'keepRams', 'lastSearch']),
            ...mapState('security', ['userName']),
            hasSops() {
                return (this.sops && this.sops.length);
            },
        },
        methods: {

            async getSops() {
                var result = await this.$store.dispatch('rams/getAdminSopTests', {
                    onlyPendingToValidate: this.onlyPendingToValidate,
                    filter: this.filter,
                });
                this.sops = (result || []);
            },
            showSop() {

            },
            getSopClass(sop) {
                switch (sop.state) {
                    case 'ASSIGNED':
                        return 'assigned-sop';
                    case 'IN PROGRESS':
                        return 'in-progress-sop';
                    case 'PENDING VALIDATION':
                        return 'pending-sop';
                    case 'REJECTED':
                        return 'rejected-sop';
                    case 'COMPLETED':
                        return 'completed-sop';
                    case 'EXPIRED':
                        return 'expired-sop';
                    case 'SIGNED':
                        return 'signed-sop';
                    case 'DELETED':
                        return 'deleted-sop';
                }
                return '';
            },
            editSop(sop) {
                this.$router.push('/record/signatures/sop/' + sop.id);
            },
            canBeEdited(sop) {
                return (sop.state == 'PENDING VALIDATION');
            },
            downloadSopTestFile(sop, download) {
                this.$store.dispatch('rams/getSopTestFile', { sopTestId: sop.id, download });
            },
            downloadRam(sop, download) {
                this.$store.dispatch('rams/getRamsFile', { ramId: sop.ramId, download });
            },
            getVideoPath(sop) {
                return '/videos/rams/ram-' + sop.ramId + '.mp4';
            },
        }
    }
</script>
<style>

    .sop-validations .header-items-bar {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 5px 5px !important;
        background-color: rgba(255,255,255,0.4);
        align-items: center;
        flex-wrap: wrap;
    }

    .sop-validations .header-items-bar .control {
        margin: 0 0.5rem 0 0;
    }

    .sop-validations .rams-select .header {
        background-color: #8b9870;
        color: white;
        align-items: center;
    }    

/*    .sop-validations .rams-select .items .button {
        background-color: #E9F1D9;
        border-radius: 10px;
        padding: 5px 10px;
    }*/

    .sop-validations .btn {
        padding: 0 10px;
        border-radius: 10px;
    }

/*    .sop-validations .rams-select .items .button:hover {
        background-color: #DBE8C0;
        font-weight: normal;
    }

    .sop-validations .rams-select .items .main-button {
        background-color: #007bff;
        border-radius: 10px;
        padding: 5px 10px;
        cursor: pointer;
        color: white;
    }

    .sop-validations .rams-select .items .main-button:hover {
        background-color: #0069d9;
        font-weight: normal;
    }*/

    .sop-validations .no-items {
        margin: 0.5rem;
        font-style: italic;
    }

    .sop-validations .rams-select .items .item {
        padding: 1px 5px 0 0;
        align-items: stretch !important;
    }

    .sop-validations .item:nth-child(even) {
        background-color: rgba(255,255,255,0.5);
    }

    .sop-validations .item:nth-child(odd) {
        background-color: rgba(255,255,255,0.2);
    }

    .sop-validations .item .col,
    .sop-validations .item .col-auto {
        padding: 5px;
        display: flex;
        align-items: center;
    }

        .sop-validations .item .col.sop-body {
/*            padding: 0;
*/            display: flex;
            align-items: stretch;
            flex-direction: column;
            justify-content: flex-start;
        }

    .sop-validations .sop-header {
        font-weight: bold;
/*        background-color: rgba(255,255,255,.6);
*/        display: flex;
        justify-content: space-between;
        width: 100%;
/*        padding: 2px;
*/    }

    .sop-validations .sop-description {
        width: 100%;
/*        padding: 2px;
*/    }

    .sop-validations .sop-state {
        text-align: center;
        width: 100px;
        justify-content: center;
    }

    .sop-validations .assigned-sop {
        background-color: lightskyblue;
    }

    .sop-validations .in-progress-sop {
        background-color: darkseagreen;
    }

    .sop-validations .pending-sop {
        background-color: palegoldenrod;
    }

    .sop-validations .rejected-sop {
        background-color: lightcoral;
    }

    .sop-validations .completed-sop {
        background-color: lightgray;
    }

    .sop-validations .expired-sop,
    .sop-validations .deleted-sop {
        background-color: orangered;
    }

    .sop-validations .signed-sop {
        background-color: antiquewhite;
    }

</style>
