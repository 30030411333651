<template>
    <div class="workers-sop">
        <h1 class="header-title">My SOP Tests</h1>

        <div class="header-items-bar">
            <control-text v-model="filter" placeholder="filter text"></control-text>
            <control-checkbox v-model="onlySopNotCompleted">Show only SOP not completed</control-checkbox>
            <button type="button" class="btn btn-primary btn-sm ml-auto" @click="getEmployeeSops">Show SOPs</button>
        </div>

        <div class="clear"></div>
        <br />

        <div class="rams-select">
            <div class="list">
                <b-container fluid>
                    <b-row class="header">
                        <b-col cols="auto" class="title">SOP Tests</b-col>
                    </b-row>
                </b-container>
                <b-container class="items" fluid v-if="hasSops">
                    <b-row align-v="center" :key="index" v-for="(sop, index) in sops" class="item">
                        <b-col cols="auto" :class="getSopClass(sop) + ' sop-state'"><span v-html="sop.state.replace(' ', '<br/>')"></span></b-col>
                        <b-col>
                            {{sop.reference}}. Version {{sop.version}}<br />
                            {{sop.description}}
                        </b-col>
                        <b-col v-if="sop.viewSeconds" cols="auto" class="fixed-width-100px">
                            [{{$virtus.tools.dates.formattedTime(sop.viewSeconds)}}]
                        </b-col>
                        <b-col v-if="sop.hasFile || sop.hasVideo" cols="auto" class="fixed-width-100px">
                            <ram-viewer-button :ram-id="sop.ramId" :has-video="sop.hasVideo" :show-timer="true" text="Open" primary></ram-viewer-button>
                        </b-col>
                        <b-col v-else cols="auto" class="fixed-width-100px">No file</b-col>
                        <!--<b-col cols="auto" class="ml-auto">
        <video-button v-if="sop.hasVideo && sop.hasFile" :video-path="getVideoPath(sop)"></video-button>
        <button v-if="!sop.hasVideo && sop.hasFile" type="button" class="btn btn-secondary btn-sm" @click="downloadRam(sop, false)">Open RAM</button>
        <div v-if="!sop.hasFile" class="no-file">No file</div>
    </b-col>-->
                        <b-col v-if="showOpenSop(sop)" cols="auto"><button type="button" class="btn btn-secondary btn-sm" @click="downloadSopTestFile(sop, false)">Open SOP</button></b-col>
                        <b-col v-if="showEditSop(sop)" cols="auto"><button type="button" class="btn btn-primary btn-sm" @click="editSop(sop)">Edit</button></b-col>
                        <b-col v-if="showShowSop(sop)" cols="auto"><button type="button" class="btn btn-primary btn-sm" @click="showSop(sop)">Show</button></b-col>
                    </b-row>
                </b-container>
                <div class="legend" v-if="hasSops">
                    <p><i>Click 'Edit' button to fill SOP questions and sent the test to review.</i></p>
                </div>
                <div class="no-items" v-if="!hasSops">There are no SOP to show.</div>
            </div>
        </div>

    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                filter: null,
                onlySopNotCompleted: true,
                sops: [],
            };
        },
        async mounted() {
            await this.getEmployeeSops();
        },
        computed: {
            ...mapState('mywork', ['rams', 'keepRams', 'lastSearch']),
            ...mapState('security', ['userName']),
            hasSops() {
                return (this.sops && this.sops.length);
            },
        },
        methods: {
            async getEmployeeSops() {
                var result = await this.$store.dispatch('mywork/getEmployeeSops', {
                    onlySopNotCompleted: this.onlySopNotCompleted,
                    filter: this.filter,
                });
                this.sops = (result || []);
            },
            getSopClass(sop) {
                switch (sop.state) {
                    case 'ASSIGNED':
                        return 'assigned-sop';
                    case 'IN PROGRESS':
                        return 'in-progress-sop';
                    case 'PENDING VALIDATION':
                        return 'pending-sop';
                    case 'REJECTED':
                        return 'rejected-sop';
                    case 'COMPLETED':
                        return 'completed-sop';
                    case 'EXPIRED':
                        return 'expired-sop';
                    case 'SIGNED':
                        return 'signed-sop';
                    case 'DELETED':
                        return 'deleted-sop';
                }
                return '';
            },
            editSop(sop) {
                this.$router.push('/mywork/sop/' + sop.ramId + '/' + (sop.id && sop.state == 'IN PROGRESS' ? sop.id : '0'));
            },
            showSop(sop) {
                this.$router.push('/mywork/sop/' + sop.ramId + '/' + sop.id);
            },
            showOpenSop(sop) {
                return (sop.state != 'ASSIGNED' && sop.state != 'SIGNED');
            },
            showEditSop(sop) {
                return (sop.state == 'ASSIGNED' || sop.state == 'IN PROGRESS' || sop.state == 'REJECTED' || sop.state == 'EXPIRED' || sop.state == 'DELETED');
            },
            showShowSop(sop) {
                return (sop.state == 'PENDING VALIDATION' || sop.state == 'COMPLETED');
            },
            downloadSopTestFile(sop, download) {
                this.$store.dispatch('mywork/getSopTestFile', { sopTestId: sop.id, download });
            },
            downloadRam(sop, download) {
                this.$store.dispatch('rams/getRamsFile', { ramId: sop.ramId, download });
            },
            getVideoPath(sop) {
                return '/videos/rams/ram-' + sop.ramId + '.mp4';
            },
        }
    }
</script>
<style>

    .workers-sop .header-items-bar {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px 5px 5px !important;
        background-color: rgba(255,255,255,0.4);
        align-items: center;
        flex-wrap: wrap;
    }

    .workers-sop .header-items-bar .control {
        margin: 0 0.5rem 0 0;
    }

    .workers-sop .rams-select .header {
        background-color: #8b9870;
        color: white;
        align-items: center;
    }

    .workers-sop .no-items {
        margin: 0.5rem;
        font-style: italic;
    }

    .workers-sop .btn {
        padding: 0 10px;
        border-radius: 10px;
    }

    .workers-sop .col-auto {
        padding: 5px;
    }

    .workers-sop .rams-select .items .item {
        padding: 1px 5px 0 0;
        align-items: stretch !important;
    }

    .workers-sop .item:nth-child(even) {
        background-color: rgba(255,255,255,0.5);
    }

    .workers-sop .item:nth-child(odd) {
        background-color: rgba(255,255,255,0.2);
    }

    .workers-sop .rams-select .items .item div {
        padding: 5px;
        display: flex;
        align-items: center;
    }
    .workers-sop .sop-state {
        text-align: center;
        width: 100px;
        justify-content: center;
    }

    .workers-sop .assigned-sop {
        background-color: lightskyblue;
    }

        .workers-sop .in-progress-sop {
            background-color: darkseagreen;
        }

    .workers-sop .pending-sop {
        background-color: palegoldenrod;
    }

        .workers-sop .rejected-sop {
            background-color: lightcoral;
        }

        .workers-sop .completed-sop {
            background-color: lightgray;
        }

    .workers-sop .expired-sop,
    .workers-sop .deleted-sop {
        background-color: orangered;
    }

    .workers-sop .signed-sop {
        background-color: antiquewhite;
    }

</style>
