<template>
    <div v-if="sop" class="sop-test-review">

        <div class="ram-header">
            <div class="sop-header">
                <h1 class="header-title">{{sop.sopTitle}}</h1>
                <div class="ram"><b>{{sop.reference}}.</b> Version {{sop.version}}. {{sop.description}}</div>
            </div>
            <div :class="getSopClass() + ' state'">{{sop.state}}</div>
        </div>
        <div class="sop-description">{{sop.sopDescription}}</div>
        <div class="sop-questions">
            <div class="sop-question" :key="index" v-for="(question, index) in sop.sopQuestions">
                <div class="question control-label">
                    {{question.order}}. {{question.question}}
                </div>
                <div class="answer">
                    <div>{{question.answer}}</div>
                    <button v-if="isSopEditable || question.isValid === false" type="button" :class="'ml-auto btn btn-sm ' + (question.isValid === false ? 'btn-danger' : 'btn-outline-danger') + (isSopEditable ? '' : ' no-editable')" @click="noValid(question)">No valid</button>
                    <button v-if="isSopEditable || question.isValid === true" type="button" :class="'btn btn-sm ' + (question.isValid === true ? 'btn-success' : 'btn-outline-success') + (isSopEditable ? '' : ' no-editable')" @click="valid(question)">Valid</button>
                </div>
                <div class="comment" v-if="isSopEditable || question.comment">
                    <div class="comment-label">comment:</div>
                    <control-text v-if="isSopEditable" v-model="question.comment"></control-text>
                    <div v-else> {{question.comment}}</div>
                </div>
            </div>
        </div>

        <br/>
        <div v-if="sop && sop.employeeSignatureUser && sop.employeeSignatureDate" class="signature">Signed on {{tools.dates.formatDateTime(sop.employeeSignatureDate)}} by {{sop.employeeSignatureUser}}.</div>
        <div v-if="!isSopEditable && sop && sop.validationSignatureUser && sop.validationSignatureDate" class="signature">{{isRejected ? 'Rejected' : 'Validated'}} on {{tools.dates.formatDateTime(sop.validationSignatureDate)}} by {{sop.validationSignatureUser}}.</div>

        <div v-if="isReview" class="review-and-sign-box">
            <div class="control-label">Review &amp; Sign</div>
            <div class="review-message">
                Please, review your comments and sign the test.
                Once you click on buttons 'Validate' or 'Reject' you will not be able to modify your comments.<br />
                Click on 'Validate' button to confirm that the SOP test is VALID.<br />
                Click on 'Reject' button to confirm that the SOP test is NOT VALID.
            </div>
            <br />

            <div class="signature-pad">
                <div cols="auto" class="signature">Signed on {{signedDateFormat}} by {{userName}}.</div>
                <div @mousemove="onMouseMoveSign">
                    <VueSignaturePad class="canvas" width="40rem" height="8rem" ref="signaturePad" />
                </div>
                <div class="header">
                    <span class="button" @click="clearSignature()">Clear</span>
                    <span class="button" @click="undoSignature()">Undo</span>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <div class="buttons">
            <button v-if="isSopEditable" type="button" class="btn btn-success btn-sm" @click="showReview">Validate or Reject</button>
            <button v-if="isSopEditable" type="button" class="btn btn-secondary btn-sm" @click="goToSopsPage">Cancel</button>
            <button v-if="isSopEditable" type="button" class="btn btn-primary btn-sm" @click="save">Save Test</button>
            <button v-if="!isSopEditable && !isReview" type="button" class="btn btn-primary btn-sm" @click="goToSopsPage">Close</button>
            <button v-if="isReview" type="button" class="btn btn-secondary btn-sm" @click="cancelReview">Cancel</button>
            <button v-if="isReview" type="button" class="btn btn-danger btn-sm" @click="confirmNotValid">Reject</button>
            <button v-if="isReview" type="button" class="btn btn-success btn-sm" @click="confirmValid">Validate</button>
        </div>

    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                sop: null,
                isSigned: false,
                isReview: false,
            };
        },
        async mounted() {
            await this.getSopTest();
        },
        computed: {
            ...mapState('mywork', ['rams', 'keepRams', 'lastSearch']),
            ...mapState('security', ['userName']),
            isSopEditable() {
                return (!this.isReview && this.sop && (this.sop.state == 'PENDING VALIDATION'));
            },
            sopTestId() {
                return this.$route.params.sopTestId;
            },
            allAnswersCompleted() {
                if (this.sop && this.sop.sopQuestions) {
                    for (var i in this.sop.sopQuestions) {
                        var question = this.sop.sopQuestions[i];
                        if (!question.answer) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            },
            signedDate() {
                return $virtus.tools.dates.now();
            },
            signedDateFormat() {
                return $virtus.tools.dates.formatDateTimeLong(this.signedDate);
            },
            isRejected() {
                return (this.sop && this.sop.state == 'REJECTED');
            },
        },
        methods: {
            goToSopsPage() {
                this.$router.push('/record/signatures/sop');
            },
            async getSopTest() {
                var result = await this.$store.dispatch('rams/getAdminSopTest', { sopTestId: this.sopTestId });
                if (result && result.sopQuestions) {
                    var questions = result.sopQuestions;
                    var answers = result.sopAnswers;
                    for (var i in questions) {
                        var question = questions[i];
                        var answer = (answers ? answers.find(a => a.sopQuestionId == question.id) : null);
                        if (answer) {
                            question.answer = answer.answer;
                            question.comment = answer.comment;
                            question.isValid = answer.isValid;
                        } else {
                            question.answer = null;
                            question.comment = null;
                            question.isValid = null;
                        }
                    }
                }
                this.sop = result;
            },
            getSopClass() {
                switch (this.sop.state) {
                    case 'ASSIGNED':
                        return 'assigned-sop';
                    case 'IN PROGRESS':
                        return 'in-progress-sop';
                    case 'PENDING VALIDATION':
                        return 'pending-sop';
                    case 'REJECTED':
                        return 'rejected-sop';
                    case 'COMPLETED':
                        return 'completed-sop';
                    case 'EXPIRED':
                        return 'expired-sop';
                    case 'SIGNED':
                        return 'signed-sop';
                }
                return '';
            },
            async save() {
                var comments = this.getComments();
                var result = await this.$store.dispatch('rams/saveSopTestComments', { sopTestId: this.sopTestId, comments });
                if (result) {
                    $virtus.tools.messages.showSaveOk('sop test', 'saved');
                    this.goToSopsPage();
                }
            },

            getComments() {
                var comments = this.sop.sopQuestions.map((q) => { return { sopQuestionId: q.id, comment: q.comment, isValid: q.isValid }; });
                return comments;
            },

            showReview() {
                //if (!this.allAnswersCompleted) {
                //    $virtus.tools.messages.error('Empty answers', "You can't send to validation until all answers are completed.");
                //} else {
                    this.isReview = true;
                //}
            },
            cancelReview() {
                this.isReview = false;
            },
            async confirmValid() {
                var comments = this.getComments();
                var signatureImage = this.$refs.signaturePad.saveSignature();
                var model = {
                    sopTestId: this.sopTestId,
                    answers: comments,
                    signatureDate: this.signedDate,
                    base64Image: signatureImage.data
                };
                var result = await this.$store.dispatch('rams/validateSopTest', model);
                if (result) {
                    $virtus.tools.messages.showSaveOk('sop test', 'saved');
                    this.goToSopsPage();
                }
            },
            async confirmNotValid() {
                var comments = this.getComments();
                var signatureImage = this.$refs.signaturePad.saveSignature();
                var model = {
                    sopTestId: this.sopTestId,
                    answers: comments,
                    signatureDate: this.signedDate,
                    base64Image: signatureImage.data
                };
                var result = await this.$store.dispatch('rams/rejectSopTest', model);
                if (result) {
                    $virtus.tools.messages.showSaveOk('sop test', 'saved');
                    this.goToSopsPage();
                }
            },
            onMouseMoveSign() {
                this.isSigned = !this.$refs.signaturePad.isEmpty();
            },
            undoSignature() {
                this.$refs.signaturePad.undoSignature();
                this.isSigned = !this.$refs.signaturePad.isEmpty();
            },
            clearSignature() {
                this.$refs.signaturePad.clearSignature();
                this.isSigned = false;
            },
            valid(question) {
                if (this.isSopEditable) {
                    question.isValid = (question.isValid === true ? null : true);
                }
            },
            noValid(question) {
                if (this.isSopEditable) {
                    question.isValid = (question.isValid === false ? null : false);
                }
            },
        }
    }
</script>
<style>

    .sop-test-review .ram-header {
        display: flex;
        justify-content: space-between;
        padding: 0 !important;
        background-color: rgba(255,255,255,0.4);
        align-items: center;
        flex-wrap: wrap;
    }

    .sop-test-review .ram-header div {
        padding: 5px 10px;
    }

    .sop-test-review .ram-header .sop-header {
        flex-grow: 1;
    }

    .sop-test-review .ram-header .header-title {
        font-weight: bolder;
    }

    .sop-test-review .ram-header .state {
        display: flex;
        align-self: stretch;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        font-weight: bolder;
    }

    .sop-test-review .sop-description {
        padding: 1rem 0;
    }

    .sop-test-review .sop-question {
        background-color: rgba(255,255,255,0.4);
        border-radius: 0.5rem;
        margin-top: 1rem;
    }

    .sop-test-review .sop-question .question {
        background-color: #8B9870;
        color: #F2F7E8;
        border-radius: 0.5rem 0.5rem 0 0;
        padding: 0.5rem;
    }

    .sop-test-review .sop-question .answer .btn {
        padding: 0.1rem 0.4rem;
        font-size: x-small;
        margin-left: 0.4rem;
    }

    .sop-test-review .sop-question .answer .btn.no-editable {
        cursor: default;
    }

    .sop-test-review .sop-question .answer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
    }

    .sop-test-review .sop-question .answer {
        padding: 0.5rem;
    }

    .sop-test-review .sop-question .comment {
        padding: 0.5rem;
        font-size: x-small;
        background-color: rgba(255,255,255,0.4);
        border-radius: 0 0 0.5rem 0.5rem;
        display: flex;
        align-items: center;
    }

    .sop-test-review .sop-question .comment .control-text,
    .sop-test-review .sop-question .comment .control-text div,
    .sop-test-review .sop-question .comment .control-text input {
        flex-grow: 1;
    }

    .sop-test-review .sop-question .comment .comment-label {
        font-weight: bold;
        padding-right: 0.3rem;
    }

    .sop-test-review .review-and-sign-box {
        background-color: rgba(255,255,255,0.4);
        border-radius: 0.5rem;
        padding: 0.25rem 1rem 1rem 1rem;
    }

    .sop-test-review .review-message {
        width: 40rem;
        text-align: justify;
    }

    .sop-test-review .signature {
        font-style: italic;
        font-size: x-small;
    }

    .sop-test-review .assigned-sop {
        background-color: lightskyblue;
    }

    .sop-test-review .in-progress-sop {
        background-color: darkseagreen;
    }

    .sop-test-review .pending-sop {
        background-color: palegoldenrod;
    }

    .sop-test-review .rejected-sop {
        background-color: lightcoral;
    }

    .sop-test-review .completed-sop {
        background-color: lightgray;
    }

    .sop-test-review .expired-sop {
        background-color: orangered;
    }

    .sop-test-review .signed-sop {
        background-color: antiquewhite;
    }

    .sop-test-review .review-and-sign-box {
        margin-top: 2rem;
    }

</style>
