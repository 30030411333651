<template>
    <admin-page :actions="actions" :fields="fields" :texts="texts"></admin-page>
</template>
<script>

    export default {
        name: 'admin-job-positions',
        props: [],
        data() {
            return {
                fields: [
                    {
                        key: 'name', label: 'Job Position', sortable: true, validations: { required: true, maxLength: 500 },
                        placeholder: 'Enter job position name'},
                    {
                        key: 'department', value: 'departmentId', label: 'Department', sortable: true, dataType: 'select', options: [], validations: { required: true },
                        placeholder: 'Select department' },
                    {
                        key: 'area', value: 'areaId', label: 'Area', sortable: true, dataType: 'select', options: [], validations: { required: true },
                        placeholder: 'Select area'}
                ],
                actions: {
                    get: 'admin/getJobPositions',
                    insert: 'admin/insertJobPosition',
                    update: 'admin/updateJobPosition'
                },
                texts: {
                    listTitle: 'Job Positions',
                    newTitle: 'Add New Job Position',
                    addButton: 'Add Job Position',
                    deleteButton: 'Remove',
                    itemName: 'job position',
                },
            }
        },
        async created() {
            await this.loadOptions();
        },
        mounted() {
        },
        computed: {


        },
        methods: {
            async loadOptions() {
                this.fields[1].options = await this.$store.dispatch('admin/getOptionsFromDepartments');
                this.fields[2].options = await this.$store.dispatch('admin/getOptionsFromAreas');
            },
        }
    }
</script>
<style scoped>
</style>
