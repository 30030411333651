<template>
    <div class="main-form">
        <h1 class="header-title">Contact</h1>
        <div>
            <p>
                You can request help using this contact form.
            </p>
            <label for="email">Your email</label>
            <b-form-input id="email" v-model="email" ref="email" placeholder="Enter your email" :state="showErrors && (missingEmail || !validEmail) ? false : null"></b-form-input>
            <div v-show="showErrors && missingEmail" class="error">The email field is mandatory.</div>
            <div v-show="showErrors && !validEmail" class="error">The email field is not valid.</div>

            <label for="subject">Subject</label>
            <b-form-input id="subject" v-model="subject" ref="subject" placeholder="Enter subject" :state="showErrors && missingSubject ? false : null"></b-form-input>
            <div v-show="showErrors && missingSubject" class="error">The subject field is mandatory.</div>

            <label for="message">Message</label>
            <b-form-textarea id="message" v-model="message" ref="message" placeholder="Enter message" :rows="5" :max-rows="20" :state="showErrors && missingMessage ? false : null"></b-form-textarea>
            <div v-show="showErrors && missingMessage" class="error">The message field is mandatory.</div>

        </div>
        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="send">Send</button>
        </div>
        <div style="margin-top:2rem;">
            <b-alert :show="sendResult===true" variant="success">
                The message has been sent successfully.
            </b-alert>
            <b-alert :show="sendResult===false" variant="danger">
                There was an error while sending the message.
            </b-alert>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                email: '',
                subject: '',
                message: '',
                showErrors: false,
                sendResult: null,
            }
        },
        created() {
        },
        computed: {
            missingEmail() {
                return (this.email === '');
            },
            missingSubject() {
                return (this.subject === '');
            },
            missingMessage() {
                return (this.message === '');
            },
            validEmail() {
                return this.missingEmail || this.validateEmail(this.email);
            },
            isValidForm() {
                return (!this.missingName && !this.missingSubject && !this.missingMessage && this.validEmail);
            },
        },
        methods: {
            async send() {
                this.showErrors = true;
                if (this.isValidForm) {
                    this.sendResult = await this.$store.dispatch('sendContactForm', { 'email': this.email, 'subject': this.subject, 'message': this.message });
                }
            },
            validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            }
        }
    }
</script>

<style>
</style>
