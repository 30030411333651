<template>
    <div class="main-form employee-signed-sheets">
        <h1 class="header-title">Employee Signed Sheets</h1>
        <div>

            <control-select v-model="employeeId" label="Employee" :options="employees" size="sm" 
                            placeholder="Enter employee" :errors="errors" />

            <!--<label for="employee">Employee</label>
            <b-form-select id="employee" v-model="employeeId" :options="employees" placeholder="Enter employee">
                <template slot="first">
                    <option :value="null">Enter employee</option>
                </template>
            </b-form-select>-->

        </div>
        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="getReports">Get reports</button>
        </div>
        <div class="search-result" v-if="showReports">
            <div class="title">
                <template v-if="reportItems && reportItems.length">Signature RAMS reports for {{employeeName}}</template>
                <template v-else>There are not Signature RAMS reports for {{employeeName}}</template>
            </div>
            <div v-if="reportItems"></div>
            <b-container class="items" fluid v-if="reportItems">
                <b-row align-v="center" :key="index" v-for="(report, index) in reportItems" :class="index % 2 ? 'even-row' : 'odd-row'">
                    <b-col>
                        {{report.employeeName}} - {{report.signatureDateText}}
                    </b-col>
                    <b-col cols="auto" class="ml-auto"><button type="button" class="btn btn-secondary btn-sm" @click="download(report, false)">Open</button></b-col>
                    <b-col cols="auto"><button type="button" class="btn btn-primary btn-sm" @click="download(report, true)">Download</button></b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                employeeId: null,
                employeeName: '',
                reportItems: [],
                showErrors: false,
                errors: null,
                showReports: false,
                employees: [],
            }
        },
        async created() {
            this.employees = await this.$store.dispatch('admin/getOptionsFromEmployees');
        },
        computed: {
            missingEmployee() {
                return (!this.employeeId);
            },
        },
        watch: {
            employeeId() {
                this.checkErrors();
            },
        },
        methods: {
            async getReports() {
                this.showErrors = true;
                this.showReports = false;
                if (this.checkErrors()) {
                    var employee = this.employees.find((e) => { return e.value == this.employeeId });
                    this.employeeName = employee.text;
                    this.showReports = true;
                    var items = await this.$store.dispatch('reports/getSignatureReportsForEmployee', { employeeId: this.employeeId }) || [];
                    for (var i = 0; i < items.length; i++) {
                        items[i].signatureDateText = $virtus.tools.dates.format(items[i].signatureDate, 'dd/MM/yyyy HH:mm:ss');
                    }
                    this.reportItems = items;
                    this.showErrors = false;
                }
            },
            download(report, download) {
                this.$store.dispatch('reports/getSignatureReportFile', { reportId: report.id, download });
            },
            checkErrors() {
                if (this.showErrors) {
                    if (this.missingEmployee) {
                        this.errors = ['Please, select an employee, it is required.'];
                    } else {
                        this.errors = null;
                    }
                }
                return !this.errors;
            },
        }
    }
</script>

<style>
    .search-result {
        margin-top: 2rem;
    }
    .search-result .title {
        font-weight: 600;
    }
    .search-result .items {
        margin-top: 0.5rem;
        background-color: white;
    }
    .search-result .row {
        padding: 0.5rem;
    }
    .search-result .even-row {
    }
    .search-result .odd-row {
        background-color: rgba(0,0,0,.05);
    }
    .search-result .button:hover {
        cursor: pointer;
        font-weight: 600;
    }
    .search-result .btn {
        padding: 0 10px;
        border-radius: 10px;
    }
    .search-result .col-auto {
        padding: 5px;
    }
</style>
