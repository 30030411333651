<template>
    <admin-page :actions="actions" :fields="fields" :texts="texts"></admin-page>
</template>
<script>

    export default {
        name: 'admin-departments',
        props: [],
        data() {
            return {
                fields: [
                    {
                        key: 'name', label: 'Department name', sortable: true, validations: { required: true, maxLength: 500 },
                        placeholder: 'Enter department name'
                    }
                ],
                actions: {
                    get: 'admin/getDepartments',
                    insert: 'admin/insertDepartment',
                    update: 'admin/updateDepartment'
                },
                texts: {
                    listTitle: 'Departments',
                    newTitle: 'Add New Department',
                    addButton: 'Add Department',
                    deleteButton: 'Remove',
                    itemName: 'deparment',
                },
            }
        },
        created() {

        },
        mounted() {
        },
        computed: {


        },
        methods: {


        }
    }
</script>
<style scoped>
</style>
