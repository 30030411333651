<template>
    <div class="control control-collection">
        <div class="control-label" v-if="label">{{label}}</div>
        <b-input-group>
            <template v-slot:prepend>
                <div class="collection-items">
                    <div :class="'collection-item ' + (allowEdit ? 'allow-edit ' : '') + item.class" :key="index" v-for="(item, index) in items" @click="editItem(item)">
                        <div :class="'collection-item-content ' + item.className" :title="(item.title ? item.title + '\n' : '') + (lockMessage && item.isBlocked ? lockMessage : '')">{{item.text}}</div>
                        <div v-if="!readOnly && !(item.isBlocked)" @click="deleteItem(item)" class="child-icon">x</div>
                    </div>
                    <div v-if="!readOnly && (!items || !items.length)" class="placeholder">{{placeholder}}</div>
                </div>
            </template>
            <template v-slot:append>
                <button v-if="!readOnly" type="button" @click="clickButton" class="btn btn-secondary btn-sm">{{buttonText}}</button>
            </template>
        </b-input-group>
    </div>
</template>

<script>
    export default {
        name: 'control-collection',
        props: ['label', 'items', 'readOnly', 'buttonText', 'placeholder', 'allowEdit', 'lockMessage'],
        data() {
            return {
            };
        },
        created() {

        },
        computed: {
        },
        watch: {
        },
        methods: {
            clickButton() {
                if (!this.readOnly) {
                    this.$emit('click');
                }
            },
            editItem(item) {
                if (!this.readOnly && this.allowEdit) {
                    this.$emit('edit', item);
                }
            },
            deleteItem(item) {
                if (!this.readOnly) {
                    this.$emit('delete', item);
                }
            },
        }
    };
</script>

<style>

    .control-collection .collection-item {
        cursor: default;
        background-color: #5C6BC0;
        color: #fff;
        border-radius: 2px;
        display: flex;
        padding: 3px 6px;
        margin: 3px;
    }

    .control-collection .collection-item .allow-edit {
        cursor: pointer;
    }

    .control-collection .collection-item .child-icon {
        cursor: pointer;
        margin: 0 2px 0 6px;
    }    

    .control-collection button {
        margin: 0.2rem 0;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .control-collection .collection-items {
        margin: 0.2rem 0 0.2rem 0.2rem !important;
        border: 1px solid #ced4da;
        border-radius: 0.25rem 0 0 0.25rem;
        display: flex;
        flex-flow: row wrap;
        flex: 1 1 auto;
        background-color: #fff;
        cursor: default;
        min-height: 2rem;
    }

    .control-collection .div-button {
        width: auto;
    }

    .control-collection button.is-invalid {
        border-color: #dc3545;
        background-color: #dc3545;
    }

    /*.control-collection div.read-only {
        margin: 0.2rem 0;
        border-radius: 0.25rem 0 0 0.25rem;
        border: 1px solid grey;
        padding: 0.3rem 0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        flex: 1 1 auto;
        font-size: 0.9rem;
    }*/

    .control-collection .placeholder {
        color: #bec4ca;
        margin: 6px 4px;
    }

    .control-collection .input-group-prepend {
        flex: 1 1 auto;
    }

</style>
