<template>
    <div class="flex-container full-table">

        <div class="filter-bar">
            <b-input-group size="sm" prepend="Filter">
                <control-text id="filter" ref="filter" size="sm" v-model="filterText" placeholder="enter filter" @keyup.enter.native="filterClick" :disabled="showEditItem"></control-text>
                <b-input-group-append>
                    <b-btn @click.stop="filterClick" :disabled="showEditItem">Go</b-btn>
                </b-input-group-append>
            </b-input-group>
        </div>

        <b-table ref="table" striped small :items="paginatedItems" :fields="tableFields" :responsive="true" no-local-sorting :sort-by.sync="localSortBy" :sort-desc.sync="localSortDesc">
            <!-- Template for Edit item -->
            <template v-slot:cell()="row">
                <span v-if="showNoEditableField(row, row.field)" v-html="formatField(row, row.field)"></span>
                <control-text v-if="showEditableTextField(row.field) && isEdit(row)" v-model="editItem[row.field.key]"
                              :id="row.field.key + '_' + row.item.id" size="sm" :hasErrors="showErrors && row.field.isError"
                              @keyup.native="checkErrors"></control-text>
                <control-select v-if="showEditableSelectField(row.field) && isEdit(row)" v-model="editItem[row.field.value]"
                                :id="row.field.key + '_' + row.item.id" :options="row.field.options" size="sm"
                                :hasErrors="showErrors && row.field.isError" @change="checkErrors" />
                <control-date v-if="showEditableDateField(row.field) && isEdit(row)" v-model="editItem[row.field.key]"
                              :id="row.field.key + '_' + row.item.id" size="sm" :placeholder="'Pick ' + row.field.label"
                              :hasErrors="showErrors && row.field.isError" @change="checkErrors"></control-date>
                <control-checkbox v-if="showEditableBoolField(row.field) && isEdit(row)" v-model="editItem[row.field.key]"
                                  :id="row.field.key + '_' + row.item.id" :hasErrors="showErrors && row.field.isError"></control-checkbox>
            </template>

            <!-- Template for Actions column -->
            <template v-slot:cell(actions)="row" v-if="allowReadWrite">
                <div class="action-buttons">
                    <span v-if="showEditItem && editIndex == row.item.id">
                        <b-button size="sm" variant="outline-secondary" @click.stop="close(row)" class="mr-1" right>Cancel</b-button>
                        <b-button size="sm" variant="outline-secondary" @click.stop="accept(row)" class="mr-1" right>Save</b-button>
                    </span>
                    <span v-else>
                        <slot name="moreActions" :item="row.item" :index="row.index" :showEditItem="showEditItem"></slot>
                        <b-button size="sm" variant="secondary" @click.stop="edit(row)" class="mr-1" :disabled="showEditItem" right>Edit</b-button>
                    </span>
                </div>
            </template>
        </b-table>

        <!-- Template for errors -->
        <div v-if="showErrors">
            <div v-for="error in errors" :key="error" class="error">{{error}}</div>
        </div>

        <div class="bottom-bar">
            <div class="left pagination">
                <b-input-group size="sm" prepend="Page size">
                    <b-form-select id="pageSize" size="sm" :options="pageOptions" v-model="perPage" :disabled="showEditItem" />
                    <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" :disabled="showEditItem" />
                </b-input-group>
            </div>
            <div class="left items-label" v-if="totalRows > 0">Showing items {{pageStart}} to {{pageEnd}} of {{totalRows}}</div>

            <div class="right" v-if="allowReadWrite">
                <slot name="additionalButtons"></slot>
                <b-button size="sm" variant="primary" :disabled="showEditItem" @click.stop="addNew">{{addNewTitle}}</b-button>
            </div>
            <div class="clear"></div>
        </div>

    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue';

    export default {
        name: 'full-table',
        props: ['fields', 'items', 'addNewTitle', 'externalEdit', 'sortBy', 'sortDesc', 'enableRemove', 'allowReadWrite'],
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                pageOptions: [5, 10, 20, 50, 100],
                editItem: {},
                editIndex: null,
                showErrors: false,
                errors: [],
                filter: '',
                filterText: '',
                localSortBy: '',
                localSortDesc: false,
                mounted: false,
                showEditItem: false,
            }
        },
        created() {
            for (var field in this.fields) {
                Vue.set(this.editItem, field.key, null);
            }
        },
        mounted() {
            this.initEditItem();
            this.setShowEditItem(false);
            this.localSortBy = this.sortBy;
            this.localSortDesc = (this.sortDesc === false ? false : true);
            this.mounted = true;
        },
        computed: {
            listItems() {
                return Object.values(this.items);
            },
            filteredItems() {
                var items = this.listItems;
                var filter = this.filter;
                if (filter) {
                    items = items.filter(r => { return (r.search && r.search.includes(filter)); });
                }
                return items;
            },
            orderedItems() {
                var self = this;
                var items = this.filteredItems;
                //var dir = this.localSortDesc;
                if (this.localSortBy) {
                    items.sort(function (a, b) { return self.sortCompare2(a, b, self); });
                }
                return items;
            },
            totalRows() {
                return this.filteredItems.length;
            },
            pageStart() {
                var i = ((this.currentPage - 1) * this.perPage) + 1;
                return i;
            },
            pageEnd() {
                var i = (this.perPage * this.currentPage);
                return (i < this.totalRows ? i : this.totalRows);
            },

            paginatedItems() {
                var items = this.orderedItems;
                var first = (this.currentPage - 1) * this.perPage;
                return items.slice(first, first + this.perPage);
            },

            tableFields() {
                var tableFields = [...this.fields];
                tableFields = tableFields.filter(f => f.dataType != 'file');
                tableFields.push({ key: 'actions', label: '', 'class': 'actions-column' });
                return tableFields;
            },

            isValidEditItem() {
                this.checkErrors();
                return (this.errors.length == 0);
            },
            dateFields() {
                return this.fields.filter(x => { return x.dataType == 'date'; }).map(x => { return x.key; });
            },
        },
        watch: {
            sortBy(newValue) {
                this.localSortBy = newValue;
            },
        },
        methods: {
            initEditItem() {
                for (var i in this.fields) {
                    var field = this.fields[i];
                    this.editItem[field.key] = null;
                    if (field.value) this.editItem[field.value] = null;
                }
            },
            addNew() {
                this.$emit('addnew');
            },
            edit(row) {
                if (this.externalEdit) {
                    this.$emit('showEdit', row);
                } else {
                    for (var [key, value] of Object.entries(row.item)) {
                        Vue.set(this.editItem, key, value);
                    }
                    this.editIndex = row.item.id;
                    this.errors = [];
                    this.showErrors = false;
                    this.setShowEditItem(true);
                }
            },
            remove(row) {
                this.$emit('remove', { item: row.item, index: row.item.id });
            },
            setShowEditItem(show) {
                this.showEditItem = show;
            },
            async accept(/*row*/) {
                this.showErrors = true;
                if (this.isValidEditItem) {
                    this.fillSelectFieldsName();
                    this.$emit('edit', { item: this.editItem, index: this.editIndex });
                }
            },
            fillSelectFieldsName() {
                var selectFields = this.fields.filter(field => field.dataType == 'select');
                var item = this.editItem;
                for (var i = 0; i < selectFields.length; i++) {
                    var field = selectFields[i];
                    var newText = null;
                    var newId = item[field.value];
                    if (newId) {
                        var selectedOption = field.options.find(option => option.value == newId);
                        if (selectedOption) {
                            newText = selectedOption.text;
                        }
                    }
                    item[field.key] = newText;
                }
            },
            close(/*row*/) {
                this.setShowEditItem(false);
                this.errors = [];
                this.showErrors = false;
            },

            movePageToItem(item) {
                var self = this;
                Vue.nextTick(function () {
                    var index = self.orderedItems.findIndex(x => x == item);
                    if (index >= 0) {
                        var page = Math.trunc(index / self.perPage) + 1;
                        self.currentPage = page;
                        self.refresh();
                    }
                });
            },

            showNoEditableField(row, field) {
                return (field.noeditable || !this.showEditItem || this.editIndex != row.item.id);
            },
            showEditableField(field) {
                return (!field.noeditable && this.showEditItem);
            },
            showEditableSelectField(field) {
                return (this.showEditableField(field) && field.dataType == 'select');
            },
            showEditableTextField(field) {
                return (this.showEditableField(field) && field.dataType != 'select' && field.dataType != 'date' && field.dataType != 'bool');
            },
            showEditableDateField(field) {
                return (this.showEditableField(field) && field.dataType == 'date');
            },
            showEditableBoolField(field) {
                return (this.showEditableField(field) && field.dataType == 'bool');
            },
            isEdit(row) {
                return (this.editIndex == row.item.id);
            },
            checkErrors() {
                var errors = [];

                if (this.showErrors) {
                    var fieldsToValidate = this.fields.filter(field => field.validations || field.dataType == 'date');
                    if (fieldsToValidate.length > 0) {
                        var item = this.editItem;
                        for (var i = 0; i < fieldsToValidate.length; i++) {
                            var field = fieldsToValidate[i];
                            var value = item[field.key];
                            var validations = (field.validations || {});
                            var fieldInError = false;
                            if (validations.required) {
                                if ((field.dataType == 'select' && !item[field.value])
                                    || (field.dataType != 'select' && !value)) {
                                    errors.push('The field ' + field.label + ' is required.');
                                    fieldInError = true;
                                }
                            }
                            if (!fieldInError && validations.maxLength) {
                                if (field.dataType != 'select' && value.length > validations.maxLength) {
                                    errors.push('The maximum length for field ' + field.label + ' is ' + validations.maxLength + '.');
                                    fieldInError = true;
                                }
                            }
                            if (!fieldInError && field.dataType == 'date' && value) {
                                if (!$virtus.tools.dates.isValid(value)) {
                                    errors.push('The field ' + field.label + ' is not a valid date.');
                                    fieldInError = true;
                                }
                            }
                            if (!fieldInError && validations.greaterThan && value) {
                                var otherFieldKey = validations.greaterThan;
                                var otherField = this.fields.find(field => field.key == otherFieldKey);
                                var otherValue = item[otherFieldKey];
                                if (otherValue && otherValue >= value) {
                                    errors.push('The field ' + field.label + ' must be greater than field ' + otherField.label + '.');
                                    fieldInError = true;
                                    otherField.isError = true;
                                }
                            }
                            if (!fieldInError && validations.lessThan && value) {
                                var otherFieldKey2 = validations.lessThan;
                                var otherField2 = this.fields.find(field => field.key == otherFieldKey2);
                                var otherValue2 = item[otherFieldKey2];
                                if (otherValue2 && otherValue2 <= value) {
                                    errors.push('The field ' + field.label + ' must be less than field ' + otherField2.label + '.');
                                    fieldInError = true;
                                    otherField2.isError = true;
                                }
                            }

                            if (!fieldInError && validations.greaterOrEqualThan && value) {
                                var otherFieldKey3 = validations.greaterOrEqualThan;
                                var otherField3 = this.fields.find(field => field.key == otherFieldKey3);
                                var otherValue3 = item[otherFieldKey3];
                                if (otherValue3 && otherValue3 > value) {
                                    errors.push('The field ' + field.label + ' must be equal or greater than field ' + otherField3.label + '.');
                                    fieldInError = true;
                                    otherField3.isError = true;
                                }
                            }
                            if (!fieldInError && validations.lessOrEqualThan && value) {
                                var otherFieldKey4 = validations.lessOrEqualThan;
                                var otherField4 = this.fields.find(field => field.key == otherFieldKey4);
                                var otherValue4 = item[otherFieldKey4];
                                if (otherValue4 && otherValue4 < value) {
                                    errors.push('The field ' + field.label + ' must be equal or less than field ' + otherField4.label + '.');
                                    fieldInError = true;
                                    otherField4.isError = true;
                                }
                            }
                            field.isError = fieldInError;
                        }
                    }
                }
                this.errors = errors;
            },
            formatField(row, field) {
                var value = row.item[field.key];
                if (field.dataType == 'date') {
                    value = $virtus.tools.dates.formatDate(value);
                } else if (field.dataType == 'bool') {
                    if (value === true) {
                        value = (field.htmlYes !== undefined ? field.htmlYes : 'Yes');
                    } else if (value === false) {
                        value = (field.htmlNo !== undefined ? field.htmlNo : 'No');
                    } else {
                        value = (field.htmlNull ? field.htmlNull : '');
                    }
                }
                return value;
            },
            sortCompare(a, b, key) {
                var valueA = (a[key] != null ? a[key] : '');
                var valueB = (b[key] != null ? b[key] : '');
                if ((typeof valueA === 'number' && typeof valueB === 'number') || this.dateFields.includes(key)) {
                    // If both compared fields are native numbers or dates
                    return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0)
                } else {
                    // Stringify the field data and use String.localeCompare
                    return valueA.toString().localeCompare(valueB.toString());
                }
            },
            sortCompare2(a, b, self) {
                var key = self.localSortBy;
                var dir = (self.localSortDesc ? 1 : -1);
                var valueA = (a[key] != null ? a[key] : '');
                var valueB = (b[key] != null ? b[key] : '');
                if ((typeof valueA === 'number' && typeof valueB === 'number') || self.dateFields.includes(key)) {
                    // If both compared fields are native numbers or dates
                    return dir * (valueA < valueB ? -1 : (valueA > valueB ? 1 : 0));
                } else {
                    // Stringify the field data and use String.localeCompare
                    return dir * (valueA.toString().toLocaleLowerCase().localeCompare(valueB.toString().toLocaleLowerCase()));
                }
            },
            filterClick() {
                this.currentPage = 1;
                this.filter = this.filterText.toLocaleLowerCase();
                this.refresh();
            },
            refresh() {
                this.$refs.table.refresh();
            },
        }
    }
</script>

<style>
    .full-table td,
    .full-table .table thead th,
    .full-table .table td {
        vertical-align: middle;
    }

    .actions-column {
        width: 9rem;
    }

    .id-column {
        width: 4rem;
    }

    table.b-table > tfoot > tr > th.sorting::before,
    table.b-table > tfoot > tr > th.sorting::after {
        content: none;
    }

    .table-sm .el-input__inner,
    .table-sm .el-input__icon {
        height: 1.9rem;
        line-height: 1.9rem;
    }

    .is-invalid .el-input__inner {
        border-color: #dc3545;
    }

    .table-sm .custom-select-sm {
        height: 1.9rem !important;
        font-size: 100%;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    .bottom-bar > div {
        margin-top: 0.1rem;
    }

    .filter-bar {
        margin: 0 0 0.5rem auto;
        width: 20rem;
    }

        .filter-bar .control,
        .filter-bar .control input {
            margin: 0 !important;
            border-radius: 0;
        }

    .pagination {
        margin-right: 0.5rem;
    }

    .items-label {
        padding-top: 0.35rem;
        margin-right: 0.5rem;
    }

    #pageSize {
        width: 4rem;
        margin-right: 0.25rem;
    }

    .action-buttons {
        white-space: nowrap;
        text-align: right;
    }

        .action-buttons button {
            float: none;
            display: inline-block;
        }
</style>

