<template>
    <div class="main-form ram-signed-sheets">
        <h1 class="header-title">RAMS Signed Sheets</h1>
        <div>

            <control-select v-model="ramId" label="RAMS" :options="rams" size="sm"
                            placeholder="Enter RAM" :errors="errors" />

            <!--<label for="ram">RAMS</label>
            <b-form-select id="ram" v-model="ramId" :options="rams" placeholder="Enter RAMS">
                <template slot="first">
                    <option :value="null"></option>
                </template>
            </b-form-select>-->

        </div>
        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="getReports">Get reports</button>
        </div>
        <div class="search-result" v-if="showReports">
            <div class="title">
                <template v-if="reportItems && reportItems.length">Signature RAMS reports for {{ramName}}</template>
                <template v-else>There are not Signature RAMS reports for {{ramName}}</template>
            </div>
            <div v-if="reportItems"></div>
            <b-container class="items" fluid v-if="reportItems">
                <b-row align-v="center" :key="index" v-for="(report, index) in reportItems" :class="index % 2 ? 'even-row' : 'odd-row'">
                    <b-col>
                        {{report.employeeName}} - {{report.signatureDateText}}
                    </b-col>
                    <b-col cols="auto" class="ml-auto"><button type="button" class="btn btn-secondary btn-sm" @click="download(report, false)">Open</button></b-col>
                    <b-col cols="auto"><button type="button" class="btn btn-primary btn-sm" @click="download(report, true)">Download</button></b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                ramId: null,
                ramName: '',
                reportItems: [],
                errors: null,
                showErrors: false,
                showReports: false,
                rams: [],
            }
        },
        async created() {
            this.rams = await this.$store.dispatch('rams/getOptionsFromRams');
        },
        computed: {
            missingRam() {
                return (!this.ramId);
            },
        },
        watch: {
            ramId() {
                this.checkErrors();
            },
        },
        methods: {
            async getReports() {
                this.showErrors = true;
                this.showReports = false;
                if (this.checkErrors()) {
                    var ram = this.rams.find((e) => { return e.value == this.ramId });
                    this.ramName = ram.text;
                    this.showReports = true;
                    var items = await this.$store.dispatch('reports/getSignatureReportsForRam', { ramId: this.ramId }) || [];
                    for (var i = 0; i < items.length; i++) {
                        items[i].signatureDateText = $virtus.tools.dates.format(items[i].signatureDate, 'DD/MM/YYYY HH:mm:ss');
                    }
                    this.reportItems = items;
                    this.showErrors = false;
                }
            },
            download(report, download) {
                this.$store.dispatch('reports/getSignatureReportFile', { reportId: report.id, download });
            },
            checkErrors() {
                if (this.showErrors) {
                    if (this.missingRam) {
                        this.errors = ['Please, select a RAM, it is required.'];
                    } else {
                        this.errors = null;
                    }
                }
                return !this.errors;
            },
        }
    }
</script>

<style scoped>
    .search-result {
        margin-top: 2rem;
    }
    .search-result .title {
        font-weight: 600;
    }
    .search-result .items {
        margin-top: 0.5rem;
        background-color: white;
    }
    .search-result .row {
        padding: 0.5rem;
    }
    .search-result .even-row {
    }
    .search-result .odd-row {
        background-color: rgba(0,0,0,.05);
    }
    .search-result .button:hover {
        cursor: pointer;
        font-weight: 600;
    }
    .search-result .btn {
        padding: 0 10px;
        border-radius: 10px;
    }
    .search-result .col-auto {
        padding: 5px;
    }
</style>
