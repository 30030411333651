<template>
    <div class="main-form expiring-skills">
        <h1 class="header-title">Required Skills expiring in next weeks</h1>
        <div>
            <p>
                This report shows all the required skills ordered by person which will expire in
                <control-text v-model="weeks" placeholder="x" type="number" :min="0" :max="99"></control-text>
                weeks.
            </p>
            <control-checkbox v-model="addPendingAndOutOfDate">Add pending and out of date skills</control-checkbox>
        </div>
        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" @click="download">Download</button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                weeks: 4,
                addPendingAndOutOfDate: true,
            }
        },
        async created() {
        },
        computed: {
        },
        methods: {
            download() {
                this.$store.dispatch('reports/getExpiringSkillsReport', { download: true, weeks: this.weeks, addPendingAndOutOfDate: this.addPendingAndOutOfDate });
            },
        }
    }
</script>

<style>
    .expiring-skills .control-text {
        display: inline-block;
    }

    .expiring-skills .control-text input {
        font-size: small;
        width: 3rem;
        padding: 0 2px 0 8px;
        height: 1.5rem;
    }

</style>
