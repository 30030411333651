import { profiles } from './profiles';
import { mapState, mapGetters } from 'vuex';


var ComponentMixin = {
    created() {
    },
    mounted() {

    },
    computed: {
        ...mapState('security', ['userId', 'appId']),
        ...mapGetters('security', ['getCurrentProfileId']),
    },
    methods: {
        async checkUserHasReadRights() {
            var profile = this.getCurrentProfileId;
            return await checkUserRights(profile, 'read');
        },
        async checkUserHasWriteRights() {
            var profile = this.getCurrentProfileId;
            return await checkUserRights(profile, 'write');
        },
        async checkUserHasAdminRights() {
            var profile = this.getCurrentProfileId;
            return await checkUserRights(profile, 'admin');
        },
    }
};

async function checkUserRights(profile, requiredLevel) {
    var isAdmin = (profile === profiles.PlatformAdmin || profile === profiles.Administrator);
    if (isAdmin) return true;
    if (requiredLevel === 'admin') return false;
    var right = await $virtus.security.userCanAccessCurrentRoute();
    if (requiredLevel === 'write' && right === 'read')
        return false;
    else
        return !!right;
}

export default ComponentMixin;
