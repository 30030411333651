<template>
    <div class="control control-checkbox-group">
        <div class="control-label" v-if="label">{{label}}</div>
        <div :class="(controlClass ? controlClass : '') + (showErrorState ? ' is-invalid' : '')">
            <div :key="index" v-for="(option, index) in groupOptions">
                <span @click="check(option)" :class="'check ' + (readOnly ? 'read-only' : 'cursor-hand')">
                    <icon class="input-icon" v-if="option.selected">
                        <icon name="regular/square" class="square-icon child-icon"></icon>
                        <icon name="check" scale="1.5" :class="(readOnly ? 'check-gray-icon' : 'check-green-icon') + ' child-icon'"></icon>
                    </icon>
                    <icon v-else name="regular/square" class="input-icon square-icon unchecked"></icon>
                </span>
                <span>{{option.text}}</span>
            </div>
            <div v-if="errors">
                <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'control-checkbox-group',
        props: ['label', 'value', 'options', 'hasErrors', 'readOnly', 'errors', 'controlClass'],
        data() {
            return {
                groupValue: null,
            };
        },
        mounted() {
            this.groupValue = this.value;
        },
        computed: {
            showErrorState() {
                return (this.errors || this.hasErrors);
            },
            groupOptions() {
                return this.options.map(x => { return { value: x.value, text: x.text, selected: (x.value == this.groupValue) }; });
            },
        },
        watch: {
            value(newValue) {
                this.groupValue = newValue;
            },
        },
        methods: {
            check(item) {
                if (!this.readOnly) {
                    this.groupValue = (this.groupValue == item.value ? null : item.value);
                    this.$emit('input', this.groupValue);
                }
            }
        }
    };
</script>

<style>
    .control-checkbox-group .read-only svg {
    }

    .control-checkbox-group .check {
        /*        position: relative;
            top: 0.3rem;
    */ margin: 0.2rem;
    }

    .control-checkbox-group .check svg {
        vertical-align: -0.35rem;
        padding: 0.1rem;
    }

</style>
