<template>
    <div :class="'page-container ' + showViewerClass" :key="viewVersion">
        <!--<div v-if="showViewer" id="app" class="page-container">
            <router-view></router-view>
        </div>-->
        <!--<div v-if="showVideoViewer" id="app" class="page-container">
            <video-viewer></video-viewer>
        </div>-->
        <div id="app" class="container">
            <div class="navbar-container" v-if="showMenu">
                <menu-bar></menu-bar>
                <!--<menu-bar v-if="showDefaultMenu || showWorkersMenu" :items="menuItems" title="HSE RECORD"></menu-bar>
                <menu-bar v-if="showIpadMenu" title="HSE RECORD">
                    <span>
                        <b-nav-item>iPad Signature for {{getEmployeeName}}</b-nav-item>
                    </span>
                </menu-bar>
                <menu-bar v-if="showVisitorsMenu" title="HSE RECORD">
                    <span>
                        <b-nav-item>Visitors and Contractors Induction System</b-nav-item>
                    </span>
                </menu-bar>-->
            </div>
            <div class="content" :key="viewVersion">
                <div :class="showMenu ? 'app-view' : ''" v-if="$route.matched.length">
                    <router-view></router-view>
                </div>
            </div>
            <footer class="page-footer">
                <div class="version">v2.0.0</div>
                <div class="footer">&copy; 2022 Virtus environment</div>
            </footer>
            <loading></loading>

        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                showViewer: false,
            }
        },
        async created() {
            //await this.$store.dispatch('security/tryToLogin');
        },
        async mounted() {
            //await this.$store.dispatch('security/loadSecurityContext');
        },
        watch: {
            $route(to, from) {
                this.showViewer = (to.meta && to.meta.viewer);
            }
        },
        computed: {
            //...mapState(['showViewer', 'showVideoViewer']),
            ...mapState('security', ['viewVersion']),
            ...mapGetters('security', ['isLoggedIn']),
            showViewerClass() {
                return (this.showViewer ? 'show-viewer' : '');
            },
            showMenu() {
                return (this.isLoggedIn && !this.$route.meta.anonymous);
            }
            //...mapGetters(['isIpadUser', 'isVisitorsUser']),
            //...mapGetters('ipad', ['getEmployeeName']),
            //menuItems() {
            //    var menuItems = this.$router.options.routes;
            //    return menuItems;
            //},
            //showDefaultMenu() {
            //    return (this.isLoggedIn && this.getHomeRoute.name == 'default');
            //},
            //showIpadMenu() {
            //    return (this.isLoggedIn && this.getHomeRoute.name == 'ipad');
            //},
            //showVisitorsMenu() {
            //    return (this.isLoggedIn && this.getHomeRoute.name == 'visitors');
            //},
            //showWorkersMenu() {
            //    return (this.isLoggedIn && this.getHomeRoute.name == 'workers');
            //},
        },
        methods: {


        },
    }
</script>

<style>
 
</style>
