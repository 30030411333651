
function skillCell(viewModel, jobPosition, skill) {
    var self = this;
    self.id = skill.id + '_' + jobPosition.id;
    var assignment = skill.assignments[jobPosition.id];
    var originalAssignmentLevelId = (assignment ? assignment.assignmentLevelId : 0);
    self.assignmentLevelId = originalAssignmentLevelId;
    var originalTrainingDate = (assignment ? assignment.trainingDate : null);
    var trainingDate = originalTrainingDate;
    var copyToEmployees = false;
    var changed = false;
    self.jobPosition = jobPosition;
    self.skill = skill;
    self.html = '';
    self.text = '';
    self.css = '';
    self.click = async function () {
        var update = false;
        if (viewModel.selectedAssignmentLevel != null && self.assignmentLevelId != viewModel.selectedAssignmentLevel.id) {
            self.assignmentLevelId = viewModel.selectedAssignmentLevel.id;
            if (!self.assignmentLevelId) trainingDate = null;
            update = true;
        }
        if (viewModel.selectedTrainingDate && trainingDate != viewModel.selectedTrainingDate.trainingDate && self.assignmentLevelId) {
            trainingDate = viewModel.selectedTrainingDate.trainingDate;
            update = true;
        }
        if (update) {
            await updateChangedItem();
            updateHtml();
        }
    };
    self.getData = function () {
        return {
            skillId: skill.id,
            jobPositionId: jobPosition.id,
            assignmentLevelId: self.assignmentLevelId,
            trainingDate: trainingDate,
            copyToEmployees: copyToEmployees,
        };
    };
    self.saveChanges = function () {
        originalAssignmentLevelId = self.assignmentLevelId;
        originalTrainingDate = trainingDate;
        changed = false;
        copyToEmployees = false;
        if (self.assignmentLevelId && self.assignmentLevelId > 0) {
            skill.assignments[jobPosition.id] = {
                assignmentLevelId: self.assignmentLevelId,
                jobPositionId: jobPosition.id,
                skillId: skill.id,
                trainingDate: trainingDate
            };
        } else {
            delete skill.assignments[jobPosition.id];
        }
        updateHtml();
    };
    self.discardChanges = function () {
        self.assignmentLevelId = originalAssignmentLevelId;
        trainingDate = originalTrainingDate;
        copyToEmployees = false;
        changed = false;
        updateHtml();
    };

    //Init
    updateHtml();

    async function updateChangedItem() {
        //Set assignment state
        var changedItem = viewModel.changedItems[self.id];
        changed = (self.assignmentLevelId != originalAssignmentLevelId || trainingDate != originalTrainingDate);
        if (!changed && changedItem) {
            //Not changed, delete changed item
            viewModel.itemsChangedCount--;
            delete viewModel.changedItems[self.id];
        } else if (changed && !changedItem) {
            //Changed now, create changed item
            viewModel.itemsChangedCount++;
            viewModel.changedItems[self.id] = self;
        } //Else Not changed, not changed item: do nothing

        //If we remove the skill assignment, ask to copy skill to employees
        if (changed && self.assignmentLevelId == 0 && originalAssignmentLevelId > 0) {
            let result = await viewModel.tools.messages.confirm('Copy to employees', 'The skill will be unassigned from job position. Do you want to copy the skill assignment to employees?');
            copyToEmployees = !!(result && result.isConfirmed);
        }
    }

    function updateHtml() {
        if (self.assignmentLevelId > 0) {
            self.text = self.assignmentLevelId;
            self.html = self.assignmentLevelId;
            self.css = 'clickable assignment-level-' + self.assignmentLevelId;
        } else {
            self.text = '';
            self.html = '';
            self.css = 'clickable';
        }                
        if (changed) {
            self.html += '<span class="note"></span>';
        }
        if (trainingDate) {
            self.html += '<span class="training-date">' + $virtus.tools.dates.format(trainingDate, 'dd/MM') + '</span>';
        }
    }
}

export default skillCell;
