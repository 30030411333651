import Vue from 'vue';
import controls from './controls'
import security from "./security";
import tools from "./tools";
import config from '../app.config';

window.$virtus = {
    install,
    configure,
    controls,
    security,
    tools,
    config
};

$virtus.install();

function install(/*Vue*/) {
    if ($virtus.installed) return;
    $virtus.installed = true;

    //Set environment
    var hostname = window.document.location.hostname;
    for (var i in $virtus.config.environments) {
        var environment = $virtus.config.environments[i];
        if (hostname.indexOf(environment.hostname) >= 0) {
            $virtus.config.environment = environment;
            console.log('client environment: ' + environment.name);
            break;
        }
    }
    if (!$virtus.config.environment) {
        console.log('client environment: unknown');
    }

    var environmentName = 'unknown';
    $virtus.tools.api.get('/api/security/environment-name').then((result) => {
        if (result) {
            environmentName = result;
        }
        console.log('%c' + environmentName + ' environment', 'background-color: black; color: white; display: block;padding: 2px 100px; border-radius:5px;text-align: center;font-weight: bold;');
    });


    //window.$virtus = virtus;
    //Vue.prototype.$virtus = virtus;
    //Vue.prototype.tools = virtus.tools;
    //window.virtus = {
    //    controls,
    //    security,
    //    tools,
    //    router: null,
    //    store: null,
    //};
    //Vue.prototype.tools = tools;
}

function configure(items) {
    if (items) {
        $virtus.router = items.router;
        $virtus.store = items.store;
        $virtus.controls.configure(items);
        $virtus.security.configure(items);
    }
}

//Vue.use(virtus);

//export default virtus;
