var api = $virtus.tools.api;

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getJobPositionRamReport(store, data) {
            var url = '/api/reports/jobposition-rams?jobPositionId=' + data.jobPositionId + '&jobPositionName=' + data.jobPositionName + '&employeeName=' + data.employeeName
                + '&onlyRequiredAndRecommended=' + data.onlyRequiredAndRecommended + '&download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getJobPositionSkillReport(store, data) {
            var url = '/api/reports/jobposition-skills?jobPositionId=' + data.jobPositionId + '&jobPositionName=' + data.jobPositionName + '&employeeName=' + data.employeeName
                + '&onlyRequiredAndRecommended=' + data.onlyRequiredAndRecommended + '&download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getEmployeeRamReport(store, data) {
            var url = '/api/reports/employee-rams?jobPositionId=' + data.jobPositionId + '&jobPositionName=' + data.jobPositionName + '&employeeId=' + data.employeeId
                + '&employeeName=' + data.employeeName + '&onlyRequiredAndRecommended=' + data.onlyRequiredAndRecommended + '&download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getEmployeeSkillReport(store, data) {
            var url = '/api/reports/employee-skills?jobPositionId=' + data.jobPositionId + '&jobPositionName=' + data.jobPositionName + '&employeeId=' + data.employeeId
                + '&employeeName=' + data.employeeName + '&onlyRequiredAndRecommended=' + data.onlyRequiredAndRecommended + '&download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getRAMsToBeUpdatedReport(store, data) {
            var url = '/api/reports/rams-to-be-updated?download=' + data.download + '&months=' + data.months + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getRequiredRAMsByPersonReport(store, data) {
            var url = '/api/reports/required-rams-by-person?download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getRequiredSkillsByPersonReport(store, data) {
            var url = '/api/reports/required-skills-by-person?download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getRamSignaturesReport(store, data) {
            var url = '/api/reports/ram-signatures?download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getSkillSignaturesReport(store, data) {
            var url = '/api/reports/skill-signatures?download=' + data.download + '&appId=2';
            let result = (data.download ? await api.download(url) : await api.openExcel(url));
            return result;
        },

        async getSignatureReportsForEmployee(store, data) {
            let result = await api.get('/api/reports/signatures/employees/' + data.employeeId);
            return result;
        },

        async getSignatureReportsForRam(store, data) {
            let result = await api.get('/api/reports/signatures/rams/' + data.ramId);
            return result;
        },

        async getSignatureReportFile(store, data) {
            var url = '/api/reports/signatures/' + data.reportId + '?download=' + data.download + '&appId=2';
            let result = await (data.download ? api.download(url) : api.open(url));
            return result;
        },

        async getExpiringSkillsReport(store, data) {
            let result = await api.download('/api/reports/skills/expiring?download=' + data.download + '&weeks=' + data.weeks + '&addPendingAndOutOfDate=' + data.addPendingAndOutOfDate);
            return result;
        },

    }
};
