<template>

    <div class="select-assignment-level">
        <div class="select-label" v-if="!readOnly">Click assignment level for quick edit:</div>
        <div class="assignment-levels-bar">
            <div :key="index" v-for="(level, index) in assignmentLevels" @click="clickAssignmentLevel(level)" :id="'assignment-level-' + level.id" :class="{ 'selected': (selectedLevel && level.id == selectedLevel.id), 'clickable': !readOnly }">{{level.name}}</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'select-assignment-level',
        props: ['readOnly'],
        data() {
            return {
                assignmentLevels: [{ id: 0, name: "Clear" }, { id: 1, name: "1 - Mandatory" }, /*{ id: 2, name: "2 - Recommended" },*/ { id: 3, name: "3 - Not required" }],
                selectedLevel: null,
            }
        },
        async created() {
        },
        computed: {

        },
        methods: {
            clickAssignmentLevel(level) {
                if (!this.readOnly) {
                    this.selectedLevel = (level != this.selectedLevel ? level : null);
                    this.$emit('click', this.selectedLevel);
                }
            },
            clear() {
                this.selectedLevel = null;
            },
        }
    }
</script>

<style>
    .select-assignment-level .select-label {
        font-size: x-small;
        font-style: italic;
    }

    .select-assignment-level .assignment-levels-bar {
        display: flex;

    }

    .select-assignment-level .assignment-levels-bar div {
        padding: .1rem .5rem;
        font-size: x-small;
        margin: 1px;
        position: relative;
    }

    .select-assignment-level .clickable {
        cursor: pointer;
    }

    .select-assignment-level .clickable:hover {
        color: black!important;
    }

/*    .select-assignment-level .selected {
        border: 2px solid #6f6f6f !important;
    }
*/
    .select-assignment-level #assignment-level-0 {
        background-color: white;
    }

    .select-assignment-level #assignment-level-1 {
        background-color: lightgoldenrodyellow;
    }

    .select-assignment-level #assignment-level-2 {
        background-color: darkseagreen;
    }

    .select-assignment-level #assignment-level-3 {
        background-color: cornflowerblue !important;
    }

</style>
