import messages from "./messages";

let changes = {
    beforeRouteLeave(to, from, next, changedItems) {
        if (changedItems) {
            messages.confirm('Are you sure?', 'You have unsaved changes. Do you really want to leave?').then(value => {
                if (value && value.isConfirmed) {
                    next();
                } else {
                    next(false);
                }
            }, function () {
                next(false);
            });
        } else {
            next();
        }
    },
    checkDocumentChanges(original, doc, fields) {
        if (original && doc) {
            for (var i in fields) {
                if (original[fields[i]] != doc[fields[i]]) {
                    return true;
                }
            }
        }
        return false;
    },
    cloneDocument(doc) {
        var cloned = {};
        for (var i in doc) {
            cloned[i] = doc[i];
        }
        return cloned;
    },
    deepClone(obj) {
        if (null == obj || "object" != typeof obj) return obj;
        if (obj instanceof Date) {
            var copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }
        if (obj instanceof Array) {
            var copy2 = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy2[i] = this.deepClone(obj[i]);
            }
            return copy2;
        }
        if (obj instanceof Object) {
            var copy3 = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy3[attr] = this.deepClone(obj[attr]);
            }
            return copy3;
        }
        throw new Error("Unable to copy obj this object.");
    },
    deepCheckDocumentChanges(original, doc) {
        //If property exists in doc and not in original, there are changes
        if (!original && !!doc) {
            return true;
        }

        if (null == doc || "object" != typeof doc) {
            let isNotEqual = (original !== doc);
            //if (isNotEqual) console.log("1. original: " + original + ' doc: ' + doc);
            return isNotEqual;
            //return original !== doc
        }
        if (doc instanceof Date) {
            let isNotEqual = (!original || original.getTime() !== doc.getTime());
            //if (isNotEqual) console.log("2. original: " + original + ' doc: ' + doc);
            return isNotEqual;
            //return original.getTime() !== doc.getTime();
        }
        if (doc instanceof Array) {
            for (var i = 0, len = doc.length; i < len; i++) {
                if (!original[i] || this.deepCheckDocumentChanges(original[i], doc[i])) {
                    //console.log("3. original: " + original + ' doc: ' + doc);
                    return true;
                }
            }
        }
        if (doc instanceof Object) {
            for (var attr in doc) {
                if (doc.hasOwnProperty(attr)) {
                    if (this.deepCheckDocumentChanges(original[attr], doc[attr])) {
                        //console.log("4. original: " + original + ' doc: ' + doc);
                        return true;
                    }
                }
            }
        }

        return false;
    }
};

export default changes;
