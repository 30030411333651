import Vue from 'vue';
import './axios-custom';
import componentMixin from "./component-mixin";
import login from "./login.vue";
import logout from "./logout.vue";
import changePassword from "./change-password.vue";
import forgottenPassword from "./forgotten-password.vue";
import menuBar from "./menu-bar.vue";
import store from "./store";
import { allProfiles, profiles, profileOptions } from './profiles';


function install(Vue) {
    if (install.installed) return;
    install.installed = true;
    Vue.mixin(componentMixin);
    Vue.component("login", login);
    Vue.component("logout", logout);
    Vue.component("change-password", changePassword);
    Vue.component("forgotten-paswword", forgottenPassword);
    Vue.component("menu-bar", menuBar);
}

function configure(items) {
    if (items) {
        if (items.router) {
            items.router.addRoute({ path: '/login', component: login, meta: { anonymous: true } });
            items.router.addRoute({ path: '/change-password', component: changePassword, meta: { anonymous: true } });
            items.router.addRoute({ path: '/forgotten-password', component: forgottenPassword, meta: { anonymous: true } });
            items.router.addRoute({ path: '/logout', component: logout, meta: { anonymous: true } });
        }
        if (items.store) {
            if (!items.store._modules.root._children.security) {
                items.store.registerModule('security', store);
            }
        }
    }
}

async function userCanAccessRoute(route) {
    var options = route.meta;
    if (!options || options.anonymous) {
        return 'anonymous';
    }

    var $store = $virtus.store;
    var userId = $store.state.security.userId;
    var profile = $store.getters['security/getCurrentProfileId'];

    if (!userId) {
        await $store.dispatch('security/loadSecurityContextToken');
        userId = $store.state.security.userId;
        profile = $store.getters['security/getCurrentProfileId'];
    }

    if (userId) {
        if (options.onlyUsers) {
            if (!Array.isArray(options.onlyUsers)) options.onlyUsers = [options.onlyUsers];
            return (options.onlyUsers.includes(userId) ? 'user' : false);
        } else {
            var isAdmin = (profile == profiles.PlatformAdmin || profile == profiles.Administrator);
            if (options.onlyAdmin) {
                return (isAdmin ? 'admin' : false);
            } else if (options.allProfiles) {
                return (isAdmin || profile == profiles.Manager || profile == profiles.Worker ? 'all' : false);
            } else {
                var result = false;
                if (options.write) {
                    if (!Array.isArray(options.write)) options.write = [options.write];
                    result = (isAdmin || options.write.includes(profile) ? 'write' : false);
                }
                if (!result && options.read) {
                    if (!Array.isArray(options.read)) options.read = [options.read];
                    result = (isAdmin || options.read.includes(profile) ? 'read' : false);
                }
                return result;
            }
        }
    }
    return false;
}

async function userCanAccessCurrentRoute() {
    var route = $virtus.router.currentRoute;
    return await userCanAccessRoute(route);
}

let security = {
    install,
    configure,
    store,
    allProfiles,
    profiles,
    profileOptions,
    userCanAccessRoute,
    userCanAccessCurrentRoute,
    components: {
        login,
        logout,
        changePassword,
        forgottenPassword,
        menuBar
    },
};

Vue.use(security);

export default security;
