var tools = $virtus.tools;

let models = {

    refreshComputedFullRamFields(ram) {
        this.refreshComputedRamFields(ram);
        var refreshAssignment = this.refreshComputedAssignmentFields;
        for (var i in ram.assignments) {
            refreshAssignment(ram.assignments[i]);
        }
        var refreshSignature = this.refreshComputedRamSignatureFields;
        for (var j in ram.signatures) {
            refreshSignature(ram.signatures[j]);
        }
        return ram;
    },

    refreshComputedRamFields(ram) {
        var issueDate = tools.dates.formatDate(ram.issueDate);
        var reviewDate = tools.dates.formatDate(ram.reviewDate);
        var passedReviewDate = tools.dates.isPastDate(ram.reviewDate);
        ram.html = '<div title="' + ram.reference + '">' + ram.reference + '</div>'
            + '<div title="' + ram.description + '">' + ram.description + '</div>'
            + '<div>' + ram.version + '</div>'
            + '<div>' + issueDate + '</div>'
            + '<div class="review-date">' + reviewDate + '</div>';
        if (ram.requiresReinduction && ram.reinductionMonths) {
            ram.html += '<div class="reinduction" title="Requires reinduction each ' + ram.reinductionMonths + ' months">r-' + ram.reinductionMonths + '</div>';
        }

        ram.css = (passedReviewDate ? 'passedReviewDate' : '');

        ram.search = (ram.reference + ' ' + ram.description + ' ' + ram.version).toLocaleLowerCase() + ' ' + tools.dates.formatDate(ram.issueDate) + ' ' + tools.dates.formatDate(ram.reviewDate);
        ram.searchName = (ram.reference + ' ' + ram.description).toLocaleLowerCase();
        return ram;
    },

    refreshComputedFullSkillFields(skill) {
        this.refreshComputedSkillFields(skill);
        var refreshAssignment = this.refreshComputedAssignmentFields;
        for (var i in skill.assignments) {
            refreshAssignment(skill.assignments[i]);
        }
        var refreshSignature = this.refreshComputedSkillSignatureFields;
        for (var j in skill.signatures) {
            refreshSignature(skill.signatures[j]);
        }
        return skill;
    },

    refreshComputedSkillFields(skill) {
        skill.html = '<div title="' + skill.description + '">' + skill.description + '</div>';
        skill.search = skill.description.toLocaleLowerCase();
        return skill;
    },

    refreshComputedEmployeeFields(employee) {
        employee.search = (employee.name + ' ' + employee.jobPosition + ' ' + employee.department + ' ' + employee.area + ' ' + employee.shift).toLocaleLowerCase();
        employee.searchName = employee.name.toLocaleLowerCase();
        employee.searchJobPosition = employee.jobPosition.toLocaleLowerCase();
        if (employee.shiftId == 1) { //Leaver
            employee.email = null;
            employee.profileId = 0;
            employee.profileName = null;
        }
        return employee;
    },

    refreshComputedAssignmentFields(assignment) {
        var assignmentLevelId = assignment.assignmentLevelId;
        assignment.text = (assignmentLevelId > 0 ? assignmentLevelId : '');
        assignment.css = (assignmentLevelId > 0 ? 'assignment-level-' + assignmentLevelId : '');
        return assignment;
    },

    refreshComputedRamSignatureFields(ramSignature) {
        ramSignature.text = (ramSignature.signatureDate ? tools.dates.formatDate(ramSignature.signatureDate) : '');
        return ramSignature;
    },

    refreshComputedSkillSignatureFields(skillSignature) {
        if (skillSignature.validFromDate && (skillSignature.validFromDate >= Date.now() || !skillSignature.expirationDate)) {
            skillSignature.text = 'from ' + tools.dates.formatDate(skillSignature.validFromDate);
        } else if (skillSignature.expirationDate) {
            skillSignature.text = 'until ' + tools.dates.formatDate(skillSignature.expirationDate);
        } else {
            skillSignature.text = '';
        }
        return skillSignature;
    },




    getSkillSignatureClass(assignmentLevelId, validFromDate, expirationDate) {
        var className = '';
        var validFromDateIsValid = ((validFromDate && validFromDate <= Date.now()) || (!validFromDate && expirationDate));
        var expirationDateIsValid = ((expirationDate && expirationDate >= Date.now()) || (!expirationDate && validFromDate));
        var signatureIsValid = (validFromDateIsValid && expirationDateIsValid);
        switch (assignmentLevelId) {
            case 1, '1': //Required
                className = (signatureIsValid ? 'valid-signature' : 'required-no-valid-signature');
                break;
            case 2, '2': //Recommended
                className = (signatureIsValid ? 'valid-signature' : 'recommended-no-valid-signature');
                break;
            case 3, '3': //Not required
                className = 'not-required-signature';
                break;
        }
        return className;
    },
    //convertRamsDatesFromStorage(rams) {
    //    var convertToDate = tools.dates.convertToDate;
    //    for (var i in rams) {
    //        var ram = rams[i];
    //        ram.issueDate = convertToDate(ram.issueDate);
    //        ram.lastValidIssueDate = convertToDate(ram.lastValidIssueDate);
    //        ram.reviewDate = convertToDate(ram.reviewDate);
    //        for (var signatureKey in ram.signatures) {
    //            var signature = ram.signatures[signatureKey];
    //            signature.signatureDate = convertToDate(signature.signatureDate);
    //        }
    //    }
    //},
    //convertSkillsDatesFromStorage(skills) {
    //    var convertToDate = tools.dates.convertToDate;
    //    for (var i in skills) {
    //        var skill = skills[i];
    //        for (var signatureKey in skill.signatures) {
    //            var signature = skill.signatures[signatureKey];
    //            signature.expirationDate = convertToDate(signature.expirationDate);
    //        }
    //    }
    //},
    //convertRamsDatesFromServer(rams) {
    //    var convertToDate = tools.dates.convertDateFromServer;
    //    for (var i in rams) {
    //        var ram = rams[i];
    //        ram.issueDate = convertToDate(ram.issueDate);
    //        ram.lastValidIssueDate = convertToDate(ram.lastValidIssueDate);
    //        ram.reviewDate = convertToDate(ram.reviewDate);
    //    }
    //},
    //convertRamsSignaturesDatesFromServer(signatures) {
    //    var convertToDate = tools.dates.convertDateFromServer;
    //    for (var signatureKey in signatures) {
    //        var signature = signatures[signatureKey];
    //        signature.signatureDate = convertToDate(signature.signatureDate);
    //    }
    //},
    //convertSkillsSignaturesDatesFromServer(signatures) {
    //    var convertToDate = tools.dates.convertDateFromServer;
    //    for (var signatureKey in signatures) {
    //        var signature = signatures[signatureKey];
    //        signature.expirationDate = convertToDate(signature.expirationDate);
    //        signature.validFromDate = convertToDate(signature.validFromDate);
    //    }
    //},

    getSortedRams(rams) {
        if (!Array.isArray(rams)) rams = Object.values(rams);
        rams.sort(function (a, b) { return a.reference.toLocaleLowerCase().localeCompare(b.reference.toLocaleLowerCase()); });
        return rams;
    },

    getSortedSkills(skills) {
        if (!Array.isArray(skills)) skills = Object.values(skills);
        skills.sort(function (a, b) { return a.description.toLocaleLowerCase().localeCompare(b.description.toLocaleLowerCase()); });
        return skills;
    },

};

export default models;
