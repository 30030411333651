
//Import components
import Vue from 'vue';
import './$virtus';
import app from './app.vue';
import store from './store';
import router from './router';

//Import CSS
import './css/site.css';

//Import icons
import 'vue-awesome/icons/question-circle';
import 'vue-awesome/icons/arrow-down';
import 'vue-awesome/icons/arrow-up';
import 'vue-awesome/icons/trash-alt';
import 'vue-awesome/icons/film';

$virtus.configure({ router, store });

//Register components
const files = require.context('./', true, /\.vue$/i);
files.keys().forEach(key => {
    var file = files(key);
    if (file.default) file = file.default;
    if (file.name && key.indexOf('/packages/') < 0) {
        Vue.component(file.name, file);
    }
});

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
    render: h => h(app),
    store,
    router
}).$mount('#app');
