<template>
    <div class="control control-text">
        <div v-if="position == 'right'">
            <b-container>
                <b-row>
                    <b-col class="mr-auto" v-if="label">
                        <div class="control-label">{{label}}</div>
                    </b-col>
                    <b-col cols="auto">
                        <div v-if="readOnly" class="read-only">{{value ? value : '&nbsp;'}}</div>
                        <div v-if="!readOnly">
                            <b-form-textarea :id="id" :type="type" ref="rightTextArea" v-if="useTextArea" :value="textValue" @input="onInput" :placeholder="placeholder"
                                             :state="errorState" :rows="rows" :max-rows="maxRows" @focus="showKeyboard" @keydown="keydown"></b-form-textarea>
                            <b-form-input :id="id" :type="type" ref="rightText" v-else :value="textValue" @input="onInput" :placeholder="placeholder"
                                          :state="errorState" @focus="showKeyboard" @keydown="keydown" :min="min" :max="max" :step="step"></b-form-input>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <div class="control-label" v-if="label">{{label}}</div>
            <div v-if="readOnly" class="read-only">{{value ? value : '&nbsp;'}}</div>
            <div v-if="!readOnly">
                <b-form-textarea :id="id" :type="type" ref="bottomTextArea" v-if="useTextArea" :value="textValue" @input="onInput" :placeholder="placeholder"
                                 :state="errorState" :rows="rows" :max-rows="maxRows" @focus="showKeyboard" @keydown="keydown"></b-form-textarea>
                <b-form-input :id="id" :type="type" ref="bottomText" v-else :value="textValue" @input="onInput" :placeholder="placeholder" :state="errorState"
                              @focus="showKeyboard" @keydown="keydown" :min="min" :max="max" :step="step"></b-form-input>
            </div>
        </div>
        <div v-if="required && isEmpty" class="error">The field {{label}} is required.</div>
        <div v-if="errorMaxLength" class="error">The maximum length for field {{label}} is {{maxLength}}.</div>
        <div v-if="errors">
            <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'control-text',
        props: ['label', 'value', 'placeholder', 'hasErrors', 'readOnly', 'rows', 'maxRows', 'position',
            'type', 'id', 'errors', 'required', 'maxLength', 'max', 'min', 'step'],
        data() {
            return {
                textValue: null,
                element: null,
            };
        },
        mounted() {
            this.textValue = this.value;
        },
        computed: {
            //...mapState(['keyboardValue', 'keyboardElement']),
            useTextArea() {
                return (this.rows && this.rows > 0 ? true : false);
            },
            isEmpty() {
                return (this.textValue === null || this.textValue === undefined);
            },
            errorMaxLength() {
                return (this.maxLength && !this.isEmpty && this.textValue.length > this.maxLength);
            },
            errorState() {
                return (this.errors || this.hasErrors || (this.required && this.isEmpty) ? false : null);
            },
        },
        watch: {
            value(newValue) {
                this.textValue = newValue;
            },
            //keyboardValue(newValue) {
            //    if (this.keyboardElement && this.element && this.element.keyboardElementId == this.keyboardElement.keyboardElementId) {
            //        if (!this.useTextArea && newValue && newValue.endsWith('\n')) {
            //            newValue = newValue.substr(0, newValue.length - 1);
            //            this.$store.commit('setKeyboardValue', newValue);
            //        }
            //        this.textValue = newValue;
            //        this.$emit('input', newValue);
            //    }
            //},
        },
        methods: {
            onInput(newValue) {
                //if (this.keyboardElement) {
                //    this.$store.commit('setKeyboardValue', newValue);
                //}
                if (this.type == 'number' && newValue && !isNaN(newValue)) {
                    if (newValue.indexOf(',') >= 0) newValue = newValue.replace(',', '.');
                    if (newValue.indexOf('.') >= 0)
                        newValue = parseFloat(newValue);
                    else
                        newValue = parseInt(newValue);

                    var changedValue = null;
                    if (this.min !== undefined && (isNaN(newValue) || newValue < this.min)) {
                        changedValue = this.min;
                    }
                    if (this.max !== undefined && newValue > this.max) {
                        changedValue = this.max;
                    }
                    if (changedValue !== null) {
                        newValue = null;
                        this.$nextTick(() => {
                            this.textValue = changedValue;
                        })
                    }
                }
                this.textValue = newValue;
                this.$emit('input', newValue);
            },
            focus() {
                if (!this.readOnly) {
                    if (this.position == 'right') {
                        if (this.useTextArea) {
                            this.$refs.rightTextArea.focus();
                        } else {
                            this.$refs.rightText.focus();
                        }
                    } else {
                        if (this.useTextArea) {
                            this.$refs.bottomTextArea.focus();
                        } else {
                            this.$refs.bottomText.focus();
                        }
                    }
                }
            },
            keydown(e) {
                this.$emit('keydown', e);
            },
            showKeyboard(e) {
                this.element = e.target;
                //if (this.keyboardElement) {
                //    this.$store.commit('setKeyboardValue', this.textValue);
                //    this.$store.commit('showKeyboard', this.element);
                //}
            },

        }
    };
</script>

<style>

    .control-text div.read-only {
        margin: 0.2rem 0;
        border-radius: 0.25rem;
        border: 1px solid grey;
        padding: 0.3rem 0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        flex: 1 1 auto;
        font-size: 0.9rem;
        min-width: 3rem;
        min-height: 2rem;
    }
</style>
