<template>
    <div class="home-counters">

        <control-select v-if="showSitesCombo" v-model="siteId" label="Show counters for site:" position="right"
                        id="selectSite" :options="getSiteOptions" size="sm" @changed="siteChanged" />

        <div class="counter-items">

            <div class="counter-item all-sites" v-if="showRAMSCountersAllSites">
                <div class="counter-title bg-dark text-light">RAMS Summary for All Sites</div>
                <div class="counter-text">
                    <table class="totals">
                        <tr>
                            <td class="label">Site</td>
                            <td class="label">Active employees</td>
                            <td class="label">Total RAMS</td>
                            <td class="label">Updated RAMS</td>
                            <td class="label">Expired RAMS</td>
                            <td class="label">Updated signatures</td>
                            <td class="label">Pending signatures</td>
                            <td class="label">Expired signatures</td>
                            <td class="label">In expired RAMS</td>
                        </tr>
                        <tr v-for="row in dataRamCountersAllSites" :key="row.siteId">
                            <td class="site-name">{{row.siteName}}</td>
                            <td class="value">{{row.activeEmployees}}</td>
                            <td class="value">{{row.totalRams}}</td>
                            <td class="value">{{row.updatedRams}}</td>
                            <td class="value">{{row.expiredRams}}</td>
                            <td class="value">{{row.updatedSignatures}}</td>
                            <td class="value">{{row.pendingSignatures}}</td>
                            <td class="value">{{row.expiredSignatures}}</td>
                            <td class="value">{{row.inExpiredRams}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="counter-item" v-if="showRAMSCounters">
                <div class="counter-title bg-dark text-light">RAMS Familiarization Training Summary</div>
                <div class="counter-text">
                    <table class="totals">
                        <tr>
                            <td class="label">Active employees:</td>
                            <td class="value">{{dataRamCounters.activeEmployees}}</td>
                        </tr>
                        <tr>
                            <td class="label">Total RAMS / TBT:</td>
                            <td class="value">{{dataRamCounters.totalRams}}</td>
                        </tr>
                        <tr>
                            <td class="label">Updated RAMS / TBT:</td>
                            <td class="value">{{dataRamCounters.updatedRams}}</td>
                        </tr>
                        <tr>
                            <td class="label">Expired RAMS / TBT:</td>
                            <td class="value">{{dataRamCounters.expiredRams}}</td>
                        </tr>
                        <tr>
                            <td class="label">Updated signatures:</td>
                            <td class="value">{{dataRamCounters.updatedSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label">Pending signatures:</td>
                            <td class="value">{{dataRamCounters.pendingSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label">Expired signatures:</td>
                            <td class="value">{{dataRamCounters.expiredSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label">In expired RAMS / TBT:</td>
                            <td class="value">{{dataRamCounters.inExpiredRams}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="counter-item" v-if="showRAMSCounters && pendingRams">
                <div class="pending-items-table">
                    <div class="counter-title pending-header small">Pending RAMS Familiarization Training Signatures</div>
                    <div class="counter-text pending-items">
                        <div :key="index" v-for="(item, index) in pendingRams" class="pending-item">
                            <div class="pending-item-department">
                                <span class="department">{{item.department}}</span>
                                <span class="pending">{{item.pending}}</span>
                            </div>
                            <div class="pending-item-employees">
                                <div :key="index" v-for="(employee, index) in item.employees" class="pending-item-employee">
                                    <span class="employee">{{employee.employee}}</span>
                                    <span class="pending">{{employee.pending}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pending-totals">
                        <span>Grand Total</span>
                        <span class="pending">{{totalPendingRams}}</span>
                    </div>
                </div>
            </div>
<!--
            <div class="counter-item" v-if="showRAMSCounters">
                <div class="counter-title">Procedures &amp; RAMS / TBT</div>
                <pie-chart :donut="true" :data="dataChartRams" :legend="false" :colors="colors" class="counter-graph" :width="graphWidth" :height="graphHeight" :library="libraryOptions"></pie-chart>
                <div class="counter-text">
                    <table class="legend">
                        <tr>
                            <td class="label tab0">Total number:</td>
                            <td class="value">{{dataRamCounters.totalRams}}</td>
                        </tr>
                        <tr>
                            <td class="label tab1">Updated:</td>
                            <td class="value">{{dataRamCounters.updatedRams}}</td>
                            <td><span class="dot color-green"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">Expired:</td>
                            <td class="value">{{dataRamCounters.expiredRams}}</td>
                            <td><span class="dot color-orange"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab0">Expiring next month:</td>
                            <td class="value">{{dataRamCounters.expiringRamsNextMonth}}</td>
                        </tr>
                    </table>
                </div>
            </div>
-->
            <div class="counter-item" v-if="showRAMSCounters">
                <div class="counter-title small">Required RAMS Familiarization Training Signatures</div>
                <pie-chart :donut="true" :data="dataChartRamSignatures" :legend="false" :colors="colors" class="counter-graph" :width="graphWidth" :height="graphHeight" :library="libraryOptions"></pie-chart>
                <div class="counter-text">
                    <table class="legend">
                        <tr>
                            <td class="label tab0">Total signatures:</td>
                            <td class="value">{{dataRamCounters.totalSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label tab1">Pending:</td>
                            <td class="value">{{dataRamCounters.pendingSignatures}}</td>
                            <td><span class="dot color-red"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">Signed:</td>
                            <td class="value">{{dataRamCounters.signed}}</td>
                        </tr>
                        <tr>
                            <td class="label tab2">Updated:</td>
                            <td class="value">{{dataRamCounters.updatedSignatures}}</td>
                            <td><span class="dot color-green"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab2">Expired:</td>
                            <td class="value">{{dataRamCounters.expiredSignatures}}</td>
                            <td><span class="dot color-orange"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab2">In expired RAMS / TBT:</td>
                            <td class="value">{{dataRamCounters.inExpiredRams}}</td>
                            <td><span class="dot color-yellow"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab0">Expiring next month:</td>
                            <td class="value">{{dataRamCounters.expiringNextMonth}}</td>
                        </tr>
                    </table>
                </div>
            </div>


            <div class="counter-item pending-items-table" v-if="showRAMSCounters">
                <div class="counter-title pending-header small">Pending RAMS Familiarization Training Validations</div>
                <div class="counter-text pending-items">
                    <div :key="index" v-for="(item, index) in pendingSopValidations" class="pending-item">
                        <div class="pending-item-department">
                            <span class="department">{{item.department}}</span>
                            <span class="pending">{{item.pending}}</span>
                        </div>
                        <div class="pending-item-employees">
                            <div :key="index" v-for="(employee, index) in item.employees" class="pending-item-employee">
                                <span class="employee">{{employee.employee}}</span>
                                <span class="pending">{{employee.pending}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pending-totals">
                    <span>Grand Total</span>
                    <span class="pending">{{totalPendingSopValidations}}</span>
                </div>
            </div>


            <!--<div class="counter-item" v-if="showRAMSCounters">
                <div class="counter-title">SOP State</div>
                <pie-chart :donut="true" :data="dataChartSopState" :legend="false" :colors="colors" class="counter-graph" :width="graphWidth" :height="graphHeight" :library="libraryOptions"></pie-chart>
                <div class="counter-text">
                    <table class="legend">
                        <tr>
                            <td class="label tab0">Total:</td>
                            <td class="value">{{dataSopState.total}}</td>
                        </tr>
                        <tr>
                            <td class="label tab1">Assigned:</td>
                            <td class="value">{{dataSopState.assigned}}</td>
                            <td><span class="dot color-yellow"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">In progress:</td>
                            <td class="value">{{dataSopState.inProgress}}</td>
                            <td><span class="dot color-orange"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">Pending validation:</td>
                            <td class="value">{{dataSopState.pendingValidation}}</td>
                            <td><span class="dot color-blue"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">Completed:</td>
                            <td class="value">{{dataSopState.completed}}</td>
                            <td><span class="dot color-green"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">Rejected:</td>
                            <td class="value">{{dataSopState.rejected}}</td>
                            <td><span class="dot color-red"></span></td>
                        </tr>
                    </table>
                </div>
            </div>-->

            <div class="counter-item" v-if="showSkillsCounters">
                <div class="counter-title bg-dark text-light">Skills Training Summary</div>
                <div class="counter-text">
                    <table class="totals">
                        <tr>
                            <td class="label">Active employees:</td>
                            <td class="value">{{dataSkillCounters.activeEmployees}}</td>
                        </tr>
                        <tr>
                            <td class="label">Total Skills Training:</td>
                            <td class="value">{{dataSkillCounters.totalSkills}}</td>
                        </tr>
                        <tr>
                            <td class="label">Updated signatures:</td>
                            <td class="value">{{dataSkillCounters.updatedSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label">Pending signatures:</td>
                            <td class="value">{{dataSkillCounters.pendingSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label">Expired signatures:</td>
                            <td class="value">{{dataSkillCounters.expiredSignatures}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="counter-item pending-items-table" v-if="showSkillsCounters && pendingSkills">
                <div class="counter-title pending-header">Pending Skills Training Signatures</div>
                <div class="counter-text pending-items">
                    <div :key="index" v-for="(item, index) in pendingSkills" class="pending-item">
                        <div class="pending-item-department">
                            <span class="department">{{item.department}}</span>
                            <span class="pending">{{item.pending}}</span>
                        </div>
                        <div class="pending-item-employees">
                            <div :key="index" v-for="(employee, index) in item.employees" class="pending-item-employee">
                                <span class="employee">{{employee.employee}}</span>
                                <span class="pending">{{employee.pending}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pending-totals">
                    <span>Grand Total</span>
                    <span class="pending">{{totalPendingSkills}}</span>
                </div>
            </div>


            <div class="counter-item" v-if="showSkillsCounters">
                <div class="counter-title">Required Skills Training Signatures</div>
                <pie-chart :donut="true" :data="dataChartSkillSignatures" :legend="false" :colors="colors" class="counter-graph" :width="graphWidth" :height="graphHeight" :library="libraryOptions"></pie-chart>
                <div class="counter-text">
                    <table class="legend">
                        <tr>
                            <td class="label tab0">Total signatures:</td>
                            <td class="value">{{dataSkillCounters.totalSignatures}}</td>
                        </tr>
                        <tr>
                            <td class="label tab1">Pending:</td>
                            <td class="value">{{dataSkillCounters.pendingSignatures}}</td>
                            <td><span class="dot color-red"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab1">Signed:</td>
                            <td class="value">{{dataSkillCounters.signed}}</td>
                        </tr>
                        <tr>
                            <td class="label tab2">Updated:</td>
                            <td class="value">{{dataSkillCounters.updatedSignatures}}</td>
                            <td><span class="dot color-green"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab2">Expired:</td>
                            <td class="value">{{dataSkillCounters.expiredSignatures}}</td>
                            <td><span class="dot color-orange"></span></td>
                        </tr>
                        <tr>
                            <td class="label tab0">Expiring next month:</td>
                            <td class="value">{{dataSkillCounters.expiringNextMonth}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>

        <div class="clear message" v-if="showRAMSCounters || showSkillsCounters">All data is referred to mandatory RAMS / SKILLs</div>

    </div>
</template>

<script type="text/javascript">

    import { mapState, mapGetters } from 'vuex';
    import stores from '../tools/stores';

    export default {
        name: 'home-counters',
        props: ['showAdmin'],
        data() {
            return {
                colors: [
                    '#AADD77', //green
                    '#FFB347', //orange
                    '#FF6961', //red
                    '#FFFF99', //yellow
                    '#779ECB'  //blue
                ],
                pendingRams: null,
                totalPendingRams: 0,
                pendingSkills: null,
                totalPendingSkills: 0,
                pendingSopValidations: null,
                totalPendingSopValidations: 0,
                homeCounters: [],
                siteId: 0,
            }
        },
        async mounted() {
            if (!this.isAdmin) this.siteId = this.getSiteId;
            this.loadData();
        },
        computed: {
            ...mapState('controls', ['chartsLibLoaded']),
            ...mapGetters('security', ['getUserSites', 'getSiteId']),
            isAdmin() {
                return (this.showAdmin !== undefined && this.showAdmin !== false && this.showAdmin !== 'false');
            },
            isAllSites() {
                return (this.siteId === 0);
            },
            showSitesCombo() {
                return (this.isAdmin && this.getSiteOptions.length > 1);
            },
            getSiteOptions() {
                var sites = [{ siteId: 0, siteName: 'All sites' }];
                for (var siteId in this.getUserSites) {
                    sites.push(this.getUserSites[siteId]);
                }
                var options = stores.getOptionsFromArray(sites, 'siteId', 'siteName');
                return options;
            },
            dataRamCountersAllSites() {
                var rows = [];
                for (var siteId in this.getUserSites) {
                    var site = this.getUserSites[siteId];
                    var counters = this.homeCounters[site.siteId] || {};
                    rows.push({
                        siteId: site.siteId,
                        siteName: site.siteName,
                        activeEmployees: counters[35],// ? counters[26] : 0,
                        totalRams: counters[30], // ? counters[21] : 0,
                        updatedRams: counters[33], // ? counters[24] : 0,
                        expiredRams: counters[31], // ? counters[22] : 0,
                        updatedSignatures: counters[4], // ? counters[4] : 0,
                        pendingSignatures: counters[1], // ? counters[1] : 0,
                        expiredSignatures: counters[3],// ? counters[3] : 0,
                        inExpiredRams: counters[5]// ? counters[5] : 0
                    });
                }
                return rows;
            },
            dataRamCounters() {
                return {
                    activeEmployees: this.homeCounters[35],
                    totalRams: this.homeCounters[30],
                    updatedRams: this.homeCounters[33],
                    expiredRams: this.homeCounters[31],
                    expiringRamsNextMonth: this.homeCounters[32],
                    totalSignatures: this.homeCounters[7],
                    signed: this.homeCounters[6],
                    updatedSignatures: this.homeCounters[4],
                    pendingSignatures: this.homeCounters[1],
                    expiredSignatures: this.homeCounters[3],
                    inExpiredRams: this.homeCounters[5],
                    expiringNextMonth: this.homeCounters[2],
                };
            },
            dataChartRams() {
                return {
                    'Updated': this.dataRamCounters.updatedRams,
                    'Expired': this.dataRamCounters.expiredRams
                };
            },
            dataChartRamSignatures() {
                return {
                    'Updated': this.dataRamCounters.updatedSignatures,
                    'Expired': this.dataRamCounters.expiredSignatures,
                    'Pending': this.dataRamCounters.pendingSignatures,
                    'In expired RAMS': this.dataRamCounters.inExpiredRams,
                };
            },
            dataSopState() {
                return {
                    total: this.homeCounters[10] + this.homeCounters[11] + this.homeCounters[12] + this.homeCounters[13] + this.homeCounters[14],
                    completed: this.homeCounters[13],
                    inProgress: this.homeCounters[11],
                    rejected: this.homeCounters[14],
                    assigned: this.homeCounters[10],
                    pendingValidation: this.homeCounters[12],
                };
            },
            dataChartSopState() {
                return {
                    'Completed': this.dataSopState.completed,
                    'In Progress': this.dataSopState.inProgress,
                    'Rejected': this.dataSopState.rejected,
                    'Assigned': this.dataSopState.assigned,
                    'Pending Validation': this.dataSopState.pendingValidation,
                };
            },
            dataSkillCounters() {
                return {
                    activeEmployees: this.homeCounters[35],
                    totalSkills: this.homeCounters[34],
                    totalSignatures: this.homeCounters[24],
                    signed: this.homeCounters[25],
                    updatedSignatures: this.homeCounters[23],
                    pendingSignatures: this.homeCounters[20],
                    expiredSignatures: this.homeCounters[22],
                    expiringNextMonth: this.homeCounters[21],
                };
            },
            dataChartSkillSignatures() {
                return {
                    'Updated': this.dataSkillCounters.updatedSignatures,
                    'Expired': this.dataSkillCounters.expiredSignatures,
                    'Pending': this.dataSkillCounters.pendingSignatures,
                };
            },
            libraryOptions() {
                return {
                    'chartArea': { 'width': '100%', 'height': '80%' },
                    'pieSliceTextStyle': { 'color': '#303030' },
                };
            },
            graphWidth() {
                return '13rem';
            },
            graphHeight() {
                return '13rem';
            },
            showRAMSCounters() {
                return (this.chartsLibLoaded && !this.isAllSites);
            },
            showSkillsCounters() {
                return (this.chartsLibLoaded && !this.isAllSites);
            },
            showRAMSCountersAllSites() {
                return (this.chartsLibLoaded && this.isAllSites);
            },
        },
        methods: {
            async loadData() {
                if (this.siteId > 0) {
                    this.homeCounters = await this.$store.dispatch('controls/getHomeCountersInfo', { siteId: this.siteId, isAdmin: this.isAdmin });
                    this.loadPendingRams();
                    this.loadPendingSkills();
                    this.loadPendingSopValidations();
                } else if (this.isAdmin) {
                    this.homeCounters = await this.$store.dispatch('controls/getHomeCountersInfoAllSites');
                    this.pendingRams = null;
                    this.totalPendingRams = null;
                    this.pendingSkills = null;
                    this.totalPendingSkills = null;
                    this.pendingSopValidations = null;
                    this.totalPendingSopValidations = null;
                }
            },
            async loadPendingRams() {
                var result = await this.loadPendingItems('controls/getHomePendingRams');
                this.pendingRams = result.pendingItems;
                this.totalPendingRams = result.totals;
            },
            async loadPendingSkills() {
                var result = await this.loadPendingItems('controls/getHomePendingSkills');
                this.pendingSkills = result.pendingItems;
                this.totalPendingSkills = result.totals;
            },
            async loadPendingSopValidations() {
                var result = await this.loadPendingItems('controls/getHomePendingSopValidations');
                this.pendingSopValidations = result.pendingItems;
                this.totalPendingSopValidations = result.totals;
            },
            async loadPendingItems(actionName) {
                var departments = {};
                var pendingItems = [];
                var totals = 0;
                var items = await this.$store.dispatch(actionName, { siteId: this.siteId, isAdmin: this.isAdmin });
                for (var i in items) {
                    var item = items[i];
                    var department = departments[item.department];
                    if (!department) {
                        department = {
                            department: item.department,
                            pending: 0,
                            employees: []
                        };
                        departments[item.department] = department;
                        pendingItems.push(department);
                    }
                    department.employees.push({
                        employee: item.employee,
                        pending: item.pending
                    });
                    department.pending += item.pending;
                    totals += item.pending;
                }
                pendingItems = pendingItems.sort(function (a, b) {
                    return b.pending - a.pending;
                });
                return { pendingItems, totals };
            },
            siteChanged() {
                this.loadData();
            },
        }
    }
</script>

<style>

    .home-counters .control-select {
        margin-top: 1rem;
    }

    .home-counters .control-select .mr-auto.col {
        margin-right: 0!important;
        flex-grow: unset;
        padding-right: 0;
    }

    .home-counters .control-select .mr-auto.col .control-label {
        width: 10rem;
    }

    .counter-items {
        margin-left: 2rem;
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: center;
    }

        .counter-item {
            display: block;
            margin-top: 1rem;
            margin-right: 1rem;
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.125);
            border-radius: .25rem;
            width: 16rem;
            height: 27rem;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            position: relative;
        }

        .counter-item.all-sites {
            width: auto;
            height: auto;
            min-width: 45rem;
        }

    .counter-item.all-sites table {
        width: calc(100% - 2rem);
    }

        .counter-item.all-sites .counter-text .label {
            text-align: center;
            background-color: lightgray;
        }

            .counter-item.all-sites .site-name {
                background-color: #d3d3d359;
                font-weight: 700;
            }

            .counter-item .totals {
                width: 14rem;
                margin: 1rem;
            }

                .counter-item .totals td {
                    border: 0.1rem solid lightgrey;
                    padding: 0.4rem;
                }

            .counter-item .counter-graph {
                width: 12rem;
                margin: 0 auto;
            }

            .counter-item .counter-text {
                vertical-align: middle;
                font-size: small;
                text-align: center;
            }

                .counter-item .counter-text .legend {
                    margin: 0 0 0 1rem;
                }

                    .counter-item .counter-text .legend td {
                        padding-right: 0.5rem;
                    }

                .counter-item .counter-text .label {
                    font-weight: 700;
                    text-align: left;
                }

                .counter-item .counter-text .tab0 {
                    padding-left: 0;
                }

                .counter-item .counter-text .tab1 {
                    padding-left: 1rem;
                }

                .counter-item .counter-text .tab2 {
                    padding-left: 2rem;
                }

                .counter-item .counter-text .value {
                    font-weight: 300;
                    text-align: right;
                }

            .counter-item .counter-title {
                font-weight: 700;
                font-size: 1rem;
                text-align: center;
                padding: 0.3rem 1rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-content: center;
                flex-direction: column;
                border-radius: 0.25rem 0.25rem 0 0;
            }

                .counter-item .counter-title.small {
                    font-size: 0.9rem;
                }

                .counter-item .counter-title.no-graph {
                    /*        margin: 0 0 1rem 0;
    */ /*        height:3rem;
    */ /*align-items: center!important;*/
                }

        .dot {
            height: 0.75rem;
            width: 0.75rem;
            border-radius: 50%;
            display: inline-block;
        }

        .color-red {
            background-color: #FF6961;
        }

        .color-green {
            background-color: #AADD77;
        }

        .color-orange {
            background-color: #FFB347;
        }

        .color-yellow {
            background-color: #FFFF99;
        }

        .color-blue {
            background-color: #779ECB;
        }

        .message {
            margin-left: 2rem;
            font-size: x-small;
            text-align: left;
            font-style: italic;
        }





        .counter-item .counter-title.pending-header {
            /*        margin-bottom: 0;
    */ background-color: lightgray;
            /*        height: auto;
    */
        }


        .pending-items-table {
            /*        display: flex;
            flex-direction: column;
            align-content: space-between;*/
        }

    .pending-items {
        overflow-y: auto;
        height: 22.75rem;
        position: relative;
    }

        .pending-item {
            position: relative;
            text-align: left;
        }

        .pending-item-department {
            background-color: #d3d3d359;
            padding: 0 0.5rem;
            font-weight: 600;
        }

        .pending-item-employee {
            border-bottom: solid #d3d3d359 1px;
            padding: 0 1rem;
        }

        .pending-item .pending,
        .pending-totals .pending {
            position: absolute;
            right: 0.5rem;
        }

        .pending-items::-webkit-scrollbar {
            width: 10px;
        }

        .pending-items::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        .pending-items::-webkit-scrollbar-thumb {
            background: #888;
        }

            .pending-items::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

    .pending-totals {
        background-color: lightgray;
        font-weight: bold;
        text-align: left;
        padding: 0 0.5rem;
        position: absolute;
        bottom: 0;
        border-radius: 0 0 0.25rem 0.25rem;
        width: 16rem;
    }
</style>
