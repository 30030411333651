var tools = $virtus.tools;
var api = tools.api;
var stores = tools.stores;

const getDefaultState = () => {
    return {
        LEAVER_STATE_ID: 1,
    }
}



function completeArea(area) {
    area.search = area.name.toLocaleLowerCase();
    return area;
}

function completeDepartment(department) {
    department.search = department.name.toLocaleLowerCase();
    return department;
}

function completeEmployee(employee) {
    if (employee) {
        employee.search = (employee.name + ' ' + employee.surname + ' ' + employee.jobPosition + ' ' + employee.department + ' ' + employee.area + ' ' + employee.shift).toLocaleLowerCase();
        //employee.searchName = employee.name.toLocaleLowerCase();
        //employee.searchJobPosition = employee.jobPosition.toLocaleLowerCase();
        if (employee.shiftId == 1) { //Leaver
            employee.email = null;
            employee.profileId = 0;
            employee.profileName = null;
        }
    }
    return employee;
}

function completeJobPosition(jobPosition) {
    jobPosition.search = jobPosition.name.toLocaleLowerCase();
    return jobPosition;
}

function completeShift(shift) {
    shift.search = shift.name.toLocaleLowerCase();
    return shift;
}



export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
    },
    mutations: {
    },
    actions: {

        async getAreas() {
            var items = await api.get('/api/areas');
            items = items.map(area => completeArea(area));
            return items;
        },
        async insertArea(store, data) {
            var item = await api.post('/api/areas', data);
            item = completeArea(item);
            return item;
        },
        async updateArea(store, data) {
            var result = await api.put('/api/areas/' + data.id, data);
            completeArea(data);
           return result;
        },
        async getOptionsFromAreas({ dispatch }) {
            var items = await dispatch('getAreas');
            var options = stores.getOptionsFromArray(items, 'id', 'name');
            return options;
        },


        async getDepartments() {
            var items = await api.get('/api/departments');
            items = items.map(department => completeDepartment(department));
            return items;
        },
        async insertDepartment(store, data) {
            var item = await api.post('/api/departments', data);
            item = completeDepartment(item);
            return item;
        },
        async updateDepartment(store, data) {
            var result = await api.put('/api/departments/' + data.id, data);
            completeDepartment(data);
            return result;
        },
        async getOptionsFromDepartments({ dispatch }) {
            var items = await dispatch('getDepartments');
            var options = stores.getOptionsFromArray(items, 'id', 'name');
            return options;
        },



        async getEmployees() {
            var options = {
                dateFields: ['endDate'],
            };
            var items = await api.get('/api/employees', options);
            items = items.map(employee => completeEmployee(employee));
            return items;
        },
        async insertEmployee(store, data) {
            var options = {
                dateFields: ['endDate'],
            };
            var item = await api.post('/api/employees', data, options);
            item = completeEmployee(item);
            return item;
        },
        async updateEmployee(store, data) {
            var options = {
                dateFields: ['endDate'],
                returnResult: true,
            };
            var item = await api.put('/api/employees/' + data.id, data, options);
            item = completeEmployee(item);
            return item;
        },
        async updateEmployeeLeave(store, data) {
            await api.put('/api/employees/' + data.id + '/leave', data);
        },
        async getActiveEmployees({ state, dispatch }) {
            var items = await dispatch('getEmployees');
            items = items.filter(e => {
                return (e.shiftId != state.LEAVER_STATE_ID);
            });
            return items;
        },
        async getOptionsFromEmployees({ dispatch }) {
            var items = await dispatch('getEmployees');
            var options = stores.getOptionsFromArray(items, 'id', 'fullName');
            return options;
        },
        async getOptionsFromActiveEmployees({ dispatch }) {
            var items = await dispatch('getActiveEmployees');
            var options = stores.getOptionsFromArray(items, 'id', 'fullName');
            return options;
        },
        async getOptionsFromManagers() {
            var items = await api.get('/api/employees/managers');
            var options = stores.getOptionsFromArray(items, 'id', 'fullName');
            return options;
        },


        async getJobPositions() {
            var items = await api.get('/api/jobpositions');
            items = items.map(jobPosition => completeJobPosition(jobPosition));
            return items;
        },
        async insertJobPosition(store, data) {
            var item = await api.post('/api/jobpositions', data);
            item = completeJobPosition(item);
            return item;
        },
        async updateJobPosition(store, data) {
            var result = await api.put('/api/jobpositions/' + data.id, data);
            completeJobPosition(data);
            return result;
        },
        async deleteJobPosition(store, data) {
            var result = await api.delete('/api/jobpositions/' + data.id);
            return result;
        },
        async sortedJobPositions({ dispatch }) {
            var items = await dispatch('getJobPositions');
            items.sort(function (a, b) { return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()); });
            return items;
        },
        async getOptionsFromJobPositions({ dispatch }) {
            var items = await dispatch('getJobPositions');
            var options = stores.getOptionsFromArray(items, 'id', 'name');
            return options;
        },
        async getJobPositionsDictionary({ dispatch }) {
            var items = await dispatch('getJobPositions');
            items = stores.convertArrayToDictionary(items);
            return items;
        },



        async getShifts() {
            var items = await api.get('/api/shifts');
            items = items.map(shift => completeShift(shift));
            return items;
        },
        async getShiftsNoLeaver() {
            var items = await api.get('/api/shifts');
            items = items.filter(x => x.id > 1);
            items = items.map(shift => completeShift(shift));
            return items;
        },        
        async insertShift(store, data) {
            var item = await api.post('/api/shifts', data);
            item = completeShift(item);
            return item;
        },
        async updateShift(store, data) {
            var result = await api.put('/api/shifts/' + data.id, data);
            completeShift(data);
            return result;
        },
        async getOptionsFromShifts({ dispatch }) {
            var items = await dispatch('getShifts');
            var options = stores.getOptionsFromArray(items, 'id', 'name');
            return options;
        },


    }
};
