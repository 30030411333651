import { DateTime, Info, Duration } from 'luxon';

let dates = {
    now() {
        return DateTime.now();
    },
    getToday() {
        return this.now().startOf('day');
    },
    getDateTime(date) {
        var result = null;
        if (date instanceof DateTime) {
            result = date;
        } else if (date instanceof Date) {
            result =  DateTime.fromJSDate(date);
        } else if (date instanceof String || typeof date === 'string') {
            if (date.indexOf('/') > 0) {
                if (date.indexOf(':') > 0) {
                    result = DateTime.fromFormat('dd/MM/yyyy hh:mm');
                } else {
                    result = DateTime.fromFormat('dd/MM/yyyy');
                }
            } else {
                result = DateTime.fromISO(date);
            }
        }
        return result;
    },
    formattedTime(seconds) {
        const duration = Duration.fromObject({ seconds: (seconds ? seconds : 0) });
        const output = duration.toFormat('hh:mm:ss');
        return output;
    },
    isValid(date) {
        var dateTime = this.getDateTime(date);
        return (dateTime ? dateTime.isValid : false);
    },
    //getISODate(date) {
    //    var dateTime = this.getDateTime(date);
    //    return (dateTime ? dateTime.toISODate() : null);
    //},
    getMonthOptions(addAllMonths) {
        var months = Info.months('long', { locale: 'en' }).map((x, i) => { return { value: i + 1, text: x }; });
        if (addAllMonths) months.unshift({ value: 0, text: 'All months' });
        return months;
    },
    getYearOptions() {
        var years = [];
        var today = DateTime.now();
        for (var i = 2020; i <= today.year; i++)
            years.push({ value: i, text: i });
        return years;
    },
    format(date, format, plus, minus) {
        var dateTime = this.getDateTime(date);
        if (plus && dateTime) dateTime = dateTime.plus(plus);
        if (minus && dateTime) dateTime = dateTime.minus(minus);
        return (dateTime ? dateTime.toFormat(format) : '');
    },
    formatDate(date, plus, minus) {
        return this.format(date, 'dd/MM/yyyy', plus, minus);
    },
    formatDateTime(date) {
        var dateTime = (date ? this.getDateTime(date) : null);
        return (dateTime ? dateTime.setZone('Europe/London').toFormat('dd/MM/yyyy HH:mm') : '');
    },
    formatDateTimeLong(date) {
        var dateTime = (date ? this.getDateTime(date) : null);
        return (dateTime ? dateTime.setZone('Europe/London').toFormat('dd LLLL yyyy, HH:mm:ss') : '');
    },
    isFutureDate(date, plus, minus) {
        var dateTime = this.getDateTime(date);
        if (plus && dateTime) dateTime = dateTime.plus(plus);
        if (minus && dateTime) dateTime = dateTime.minus(minus);
        return (dateTime ? dateTime > DateTime.now() : false);
    },
    isPastDate(date, plus, minus) {
        var dateTime = this.getDateTime(date);
        if (plus && dateTime) dateTime = dateTime.plus(plus);
        if (minus && dateTime) dateTime = dateTime.minus(minus);
        return (dateTime ? dateTime < DateTime.now() : false);
    },
    convertDateToServer(date) {
        var dateTime = this.getDateTime(date);
        return (dateTime ? dateTime.toISODate() : null);
    },
    convertDateTimeToServer(date) {
        var dateTime = this.getDateTime(date);
        return (dateTime ? dateTime.toUTC().toISO() : null);
    },
    convertArrayDatesToServer(array, fieldNames) {
        var newArray = array.slice();
        for (var i = 0; i < newArray.length; i++) {
            var item = newArray[i];
            for (var j = 0; j < fieldNames.length; j++) {
                var fieldName = fieldNames[j];
                item[fieldName] = this.convertDateToServer(item[fieldName]);
            }
        }
        return newArray;
    },
    convertObjectDatesToServer(source, fieldNames) {
        var newObject = { ...source };
        for (var i = 0; i < fieldNames.length; i++) {
            var fieldName = fieldNames[i];
            newObject[fieldName] = this.convertDateToServer(newObject[fieldName]);
        }
        return newObject;
    },
    convertObjectDateTimesToServer(source, fieldNames) {
        var newObject = { ...source };
        for (var i = 0; i < fieldNames.length; i++) {
            var fieldName = fieldNames[i];
            newObject[fieldName] = this.convertDateTimeToServer(newObject[fieldName]);
        }
        return newObject;
    },
    convertObjectDateTimesFromServer(source, fieldNames) {
        var newObject = { ...source };
        for (var i = 0; i < fieldNames.length; i++) {
            var fieldName = fieldNames[i];
            newObject[fieldName] = this.convertDateTimeFromServer(newObject[fieldName]);
        }
        return newObject;
    },
    convertDateFromServer(date) {
        return this.convertDateTimeFromServer(date);
    },
    convertDateTimeFromServer(date) {
        return (date ? DateTime.fromISO(date) : null);
    },
    //convertToDate(date) {
    //    return (date ? new Date(date) : null);
    //},
    convertArrayDatesFromServer(array, fieldNames) {
        var newArray = array.slice();
        for (var i = 0; i < newArray.length; i++) {
            var item = newArray[i];
            for (var j = 0; j < fieldNames.length; j++) {
                var fieldName = fieldNames[j];
                item[fieldName] = this.convertDateFromServer(item[fieldName]);
            }
        }
        return newArray;
    },

    convertObjectDatesFromServer(source, fieldNames) {
        var newObject = { ...source };
        for (var i = 0; i < fieldNames.length; i++) {
            var fieldName = fieldNames[i];
            newObject[fieldName] = this.convertDateFromServer(newObject[fieldName]);
        }
        return newObject;
    },
};

export default dates;
