<template>
    <div :class="{ 'signatures-skills': true, 'read-only': !hasWriteRights }">
        <h1 class="header-title">Skill Signatures</h1>

        <div class="header-items-bar">

            <b-input-group size="sm" prepend="Filter" class="signature-filter">
                <b-form-input size="sm" v-model="skillFilterText" placeholder="skill" title="enter skill filter" @keyup.enter.native="filterClick" />
                <b-form-input size="sm" v-model="employeeFilterText" placeholder="employee" title="enter employee filter" @keyup.enter.native="filterClick" />
                <b-form-input size="sm" v-model="jobPositionFilterText" placeholder="job position" title="enter job position filter" @keyup.enter.native="filterClick" />
                <b-form-input size="sm" v-model="departmentFilterText" placeholder="department" title="enter department filter" @keyup.enter.native="filterClick" />
                <b-input-group-append>
                    <b-btn @click.stop="filterClick">Go</b-btn>
                </b-input-group-append>
            </b-input-group>

            <b-form-checkbox id="showLeavers" v-model="showLeavers" :value="true" :unchecked-value="false" @change="showLeaversClick">Show leavers</b-form-checkbox>

            <div class="signature-types">
                <table>
                    <tr>
                        <td class="valid-signature">Signed</td>
                        <td class="required-no-valid-signature">Mandatory not signed or expired</td>
                        <!--<td class="recommended-no-valid-signature">Recommended not signed or expired</td>-->
                        <td class="not-required-signature">Not required</td>
                    </tr>
                </table>
            </div>

        </div>

        <div class="employee-assignments-message-text">
            <span v-if="hasWriteRights">Click a cell to edit the expiration date.</span>
            Employee assignments are marked with 'e' at bottom-right corner.
            Click cells on Leave column to manage leaves.
        </div>

        <div id="signatures-table" class="fixed-table-container">
            <table>
                <thead>
                    <tr class="header even-row">
                        <th v-for="cell in matrix.header" :key="'h_' + cell.id" :class="cell.css" v-html="cell.html"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in matrix.rows" :key="'r_' + row.id" :class="row.css">
                        <td v-for="cell in row.cells" :key="'c_' + cell.id" @click.stop="clickCell(cell)" :class="cell.css" v-html="cell.html"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <b-input-group size="sm" prepend="Page size" class="table-footer">
            <b-form-select id="pageSize" size="sm" :options="pageOptions" v-model="perPage" @change="paginationChanged" />
            <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" @change="paginationChanged" />
            <div class="footer-span"><span v-if="hasWriteRights && itemsChangedCount > 0">Skill signatures changed: {{itemsChangedCount}}</span></div>
            <button v-if="hasWriteRights" type="button" class="btn btn-secondary btn-sm" @click="discardChanges" :disabled="itemsChangedCount == 0">Discard changes</button>
            <button v-if="hasWriteRights" type="button" class="btn btn-primary btn-sm" @click="saveChanges" :disabled="itemsChangedCount == 0">Save changes</button>
        </b-input-group>

        <simple-modal ref="signature" :key="'enter-dates'" :headerTitle="hasWriteRights ? 'Enter skill signature' : 'Show skill signature'" dialog-class="signatures-skills">
            <template slot="modal-content">
                <control-date label="Valid From Date" placeholder="Pick a valid from date" v-model="editValidFromDate" :read-only="!hasWriteRights"></control-date>
                <control-date label="Expiration Date" placeholder="Pick an expiration date" v-model="editExpirationDate" :has-errors="showErrors && editExpirationDate && !isFutureDate" :read-only="!hasWriteRights"></control-date>
                <div v-if="showErrors && editExpirationDate && !isFutureDate" class="error">The expiration date can't be in the past.</div>
                <br />
                <file-upload v-if="hasWriteRights" text="Drag your skill signature file here <br/> or click to browse" :initialFile="filePopupFilename" @fileSelected="fileSelected" @fileRemoved="removeFile" confirmDelete="Delete skill certificate"></file-upload>
                <div v-else>{{ filePopupFilename ? filePopupFilename : "No file uploaded"}}</div>
            </template>
            <template slot="modal-buttons">
                <button v-if="showDownloadFile" type="button" class="btn btn-secondary" @click="openFile">Open file</button>
                <button v-if="showDownloadFile" type="button" class="btn btn-secondary mr-auto" @click="downloadFile">Download file</button>
                <button v-if="hasWriteRights" type="button" class="btn btn-secondary" @click="cancelSignature">Cancel</button>
                <button type="button" class="btn btn-primary" @click="saveSignature">{{ hasWriteRights ? "Save" : "Close" }}</button>
            </template>
        </simple-modal>

        <simple-modal ref="updateLeave" :key="'update-leave'" headerTitle="Update employee leave" dialog-class="signatures-update-leave">
            <template slot="modal-content">
                <control-checkbox-group v-model="leave" :options="leaveOptions" :label="'Select leave for the employee \'' + employeeName + '\':'"></control-checkbox-group>
            </template>
            <template slot="modal-buttons">
                <button type="button" class="btn btn-secondary" @click="cancelUpdateLeave">Cancel</button>
                <button type="button" class="btn btn-primary" @click="updateLeave">Update</button>
            </template>
        </simple-modal>
    </div>
</template>
<script>
    //import { mapState } from 'vuex';
    import 'core-js/web/immediate';
    import models from '../shared/models.js';
    export default {
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                pageOptions: [5, 10, 20, 50, 100],
                matrix: { header: [], rows: [] },
                itemsChangedCount: 0,
                editValidFromDate: null,
                editExpirationDate: null,
                editItem: null,
                showErrors: false,
                skillFilterText: '',
                employeeFilterText: '',
                jobPositionFilterText: '',
                departmentFilterText: '',
                skillFilter: '',
                employeeFilter: '',
                jobPositionFilter: '',
                departmentFilter: '',
                showLeavers: false,
                changedItems: {},
                employeesAssignments: {},
                filePopup: null,
                filePopupFilename: null,
                file: null,
                fileRemoved: false,
                showDownloadFile: false,
                assignmentLevels: [],
                skills: [],
                sortedSkills: [],
                signatures: [],
                hasWriteRights: false,
                //For update leave popup
                leave: null,
                employeeId: null,
                employeeName: null,
                leaveOptions: [{ value: null, text: 'No leave'}, { value: 'LTS', text: 'Long term sick leave'}, { value: 'MT', text: 'Maternity leave'}, { value: 'PL', text: 'Paternity leave'}],
            }
        },
        async created() {
            var self = this;
            window.showEmployeeLeavePopup = (employeeId, employeeName, leave) => {
                self.employeeId = employeeId;
                self.employeeName = employeeName;
                self.leave = leave;
                self.$refs.updateLeave.show();
            };
            this.hasWriteRights = await this.checkUserHasWriteRights();
            await Promise.all([
                this.$store.dispatch('getAssignmentLevels'),
                this.$store.dispatch('skills/getSkillsDictionary'),
                this.$store.dispatch('skills/getSkills'),
                this.loadSignatures(),
            ]).then((values) => {
                this.assignmentLevels = values[0];
                this.skills = values[1];
                this.sortedSkills = values[2];
                this.signatures = values[3];
                this.loadMatrix();
            });
        },
        beforeRouteLeave(to, from, next) {
            $virtus.tools.changes.beforeRouteLeave(to, from, next, this.itemsChangedCount > 0);
        },
        computed: {
            //...mapState(['leaverStateId']),
            isFutureDate() {
                return $virtus.tools.dates.isFutureDate(this.editExpirationDate);
            },
            totalRows() {
                return this.filteredSkills.length;
            },
            first() {
                return 1 + (this.currentPage - 1) * this.perPage;
            },
            paginatedSkills() {
                var skills = [];
                if (this.filteredSkills && this.filteredSkills.length > 0) {
                    skills = this.filteredSkills.slice(this.first - 1, this.first + this.perPage - 1);
                }
                return skills;
            },
            filteredSkills() {
                var skills = this.sortedSkills;
                var skillFilter = this.skillFilter;
                if (skillFilter) {
                    skills = skills.filter(r => { return r.search.includes(skillFilter); });
                }
                return skills;
            },
            filteredEmployees() {
                var employees = this.signatures;
                var jobPositionFilter = this.jobPositionFilter;
                var departmentFilter = this.departmentFilter;
                var employeeFilter = this.employeeFilter;
                if (jobPositionFilter || departmentFilter || employeeFilter || !this.showLeavers) {
                    employees = employees.filter(e => {
                        return (!employeeFilter || e.searchName.includes(employeeFilter))
                            && (!jobPositionFilter || e.searchJobPosition.includes(jobPositionFilter))
                            && (!departmentFilter || e.searchDepartment.includes(departmentFilter))
                            && (this.showLeavers || e.shiftId != $virtus.config.LEAVER_SHIFT_ID);
                    });
                }
                return employees;
            },
        },
        methods: {
            async loadSignatures() {
                var items = await this.$store.dispatch('skills/getFullSkillSignatures', { showLeavers: this.showLeavers });
                items = items.map((item) => {
                    if (item) {
                        item.searchName = (item.name ? item.name.toLocaleLowerCase() : '');
                        item.searchJobPosition = (item.jobPosition ? item.jobPosition.toLocaleLowerCase() : '');
                        item.searchDepartment = (item.department ? item.department.toLocaleLowerCase() : '');
                    }
                    return item;
                });
                return items;
            },
            async updateLeave() {
                await this.$store.dispatch('admin/updateEmployeeLeave', { id: this.employeeId, leave: this.leave });
                var employee = this.signatures.find(x => x.id == this.employeeId);
                employee.leave = this.leave;
                this.loadMatrix();
                $virtus.tools.messages.showMessage('Success!', 'The employee leave has been saved successfully.');
                this.cancelUpdateLeave();
            },

            cancelUpdateLeave() {
                this.employeeId = null;
                this.employeeName = null;
                this.leave = null;
                this.$refs.updateLeave.hide();
            },
            async clickCell(item) {
                //if (!this.hasWriteRights) return;
                if (!item.isHeader) {
                    this.showErrors = false;
                    this.editItem = item;
                    this.editValidFromDate = item.validFromDate;
                    this.editExpirationDate = item.expirationDate;
                    if (typeof item.hasFile === 'undefined') {
                        item.hasFile = await this.$store.dispatch('skills/checkIfSkillSignatureFileExists', item);
                    }
                    var changedItem = this.changedItems[item.id];
                    var showFile = (!changedItem && item.hasFile) || (changedItem && changedItem.renameTempFile) || (changedItem && item.hasFile && !changedItem.removeFile);
                    this.filePopupFilename = (showFile ? this.getSkillSignatureFilename(item) : '');
                    this.showDownloadFile = (item.hasFile && (!changedItem || (changedItem && !changedItem.renameTempFile && !changedItem.removeFile)));
                    this.$refs.signature.show();
                }
            },
            getSkillSignatureFilename(signature) {
                var skill = this.skills[signature.skillId];
                var employee = this.signatures.find(x => x.id == signature.employeeId);
                return 'SkillCertificate_' + employee.name + '_' + skill.description + '.pdf';
            },
            discardChanges() {
                this.changedItems = {};
                this.itemsChangedCount = 0;
                this.loadMatrix();
                this.showChangesDiscarded();
            },
            showSaveOk() {
                $virtus.tools.messages.showMessage('Success!', 'The skill signatures have been saved successfully.');
            },
            showChangesDiscarded() {
                $virtus.tools.messages.showMessage('Changes discarded', 'All changes have been discarded.');
            },
            async saveChanges() {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    let result = await this.$store.dispatch('skills/updateSkillSignatures', changedItems);
                    if (result) {
                        this.showSaveOk();
                        this.changedItems = {};
                        this.itemsChangedCount = 0;
                        this.signatures = await this.loadSignatures();
                        this.loadMatrix();
                    }
                }
            },
            async showLeaversClick() {
                var self = this;
                setImmediate(() => {
                    self.loadSignatures().then((value) => {
                        self.signatures = value;
                        self.loadMatrix();
                    });
                }, 0);
            },
            filterClick() {
                this.currentPage = 1;
                this.skillFilter = this.skillFilterText.toLocaleLowerCase();
                this.jobPositionFilter = this.jobPositionFilterText.toLocaleLowerCase();
                this.departmentFilter = this.departmentFilterText.toLocaleLowerCase();
                this.employeeFilter = this.employeeFilterText.toLocaleLowerCase();
                this.loadMatrix();
            },
            paginationChanged() {
                this.loadMatrix();
            },
            async saveSignature() {
                var self = this;
                if (!this.hasWriteRights) {
                    this.$refs.signature.hide();
                    return;
                }
                this.showErrors = true;
                //Only if popup is valid (if there is expiration date, it is in the future)
                if (!this.editExpirationDate || this.isFutureDate) {
                    this.$refs.signature.hide();
                    if (!this.editExpirationDate && !this.editValidFromDate && (this.editItem.expirationDate || this.editItem.validFromDate)) {
                        await $virtus.tools.messages.confirm('Are you sure?', 'You are going to delete the signature, please confirm the action.', true).then(value => {
                            if (value && value.isConfirmed) {
                                self.changeEditItem();
                            }
                        });
                    } else {
                        await this.changeEditItem();
                    }
                }
            },
            cancelSignature() {
                this.$refs.signature.hide();
            },
            async changeEditItem() {
                var item = this.editItem;
                var validFromDate = this.editValidFromDate;
                var expirationDate = this.editExpirationDate;
                item.expirationDate = expirationDate;
                item.validFromDate = validFromDate;
                models.refreshComputedSkillSignatureFields(item);
                item.html = item.text;
                item.css = 'clickable ' + (item.isEmployeeAssignment ? 'employee-assignment ' : '')
                    + models.getSkillSignatureClass(item.assignmentLevelId, validFromDate, expirationDate);
                //Set assignment state
                var changedItem = this.changedItems[item.id];
                var changed = (expirationDate != item.originalExpirationDate || validFromDate != item.originalValidFromDate
                    || this.file || (this.fileRemoved && item.hasFile));
                if (this.file) {
                    //Upload temp file
                    var data = {
                        employeeId: item.employeeId,
                        skillId: item.skillId,
                        file: this.file
                    };
                    await this.$store.dispatch('skills/saveTempSkillSignatureFile', data);
                }
                //Update / create / delete changedItem
                if (changed && changedItem) {
                    //Already changed, update
                    changedItem.validFromDate = validFromDate;
                    changedItem.expirationDate = expirationDate;
                    changedItem.renameTempFile = !!this.file;
                    changedItem.removeFile = this.fileRemoved;
                } else if (!changed && changedItem) {
                    //Not changed, delete changed item
                    this.itemsChangedCount--;
                    delete this.changedItems[item.id];
                } else if (changed && !changedItem) {
                    //Changed now, create changed item
                    this.itemsChangedCount++;
                    this.changedItems[item.id] = {
                        id: item.id,
                        skillId: item.skillId,
                        employeeId: item.employeeId,
                        validFromDate: validFromDate,
                        expirationDate: expirationDate,
                        localValidFromDate: validFromDate, //Store date, other date is changed when sent to server
                        localExpirationDate: expirationDate, //Store date, other date is changed when sent to server
                        text: item.text,
                        renameTempFile: !!this.file,
                        removeFile: this.fileRemoved,
                        trainingDate: item.trainingDate,
                    };
                } //Else Not changed, not changed item: do nothing
                //Display changed item
                if (changed) {
                    item.html += '<span class="note"></span>';
                }
                if (item.trainingDate) {
                    item.html = '<div class="with-training-date">' + item.html + '<span class="training-date">' + $virtus.tools.dates.format(trainingDate, 'dd/MM') + '</span></div>';
                }
            },
            downloadFile() {
                this.$store.dispatch('skills/getSkillSignatureFile', { employeeId: this.editItem.employeeId, skillId: this.editItem.skillId, download: true });
            },
            openFile() {
                this.$store.dispatch('skills/getSkillSignatureFile', { employeeId: this.editItem.employeeId, skillId: this.editItem.skillId, download: false });
            },
            fileSelected(file) {
                this.fileRemoved = false;
                this.file = file;
                this.filePopupFilename = this.getSkillSignatureFilename(this.editItem);
                this.showDownloadFile = false;
                return this.filePopupFilename;
            },
            removeFile() {
                this.fileRemoved = true;
                this.file = null;
                this.filePopupFilename = '';
                this.showDownloadFile = false;
            },
            getEmployeeLeaveTitle(employee) {
                switch (employee.leave) {
                    case 'LTS': return 'Long term sick leave';
                    case 'MT': return 'Maternity leave';
                    case 'PL': return 'Paternity leave';
                    default: return '';
                }
            },
            loadMatrix() {
                var self = this;
                $virtus.tools.loader.show();
                var matrix = { header: [], rows: [] };
                var skills = self.paginatedSkills;
                var employees = self.filteredEmployees;
                //Create header
                matrix.header.push({
                    id: 0, css: 'head-col-label', html: '<div><span>Employee</span><span>Job Position</span><span>Department</span><span class="leave">Leave</span></div>'
                });
                for (var k = 0; k < skills.length; k++) {
                    var skillHeader = skills[k];
                    if (!skillHeader.html) {
                        models.refreshComputedSkillFields(skillHeader);
                    }
                    matrix.header.push({
                        id: skillHeader.id,
                        html: skillHeader.html
                    });
                }
                //Create rows
                for (var i = 0; i < employees.length; i++) {
                    var even = (i % 2);
                    var employee = employees[i];
                    var row = {
                        id: employee.id,
                        css: (employee.leave ? 'has-leave ' : '') + (even ? 'even-row' : ''),
                        cells: [{
                            id: 'header_' + employee.id,
                            isHeader: true,
                            css: 'head-col',
                            html: '<span title="' + employee.name + '">' + employee.name + '</span><span title="' + employee.jobPosition + '">' + employee.jobPosition
                                + '</span><span title="' + employee.department + '">' + employee.department + '</span>'
                                +'<span class="clickable leave" onclick="showEmployeeLeavePopup(' + employee.id + ',\'' + employee.name + '\',' + (employee.leave ? '\'' + employee.leave + '\'' : 'null') + ')" title="' + this.getEmployeeLeaveTitle(employee) + '">' + (employee.leave ? employee.leave : '') + '</span>'
                        }]
                    };
                    for (var j = 0; j < skills.length; j++) {
                        var skill = skills[j];
                        var cellId = skill.id + '_' + employee.id;
                        var originalValidFromDate = null, validFromDate = null, originalExpirationDate = null, expirationDate = null, assignmentLevelId = null, text = '', html = '',
                            isEmployeeAssignment = false, trainingDate = null;
                        var signature = employee[skill.id];
                        if (signature) {
                            var data = signature.split(';');
                            if (data.length == 5) {
                                assignmentLevelId = data[0];
                                originalValidFromDate = validFromDate = (data[1] ? new Date(data[1]) : null);
                                originalExpirationDate = expirationDate = (data[2] ? new Date(data[2]) : null);
                                isEmployeeAssignment = (data[3] == 'e');
                                trainingDate = (data[4] ? new Date(data[4]) : null);
                                if (validFromDate && (validFromDate >= Date.now() || !expirationDate)) {
                                    text = 'from ' + $virtus.tools.dates.formatDate(validFromDate);
                                } else if (expirationDate) {
                                    text = 'until ' + $virtus.tools.dates.formatDate(expirationDate);
                                }
                                html = text;
                            }
                        }
                        var changedItem = self.changedItems[cellId];
                        if (changedItem) {
                            text = changedItem.text;
                            html = changedItem.text + '<span class="note"></span>';
                            validFromDate = changedItem.validFromDate;
                            expirationDate = changedItem.expirationDate;
                            trainingDate = changedItem.trainingDate;
                        }
                        if (trainingDate) {
                            html = '<div class="with-training-date">' + html + '<span class="training-date">' + $virtus.tools.dates.format(trainingDate, 'dd/MM') + '</span></div>';
                        }
                        var item = {
                            id: cellId,
                            skillId: skill.id,
                            employeeId: employee.id,
                            jobPositionId: employee.jobPositionId,
                            assignmentLevelId,
                            isEmployeeAssignment,
                            validFromDate,
                            originalValidFromDate,
                            expirationDate,
                            originalExpirationDate,
                            trainingDate,
                            css: 'clickable ' + (isEmployeeAssignment ? 'employee-assignment ' : '') 
                                + models.getSkillSignatureClass(assignmentLevelId, validFromDate, expirationDate),
                            text,
                            html,
                        };
                        row.cells.push(item);
                    }
                    matrix.rows.push(row);
                }
                self.matrix = matrix;
                setImmediate(() => {
                    $virtus.tools.fixTable(document.getElementById('signatures-table'));
                    $virtus.tools.loader.close();
                }, 0);
            },
        }
    }
</script>
<style>
    .signatures-skills .header-items-bar {
        align-items: center;
        margin-bottom: 0;
    }
    .signatures-skills .header-items-bar > * {
        margin-bottom: 5px;
    }
    .signatures-skills .custom-checkbox {
        margin-right: auto;
    }
    .signatures-skills .custom-checkbox .custom-control-label {
        padding-top: 3px;
        padding-right: 10px;
    }
    .signatures-skills .signature-filter {
        width: 28rem;
        margin-right: 10px;
    }
    .signatures-skills .signature-types {
        font-size: x-small;
    }
    .signatures-skills .signature-types td {
        padding: 1px 5px;
    }
    .signatures-skills .table-footer {
        margin-top: 10px;
        align-items: center;
    }
    .signatures-skills .table-footer.input-group > .custom-select,
    .signatures-skills .table-footer > * {
        margin-bottom: 5px;
    }
    .signatures-skills .table-footer .pagination {
        margin-right: auto;
    }
    .signatures-skills .table-footer button.btn-secondary {
    }
    .signatures-skills .table-footer button.btn {
        margin-left: 5px;
    }
    .signatures-skills #signatures-table td {
        font-size: smaller;
    }
    .signatures-skills .with-training-date {
        padding-left: 8px;
        text-align: left;
    }
    .signatures-skills .training-date {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
        font-size: xx-small;
        background-color: rgba(255, 140, 0, 1);
        padding: 1px 2px;
        line-height: 10px;
        color: black;
    }
    #pageSize {
        flex: none;
        width: 4rem;
    }
    .employee-assignment::after {
        content: 'e';
        font-size: xx-small;
        position: absolute;
        display: inline-block;
        left: 2px;
        bottom: 0;
        margin-bottom: -4px;
    }
    .employee-assignments-message-text {
        font-size: xx-small;
        font-style: italic;
    }
    .signatures-skills #signatures-table .head-col-label span.leave,
    .signatures-skills #signatures-table .head-col span.leave {
        min-width: 3rem;
        max-width: 3rem;
    }
    .signatures-skills #signatures-table .head-col span.leave:hover {
        background-color: #c0c0c0C0;
    }

    .signatures-skills #signatures-table .has-leave .head-col,
    .signatures-skills #signatures-table .has-leave .clickable,
    .signatures-skills #signatures-table .has-leave .required-no-valid-signature,
    .signatures-skills #signatures-table .has-leave .valid-signature {
        color: #4d4d4d;
        background-color: #A9A9A9 !important;
    }

</style>
