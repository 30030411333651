<template>
    <form @submit.prevent="changePassword" autocomplete="off">
        <simple-modal ref="changePassword" name="changePassword" @opened="opened" :clickToClose="false">
            <template slot="modal-header">
                <header class="modal-header bg-dark text-light">
                    <h5 class="modal-title">Enter new password</h5>
                </header>
            </template>
            <template slot="modal-content">
                <p v-if="!requestIsValid" class="error">The request for change password is not valid.</p>
                <div v-if="requestIsValid">

                    <br />
                    <control-text label="Password" v-model.trim="password" ref="password" placeholder="Please, enter your new password" type="password"></control-text>
                    <p v-if="showErrors && missingPassword" class="error">Password field is required.</p>
                    <p v-if="showErrors && passwordFormatNotValid && !missingPassword" class="error">
                        The format for password is not valid. It must contain at least one number and one letter,
                        and at least 8 or more characters. Only letters and numbers are allowed.
                    </p>

                    <br />
                    <control-text label="Repeat Password" v-model.trim="repeatPassword" placeholder="Please, repeat your new password" type="password"></control-text>
                    <p v-if="showErrors && missingRepeatPassword" class="error">You must repeat the password.</p>
                    <p v-if="showErrors && passwordsDoNotMatch && !missingRepeatPassword" class="error">Passwords do not match.</p>

                    <br />
                    <p v-if="changePasswordError" class="error">There was an error changing your password. Please, try again or contact the administrator.</p>
                </div>
            </template>
            <template slot="modal-buttons">
                <button id="btnChangePassword" type="submit" class="btn btn-primary" :disabled="!requestIsValid">Change password</button>
            </template>
        </simple-modal>
    </form>
</template>

<script>

    export default {
        name: 'change-password',
        props: [],
        data() {
            return {
                password: '',
                repeatPassword: '',
                showErrors: false,
                changePasswordError: false
            }
        },
        mounted() {
            this.$refs.changePassword.show();
        },
        computed: {
            missingPassword() {
                return (this.password === '');
            },
            missingRepeatPassword() {
                return (this.repeatPassword === '');
            },
            passwordsDoNotMatch() {
                return (this.password !== this.repeatPassword);
            },
            passwordFormatNotValid() {
                return (!$virtus.tools.validation.validatePassword(this.password));
            },
            isValidForm: function () {
                return (this.requestIsValid && !this.missingPassword && !this.missingRepeatPassword && !this.passwordsDoNotMatch && !this.passwordFormatNotValid);
            },
            requestIsValid: function () {
                return (this.token);
            },
            token: function () {
                return this.$route.query.token;
            }
        },
        methods: {
            async changePassword() {
                this.showErrors = true;
                this.changePasswordError = false;
                if (this.isValidForm) {
                    var result = await this.$store.dispatch('security/changePassword', {
                        token: this.token,
                        password: this.password
                    });
                    if (result) {
                        await $virtus.tools.messages.success('Change password', 'The password has been updated. Please, use the new password to login.');
                        this.$router.push('/logout');
                    } else {
                        this.changePasswordError = true;
                    }
                }
            },
            opened() {
                this.$refs.password.focus();
            },
        }
    }
</script>

<style scoped>

    #btnChangePassword {
        width: 10rem;
    }

</style>
