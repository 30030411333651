<template>
    <div class="control control-choice">
        <div v-if="position == 'right'">
            <b-container>
                <b-row>
                    <b-col class="mr-auto">
                        <div class="control-label" v-if="label">{{label}}</div>
                    </b-col>
                    <b-col cols="auto">
                        <div v-if="readOnly && value" class="read-only">{{value}}</div>
                        <div v-if="!readOnly" :class="'control-options form-control' + (showErrorState ? ' is-invalid' : '')">
                            <span :key="index" v-for="(option, index) in choiceOptions" @click="onClickOption(option)" :class="option.selected ? 'choice-selected' : ''">{{option.text}}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <div class="control-label" v-if="label">{{label}}</div>
            <div v-if="readOnly && value" class="read-only">{{value}}</div>
            <div v-if="!readOnly" :class="'control-options' + (showErrorState ? ' is-invalid' : '')">
                <span :key="index" v-for="(option, index) in choiceOptions" @click="onClickOption(option)" :class="option.selected ? 'choice-selected' : ''">{{option.text}}</span>
            </div>
        </div>
        <div v-if="required && isEmpty" class="error">The field {{label}} is required.</div>
        <div v-if="errors">
            <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'control-choice',
        props: ['label', 'value', 'options', 'readOnly', 'position', 'cols', 'hasErrors', 'errors', 'required'],
        data() {
            return {
                choiceOptions: [],
                choiceValue: null
            };
        },
        mounted() {
            this.choiceValue = this.value;
            var options = (this.options || '').split(',');
            for (var i in options) {
                this.choiceOptions.push({
                    text: options[i],
                    selected: (options[i] == this.value)
                });
            }
        },
        watch: {
            value() {
                this.choiceValue = this.value;
                this.setValue(this.value);
            }
        },
        computed: {
            isEmpty() {
                return (this.choiceValue === null || this.choiceValue === undefined);
            },
            showErrorState() {
                return (this.errors || this.hasErrors || (this.required && this.isEmpty));
            },
        },
        methods: {
            onClickOption(option) {
                if (!this.readOnly) {
                    this.clearAllOptions();
                    option.selected = true;
                    this.$emit('input', option.text);
                }
            },
            setValue(newValue) {
                for (var i in this.choiceOptions) {
                    this.choiceOptions[i].selected = (this.choiceOptions[i].text == newValue);
                }
            },
            clearAllOptions() {
                this.setValue(null);
            },
        }
    };
</script>

<style>
    .control-choice .control-options {
        margin: 0.2rem 0;
        padding: 0.6rem 0.2rem;
    }

    .control-choice span {
        padding: 0.5rem 1rem;
        cursor: pointer;
        background-color: white;
        margin: 0 2px;
        border: solid white 2px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    .control-choice .choice-selected {
        font-weight: bolder;
        border-color: darkgray;
    }

    .control-choice div.read-only {
        margin: 0.2rem 0;
        border-radius: 0.25rem;
        border: 1px solid grey;
        padding: 0.3rem 0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        flex: 1 1 auto;
        font-size: 0.9rem;
        cursor: default;
    }

    .control-choice .is-invalid span {
        border: 1px solid #dc3545;
    }
</style>
