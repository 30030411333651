<template>
    <div class="signatures-ipad-sign-page">
        <h1 class="header-title">{{userName}}</h1>
        <div class="rams-area">
            <p>
                By signing this document I confirm that I have fully understood the
                contents of all documents listed bellow, the safe systems of work and risk
                assessments and I agree to work complying with them and not defer from them
                without prior written confirmation from the company management and QHSE
                department:
            </p>
            <ul class="rams-to-sign">
                <li :key="index" v-for="(ram, index) in ramsToSign">
                    <span class="reference">{{ram.reference}}.</span> Version {{ram.version}}. {{ram.description}} 
                    <span v-if="ram.viewSeconds">[{{$virtus.tools.dates.formattedTime(ram.viewSeconds)}}]</span>
                </li>
            </ul>
            <div class="total-training-time" >Total training time: {{$virtus.tools.dates.formattedTime(totalTrainingTime)}}</div>
        </div>
        <div class="signature-pad">
            <div cols="auto" class="date">Signed on {{signedDateFormat}} by {{userName}}.</div>
            <div @mousemove="onMouseMoveSign">
                <VueSignaturePad class="canvas" width="40rem" height="10rem" ref="signaturePad" />
            </div>
            <div class="header">
                <span class="button" @click="clearSignature()">Clear</span>
                <span class="button" @click="undoSignature()">Undo</span>
            </div>
        </div>
        <div class="buttons">
            <button type="button" class="btn btn-secondary btn-sm" @click="cancel()">Cancel and go to read page</button>
            <button type="button" class="btn btn-primary btn-sm" :disabled="isNotSigned" @click="signDocument()">Sign document</button>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                signedDate: null,
                isSigned: false
            }
        },
        async created() {
            if (!this.ramsToSign || !(this.ramsToSign.length > 0)) {
                this.cancel();
            } else {
                this.signedDate = $virtus.tools.dates.now();
            }
        },
        computed: {
            ...mapState('mywork', ['rams']),
            ...mapState('security', ['userName']),

            isNotSigned() {
                return !this.isSigned;
            },
            ramsToSign() {
                var rams = [];
                if (this.rams && this.rams.length > 0) {
                    rams = this.rams.filter(function (ram) {
                        return ram.isRead;
                    });
                }
                return rams;
            },
            signedDateFormat() {
                return $virtus.tools.dates.formatDateTime(this.signedDate);
            },
            totalTrainingTime() {
                var total = 0;
                for (var ram of this.ramsToSign) {
                    if (ram.viewSeconds) total += ram.viewSeconds;
                }
                return total;
            },
        },
        methods: {
            onMouseMoveSign() {
                this.isSigned = !this.$refs.signaturePad.isEmpty();
            },
            undoSignature() {
                this.$refs.signaturePad.undoSignature();
                this.isSigned = !this.$refs.signaturePad.isEmpty();
            },
            clearSignature() {
                this.$refs.signaturePad.clearSignature();
                this.isSigned = false;
            },
            cancel() {
                this.$store.commit('mywork/setKeepRams', true);
                this.goToIndex();
            },
            goToIndex() {
                this.$router.push('/mywork/rams');
            },
            async signDocument() {
                var signatureImage = this.$refs.signaturePad.saveSignature();
                var rams = this.ramsToSign.map(function (ram) {
                    return {
                        Id: ram.ramId,
                        Version: ram.version,
                        Description: ram.description,
                        Reference: ram.reference
                    };
                });

                var model = {
                    ramsToSign: rams,
                    signatureDate: $virtus.tools.dates.convertDateTimeToServer(this.signedDate),
                    base64Image: signatureImage.data
                };

                let result = await this.$store.dispatch('mywork/createSignatureReport', model);
                if (result) {
                    $virtus.tools.messages.showSaveOk('signature report', 'created');
                    this.goToIndex();
                }
            }
        }
    }

</script>
<style>
    .signatures-ipad-sign-page {
    }

    .signatures-ipad-sign-page .header-title {
        font-size: xx-large;
        font-weight: bolder;
    }

    .signatures-ipad-sign-page .buttons {
        position: absolute;
        bottom: 2rem;
        right: 2rem;
    }

    .signatures-ipad-sign-page .signature-pad {
        position: absolute;
        bottom: 8rem;
    }

    .signatures-ipad-sign-page .rams-area {
        position: absolute;
        bottom: 18rem;
        top: 5rem;
        left: 2rem;
        right: 0;
        padding-right: 2rem;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: large;
        text-align: justify;
        line-height: 2rem;
    }

    .rams-to-sign {
        margin-top: 2rem;
    }

    .rams-to-sign li {
        margin-left: 2rem;
    }

    .rams-to-sign li .reference {
        font-weight: bolder;
    }

    .signature-pad {
        width: 40rem;
        height: 10rem;
    }
    .signature-pad .canvas {
        background-color: rgba(255, 255, 255, 0.6);
    }

    .signature-pad .header {
        text-align: right;
        width: 100%;
    }

    .signature-pad .button {
        margin-left: 1rem;
        font-weight: 600;
    }

    .signature-pad .button:hover {
        cursor: pointer;
        color: white;
        background-color: rgba(0, 0, 0, 0.4);
    }
</style>
