<template>
    <b-modal v-model="isVisible" ref="simpleModal" :id="name" centered scrollable @show="beforeOpen"
             @shown="opened" @hidden="hidden" :dialog-class="'simple-modal ' + dialogClass"
             :no-close-on-esc="true" :no-close-on-backdrop="true" :hide-header-close="true">
        <template #modal-header>
            <slot name="modal-header">
                <h5 class="modal-title">{{ headerTitle}}</h5>
                <div class="close-button" v-on:click="clickClose"><icon name="times"></icon></div>
            </slot>
        </template>
        <template #default>
            <slot name="modal-content"></slot>
        </template>
        <template #modal-footer>
            <slot name="modal-buttons">
                <template v-if="modalType=='popup'">
                    <button v-if="!readOnly" type="button" class="btn btn-secondary" @click="clickClose">Cancel</button>
                    <button v-else type="button" class="btn btn-primary" @click="clickClose">Close</button>
                    <button v-if="!readOnly" type="button" class="btn btn-primary" @click="clickSave">{{buttonText}}</button>
                </template>
                <template v-else>
                    <button type="button" class="btn btn-secondary" @click="clickClose">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="clickOk">OK</button>
                </template>
            </slot>
        </template>
    </b-modal>
</template>

<script>

    export default {
        name: 'simple-modal',
        props: {
            headerTitle: {},
            name: {
                default: 'simple-modal-1'
            },
            width: {},
            modalType: {
                default: 'popup'
            },
            buttonText: {
                default: 'Save'
            },
            readOnly: {
                default: false
            },
            dialogClass: {
                default: ''
            },
        },
        data() {
            return {
                isVisible: false,
            };
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.setPopupWidth);
            })
        },
        computed: {
            numericWidth() {
                return (this.width ? parseInt((this.width || '').replace('px', '')) : 600);
            },
        },
        methods: {
            clickOk: function () {
                this.$emit('ok');
                this.isVisible = false;
            },
            clickClose: function () {
                this.$emit('cancel');
                this.isVisible = false;
            },
            clickSave: function () {
                this.$emit('save');
            },
            show() {
                this.setPopupWidth();
                this.isVisible = true;
            },
            hide() {
                this.isVisible = false;
            },
            hidden() {
                this.$emit('closed');
            },
            beforeOpen() {
                this.$emit('before-open');
            },
            opened() {
                this.$emit('opened');
            },
            setPopupWidth() {
                var simpleModal = this.$refs.simpleModal;
                if (simpleModal && simpleModal.modal) {
                    var windowWidth = window.innerWidth;
                    var width = simpleModal.modal.width;
                    var maxWidth = this.numericWidth;
                    if (width > 0.95 * windowWidth) {
                        simpleModal.modal.width = 0.90 * windowWidth;
                    } else if (width < 0.8 * windowWidth) {
                        simpleModal.modal.width = (0.90 * windowWidth < maxWidth ? 0.90 * windowWidth : maxWidth);
                    }
                }
            },
        }
    };

</script>

<style>
    .v--modal-overlay .v--modal-box {
        margin-top: 2rem;
    }

    .simple-modal .modal-header {
        display: block;
        border: 0;
        text-align: center;
        cursor: grab;
        padding: 0.3rem 1rem;
    }

    .simple-modal .modal-title {
        font-size: 1rem;
        font-weight: 600;
    }

    .simple-modal .modal-footer {
        border: 0;
    }

    .simple-modal .modal-content {
        margin-top: 0.5rem;
        max-height: 30rem;
        border: none;
    }

    .simple-modal .modal-body {
        overflow: auto;
        padding: 0 1rem 1rem 1rem;
    }

    .simple-modal .close-button {
        position: absolute;
        top: 0.55rem;
        right: 1rem;
        cursor: pointer;
    }

    @media (max-height: 250px) {
        .simple-modal .modal-body {
            max-height: 200px !important;
        }
    }

    @media (max-height: 400px) {
        .simple-modal .modal-body {
            max-height: 300px !important;
        }
    }

    @media (max-height: 600px) {
        .simple-modal .modal-body {
            max-height: 400px;
        }
    }

    @media (max-height: 800px) {
        .simple-modal .modal-body {
            max-height: 600px;
        }
    }

    @media (max-height: 1000px) {
        .simple-modal .modal-body {
            max-height: 800px;
        }
    }

    @media (min-height: 1000px) {
        .simple-modal .modal-body {
            max-height: 900px;
        }
    }
</style>
