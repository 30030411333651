import api from '../tools/api';

export default {
    namespaced: true,
    state: {
        chartsLibLoaded: false,
    },
    getters: {

    },
    mutations: {

        checkChartsLibIsLoaded(state) {
            var existingChartLib = window.document.getElementById('chartsLib');
            if (!existingChartLib) {
                state.chartsLibLoaded = false;
                let script = window.document.createElement('script');
                script.setAttribute('src', 'https://www.gstatic.com/charts/loader.js');
                script.setAttribute('id', 'chartsLib');
                script.onload = () => {
                    state.chartsLibLoaded = true;
                }
                script.async = true
                window.document.head.appendChild(script);
            } else {
                state.chartsLibLoaded = true;
            }
        },

    },
    actions: {
        async getHomeCountersInfo(store, data) {
            var url = (data.isAdmin ? '/api/home/admin/counters?siteId=' + data.siteId : '/api/home/counters');
            let result = await api.get(url);
            if (result) {
                var counters = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0, 24: 0, 25: 0, 26: 0, 27: 0, 28: 0, 29: 0, 30: 0, 31: 0 };
                for (var i = 0; i < result.length; i++) {
                    var item = result[i];
                    counters[item.counterId] = item.counterValue;
                }
                result = counters;
            }
            return result;
        },

        async getHomeCountersInfoAllSites() {
            let result = await api.get('/api/home/admin/counters?siteId=0');
            if (result) {
                var counters = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0, 24: 0, 25: 0, 26: 0, 27: 0, 28: 0, 29: 0, 30: 0, 31: 0 };
                var allSites = {};
                for (var i = 0; i < result.length; i++) {
                    var item = result[i];
                    if (!allSites[item.siteId]) allSites[item.siteId] = Object.assign({}, counters);
                    allSites[item.siteId][item.counterId] = item.counterValue;
                }
                result = allSites;
            }
            return result;
        },

        async getHomePendingRams(store, data) {
            var url = (data.isAdmin ? '/api/home/admin/pending-rams?siteId=' + data.siteId : '/api/home/pending-rams');
            let result = await api.get(url);
            return result;
        },

        async getHomePendingSkills(store, data) {
            var url = (data.isAdmin ? '/api/home/admin/pending-skills?siteId=' + data.siteId : '/api/home/pending-skills');
            let result = await api.get(url);
            return result;
        },

        async getHomePendingSopValidations(store, data) {
            var url = (data.isAdmin ? '/api/home/admin/pending-sop-validations?siteId=' + data.siteId : '/api/home/pending-sop-validations');
            let result = await api.get(url);
            return result;
        },
    }
};
