const CACHE_EXPIRATION_IN_MINUTES = 10;

let cache = {
    get(state, key) {
        //var d = new Date();
        if (state.cache && state.cache[key] && state.cache[key].expires) {
            if (state.cache[key].expires > new Date()) {
                return state.cache[key].value;
            } else {
                this.invalidate(state, key);
            }
        }
        return null;
    },
    set(state, key, value) {
        if (!state.cache) state.cache = {};
        var d = new Date();
        d.setTime(d.getTime() + (CACHE_EXPIRATION_IN_MINUTES * 60 * 1000));
        state.cache[key] = {
            value,
            expires: d
        };
    },
    invalidate(state, keys) {
        if (state.cache) {
            if (!Array.isArray(keys)) {
                keys = [keys];
            }
            for (var i in keys) {
                state.cache[keys[i]] = null;
            }
        }
    },
};

export default cache;
