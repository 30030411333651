var api = $virtus.tools.api;

export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getTrainingCosts(store, data) {
            let result = await api.get('/api/training-costs/' + data.year);
            return result;
        },

        async createUpdateTrainingCosts(store, data) {
            let result = await api.post('/api/training-costs', data);
            return result;
        },

    }
};
