<template>
    <div></div>

</template>
<script>

    export default {
        name: 'logout',
        props: [],
        data() {
            return {

            }
        },
        created() {
            this.$store.dispatch('security/logout');
        },
        mounted() {
        },
        computed: {


        },
        methods: {


        }
    }
</script>
<style scoped>

</style>
