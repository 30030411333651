<template>
    <div class="ram-viewer-header">
        <table v-if="ramIsValid">
            <tr>
                <td colspan="2" class="description">{{description}}</td>
                <td rowspan="2" v-if="showTimer" class="username">{{userName}}</td>
                <td rowspan="2" v-if="showTimer" class="timer">{{formattedTime}}</td>
                <td rowspan="2" class="back" v-if="returnPath"><div @click="goBack()">Back</div></td>
            </tr>
            <tr>
                <td class="reference">{{reference}}</td>
            </tr>
        </table>
        <div class="message" v-if="message">{{message}}</div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'ram-viewer-header',
        props: ['ramId', 'returnPath', 'showTimer'],
        data() {
            return {
                reference: null,
                description: null,
                version: null,
                viewSeconds: null,
                timerEnabled: false,
                message: null,
            }
        },
        async created() {
        },
        async mounted() {
            await this.loadRam();
        },
        computed: {
            ...mapState('security', ['userName']),
            ramIsValid() {
                return (this.ramId && this.reference && this.description);
            },
            formattedTime() {
                return $virtus.tools.dates.formattedTime(this.viewSeconds);
            }
        },
        methods: {
            async loadRam() {
                if (this.ramId) {
                    var ramViewTime = await this.$store.dispatch('rams/getRamViewTime', { ramId: this.ramId });
                    if (ramViewTime) {
                        this.reference = ramViewTime.reference;
                        this.description = ramViewTime.description;
                        this.version = ramViewTime.version;
                        this.viewSeconds = ramViewTime.viewSeconds;
                        if (!this.ramIsValid) {
                            this.message = 'The ram is not valid.';
                        } else {
                            this.message = null;
                            this.timerEnabled = ramViewTime.timerEnabled && this.showTimer;
                            if (this.timerEnabled) {
                                this.startTimer();
                            }
                        }
                    }
                }
            },
            goBack() {
                this.$store.dispatch('security/routeTo', this.returnPath);
            },
            startTimer() {
                setInterval(() => {
                    if (this.timerEnabled) {
                        this.viewSeconds++;
                        if (this.viewSeconds % 10 === 0) {
                            this.updateViewTime();
                        }
                    }
                }, 1000);
            },
            async updateViewTime() {
                await this.$store.dispatch('rams/updateRamViewTime', { RamId: this.ramId, Version: this.version, ViewSeconds: this.viewSeconds });
            },
            async routeLeave() {
                if (this.timerEnabled) {
                    this.timerEnabled = false;
                    await this.updateViewTime();
                }
            }
        }
    }

</script>
<style>

    .app-view .ram-viewer-header table {
        width: 100%;
        background-color: transparent;
    }

    .ram-viewer-header .back {
        width: 1%;
        padding: 1rem;
        border-radius: 5px;
    }

    .ram-viewer-header .back div {
        background-color: rgba(255,255,255,0.25);
        color: white;
        padding: 0.25rem 1rem;
        cursor: pointer;
    }

    .ram-viewer-header .back div:hover {
        background-color: rgba(255,255,255,0.7);
        color: black;
    }

    .ram-viewer-header td.description {
        font-size: xx-large;
        line-height: 2rem;
    }


</style>
