<template>
    <div :class="{ 'employee-skills-assignments': true, 'read-only': !hasWriteRights }">
        <h1 class="header-title">Employees Skills Assignments</h1>

        <div class="header-items-bar">
            <!--<div class="header-item signature-msg" v-if="hasWriteRights">Click assignment level for quick edit:</div>-->
            <!--<div class="header-item assignment-levels-bar">
                <table id="assignment-levels">
                    <tr>
                        <td id="assignment-level-clear" :class="{ 'selected': (selectedAssignmentLevel && selectedAssignmentLevel.id == 0), 'clickable': hasWriteRights }" @click.stop="clickAssignmentLevel({ id: 0})">Clear</td>
                        <td :key="index" v-for="(level, index) in assignmentLevels" @click.stop="clickAssignmentLevel(level)" :id="'assignment-level-' + level.id" :class="{ 'selected': (selectedAssignmentLevel && level.id == selectedAssignmentLevel.id), clickable: hasWriteRights }">{{level.id + ' - ' + level.name}}</td>
                    </tr>
                </table>
            </div>-->
            <select-assignment-level v-if="hasWriteRights" ref="selectAssignmentLevel" class="header-item" @click="clickAssignmentLevel"></select-assignment-level>
            <select-book-training v-if="hasWriteRights" ref="selectBookTraining" class="header-item" @click="clickBookTraining"></select-book-training>

            <div class="header-item">
                <b-input-group size="sm" prepend="Filter">
                    <b-form-input id="skillFilter" size="sm" v-model="skillFilterText" placeholder="skills" title="enter skills filter" @keyup.enter.native="filterClick" />
                    <b-form-input id="employeeFilter" v-model="employeeFilterText" placeholder="employee" title="enter employee filter" @keyup.enter.native="filterClick" />
                    <b-input-group-append>
                        <b-btn @click.stop="filterClick">Go</b-btn>
                    </b-input-group-append>
                </b-input-group>
            </div>

            <div class="header-item">
                <b-input-group size="sm" prepend="Page size">
                    <b-form-select id="pageSize" size="sm" :options="pageOptions" v-model="perPage" @change="paginationChanged" />
                    <b-pagination size="sm" :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" @change="paginationChanged" />
                </b-input-group>
            </div>

            <div class="header-item checkLeavers">
                <b-form-checkbox id="showLeavers" v-model="showLeavers" :value="true" :unchecked-value="false" @change="showLeaversClick">Show leavers</b-form-checkbox>
            </div>

            <div class="clear"></div>

        </div>

        <div id="assignments-table" class="fixed-table-container">
            <table>
                <thead>
                    <tr class="header even-row">
                        <th v-for="cell in matrix.header" :key="'h_' + cell.id" :class="cell.css" v-html="cell.html"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in matrix.rows" :key="'r_' + row.id" :class="row.css">
                        <td v-for="cell in row.cells" :key="'c_' + cell.id" @click.stop="clickCell(cell)" :class="cell.css" :title="cell.text" v-html="cell.html"></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="buttons" v-if="hasWriteRights">
            <span style="float:left;" v-show="itemsChangedCount > 0">Employees Skills assignments changed: {{itemsChangedCount}}</span>
            <span>
                <button type="button" class="btn btn-secondary btn-sm" @click="discardChanges" :disabled="itemsChangedCount == 0">Discard changes</button>
                <button type="button" class="btn btn-primary btn-sm" @click="saveChanges" :disabled="itemsChangedCount == 0">Save changes</button>
            </span>
        </div>

    </div>
</template>

<script>
    //import { mapState } from 'vuex';
    import 'core-js/web/immediate';
    import employeeSkillCell from './employee-skill-cell.js';
    import models from '../shared/models.js';

    export default {
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                pageOptions: [5, 10, 20, 50, 100],
                selectedAssignmentLevel: null,
                selectedTrainingDate: null,
                matrix: { header: [], rows: [] },
                itemsChangedCount: 0,
                skillFilterText: '',
                employeeFilterText: '',
                skillFilter: '',
                employeeFilter: '',
                changedItems: {},
                employeesAssignments: {},
                showLeavers: false,
                assignmentLevels: [],
                skills: {},
                sortedSkills: [],
                employees: [],
                hasWriteRights: false,
            }
        },
        async created() {
            this.hasWriteRights = await this.checkUserHasWriteRights();
            await Promise.all([
                this.$store.dispatch('getAssignmentLevels'),
                this.$store.dispatch('skills/getFullSkills'),
                this.$store.dispatch('skills/getSortedFullSkills'),
                this.$store.dispatch('admin/getEmployees'),
                this.loadEmployeesAssignments(),
            ]).then((values) => {
                this.assignmentLevels = values[0];
                this.skills = values[1];
                this.sortedSkills = values[2];
                this.employees = values[3];
                this.loadMatrix();
            });
        },
        beforeRouteLeave(to, from, next) {
            $virtus.tools.changes.beforeRouteLeave(to, from, next, this.itemsChangedCount > 0);
        },
        computed: {
            //...mapState(['leaverStateId']),
            totalRows() {
                return this.filteredSkills.length;
            },
            first () {
                return 1 + (this.currentPage - 1) * this.perPage;
            },
            paginatedSkills() {
                var skills = this.filteredSkills.slice(this.first - 1, this.first + this.perPage - 1);
                return skills;
            },
            filteredSkills() {
                var skills = this.sortedSkills;
                var skillFilter = this.skillFilter;
                if (skillFilter) {
                    skills = skills.filter(r => { return r.search.includes(skillFilter); });
                }
                return skills;
            },
            filteredEmployees() {
                var employees = this.employees;
                var employeeFilter = this.employeeFilter;
                if (employeeFilter || !this.showLeavers) {
                    employees = employees.filter(e => {
                        return (!employeeFilter || e.search.includes(employeeFilter))
                            && (this.showLeavers || e.shiftId != $virtus.config.LEAVER_SHIFT_ID);
                    });
                }
                return employees;
            },
        },
        methods: {
            async loadEmployeesAssignments() {
                let assignments = await this.$store.dispatch('skills/getEmployeesSkillsAssignments');
                var items = {};
                for (var i in assignments) {
                    var item = assignments[i];
                    if (!items[item.employeeId]) items[item.employeeId] = {};
                    var assignmentLevelId = item.assignmentLevelId;
                    items[item.employeeId][item.skillId] = {
                        //text: assignmentLevelId,
                        //css: 'assignment-level-' + assignmentLevelId,
                        assignmentLevelId: assignmentLevelId,
                        trainingDate: item.trainingDate,
                    };
                }
                this.employeesAssignments = items;
            },
            //clickCell(item) {
            //    if (!this.hasWriteRights) return;
            //    if (!item.isHeader && this.selectedAssignmentLevel != null && (item.assignmentLevelId != this.selectedAssignmentLevel.id || !item.isEmployeeAssignment)) {
            //        var assignmentLevelId = 0, isEmployeeAssignment = false;

            //        //Update item
            //        if (this.selectedAssignmentLevel.id > 0 ) {
            //            assignmentLevelId = this.selectedAssignmentLevel.id;
            //            item.assignmentLevelId = assignmentLevelId;
            //            item.css = 'clickable employee assignment-level-' + assignmentLevelId;
            //            item.html = assignmentLevelId + ' <span>(employee)</span>';
            //            isEmployeeAssignment = true;
            //        } else if (item.jobPositionAssignment) {
            //            assignmentLevelId = item.jobPositionAssignment.assignmentLevelId;
            //            item.assignmentLevelId = assignmentLevelId;
            //            item.css = 'clickable assignment-level-' + assignmentLevelId;
            //            item.html = assignmentLevelId;
            //            isEmployeeAssignment = false;
            //        } else {
            //            item.assignmentLevelId = 0;
            //            item.css = 'clickable';
            //            item.html = '';
            //            isEmployeeAssignment = false;
            //        }

            //        //Set assignment state
            //        var changedItem = this.changedItems[item.id];
            //        var changed = (item.assignmentLevelId != item.originalAssignmentLevelId || isEmployeeAssignment != item.isEmployeeAssignment);

            //        //Update / create / delete changedItem
            //        if (changed && changedItem) {
            //            //Already changed, update
            //            changedItem.assignmentLevelId = assignmentLevelId;
            //            changedItem.text = (assignmentLevelId > 0 ? assignmentLevelId : '');
            //            changedItem.css = (assignmentLevelId > 0 ? 'assignment-level-' + assignmentLevelId : '');
            //        } else if (!changed && changedItem) {
            //            //Not changed, delete changed item
            //            this.itemsChangedCount--;
            //            delete this.changedItems[item.id];
            //        } else if (changed && !changedItem) {
            //            //Changed now, create changed item
            //            this.itemsChangedCount++;
            //            this.changedItems[item.id] = {
            //                id: item.id,
            //                skillId: item.skillId,
            //                employeeId: item.employeeId,
            //                assignmentLevelId: assignmentLevelId,
            //                text: (assignmentLevelId > 0 ? assignmentLevelId : ''),
            //                css: (assignmentLevelId > 0 ? 'assignment-level-' + assignmentLevelId : ''),
            //                isEmployeeAssignment: isEmployeeAssignment
            //            };
            //        } //Else Not changed, not changed item: do nothing

            //        //Display changed item
            //        if (changed) {
            //            item.html += '<span class="note"></span>';
            //        }
            //    }
            //},
            //clickAssignmentLevel(level) {
            //    if (!this.hasWriteRights) return;
            //    if (!this.selectedAssignmentLevel || level.id != this.selectedAssignmentLevel.id) {
            //        this.selectedAssignmentLevel = level;
            //    } else {
            //        this.selectedAssignmentLevel = null;
            //    }
            //},
            async clickCell(item) {
                if (!this.hasWriteRights) return;
                if (!item.isHeader) {
                    item.click();
                }
            },

            clickAssignmentLevel(level) {
                this.selectedTrainingDate = null;
                this.selectedAssignmentLevel = level;
                this.$refs.selectBookTraining.clear();
            },

            clickBookTraining(trainingDate) {
                this.selectedAssignmentLevel = null;
                this.selectedTrainingDate = trainingDate;
                this.$refs.selectAssignmentLevel.clear();
            },

            discardChanges() {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    changedItems.forEach(x => x.discardChanges());
                }
                this.changedItems = {};
                this.itemsChangedCount = 0;
                //this.loadMatrix();
                this.showChangesDiscarded();
            },

            showSaveOk() {
                $virtus.tools.messages.showMessage('Success!', 'The employees skills assignments have been saved successfully.');
            },

            showChangesDiscarded() {
                $virtus.tools.messages.showMessage('Changes discarded', 'All changes have been discarded.');
            },

            async saveChanges() {
                var changedItems = Object.values(this.changedItems);
                if (changedItems) {
                    var data = changedItems.map(x => x.getData());
                    //for (var i in changedItems) {
                    //    //Set assignment level to 0 for job position levels
                    //    if (!changedItems[i].isEmployeeAssignment) changedItems[i].assignmentLevelId = 0;
                    //}
                    let result = await this.$store.dispatch('skills/updateEmployeesSkillsAssignments', data);
                    if (result) {
                        this.showSaveOk();
                        changedItems.forEach(x => x.saveChanges());
                        //console.log('saved ok');
                        //for (var j = 0; j < changedItems.length; j++) {
                        //    var item = changedItems[j];
                        //    if (!this.employeesAssignments[item.employeeId]) this.employeesAssignments[item.employeeId] = {};
                        //    var assignments = this.employeesAssignments[item.employeeId];
                        //    if (item.assignmentLevelId && item.assignmentLevelId > 0) {
                        //        var assignmentLevelId = item.assignmentLevelId;
                        //        assignments[item.skillId] = {
                        //            text: assignmentLevelId,
                        //            css: 'assignment-level-' + assignmentLevelId,
                        //            assignmentLevelId: assignmentLevelId
                        //        };
                        //    } else {
                        //        delete assignments[item.skillId];
                        //    }
                        //}
                        this.changedItems = {};
                        this.itemsChangedCount = 0;
                        //this.loadMatrix();
                    }
                }
            },

            showLeaversClick: function () {
                this.loadMatrix();
            },

            filterClick() {
                this.currentPage = 1;
                this.skillFilter = this.skillFilterText.toLocaleLowerCase();
                this.employeeFilter = this.employeeFilterText.toLocaleLowerCase();
                this.loadMatrix();
            },

            paginationChanged() {
                this.loadMatrix();
            },
            loadMatrix() {
                var self = this;
                $virtus.tools.loader.show();

                var matrix = { header: [], rows: [] };
                var skills = [];
                var employees = [];
                //var assignmentLevels = self.assignmentLevels;

                function part1() {
                    //var a = new Date();
                    //console.log("part 1 - start");
                    //Apply filters
                    skills = self.paginatedSkills;
                    employees = self.filteredEmployees;

                    setImmediate(part2);
                    //console.log("part 1 - end " + (new Date() - a) + ' ms');
                }

                function part2() {
                    //var a = new Date();
                    //console.log("part 2 - start");
                    //Create header
                    matrix.header.push({ id: 0, css: 'head-col-label', html: '<div>Employee</div>' });
                    for (var i = 0; i < skills.length; i++) {
                        var skill = skills[i];
                        if (!skill.html) {
                            models.refreshComputedSkillFields(skill);
                        }
                        matrix.header.push({
                            id: skill.id,
                            css: skill.css,
                            html: skill.html
                        });
                    }
                    setImmediate(part3);
                    //console.log("part 2 - end " + (new Date() - a) + ' ms');
                }

                function part3() {
                    //var a = new Date();
                    //console.log("part 3 - start");

                    //Create rows
                    for (var i = 0; i < employees.length; i++) {
                        var even = (i % 2);
                        var employee = employees[i];
                        var assignments = self.employeesAssignments[employee.id] || {};
                        var row = {
                            id: employee.id,
                            css: (even ? 'even-row' : ''),
                            cells: [{
                                id: employee.id,
                                text: employee.fullName,
                                html: employee.fullName,
                                isHeader: true,
                                css: 'head-col'
                            }]
                        };
                        for (var j = 0; j < skills.length; j++) {
                            var skill = skills[j];
                            row.cells.push(new employeeSkillCell(self, employee, skill, assignments));

                        //    var cellId = skill.id + '_' + employee.id;
                        //    var assignmentLevelId = 0, html = '', css = '';
                        //    var assignment = assignments[skill.id];
                        //    var isEmployeeAssignment = false;
                        //    var jobPositionAssignment = skill.assignments[employee.jobPositionId];
                        //    var changedItem = self.changedItems[cellId];
                        //    if (changedItem) {
                        //        html = changedItem.text + '<span class="note"></span>';
                        //        css = changedItem.css;
                        //        assignmentLevelId = changedItem.assignmentLevelId;
                        //        isEmployeeAssignment = changedItem.isEmployeeAssignment;
                        //    } else if (assignment) {
                        //        html = assignment.text + ' <span>(employee)</span>';
                        //        css = 'employee ' + assignment.css;
                        //        assignmentLevelId = assignment.assignmentLevelId;
                        //        isEmployeeAssignment = true;
                        //    } else if (jobPositionAssignment) {
                        //        html = jobPositionAssignment.text;
                        //        css = jobPositionAssignment.css;
                        //        assignmentLevelId = jobPositionAssignment.assignmentLevelId;
                        //    }
                        //    row.cells.push({
                        //        id: cellId,
                        //        skillId: skill.id,
                        //        employeeId: employee.id,
                        //        assignmentLevelId: assignmentLevelId,
                        //        originalAssignmentLevelId: assignmentLevelId,
                        //        isEmployeeAssignment: isEmployeeAssignment,
                        //        jobPositionAssignment: jobPositionAssignment,
                        //        html: html,
                        //        css: 'clickable ' + css
                        //    });
                        }
                        matrix.rows.push(row);
                    }

                    self.matrix = matrix;
                    setImmediate(part4);
                    //console.log("part 3 - end " + (new Date() - a) + ' ms');
                }

                function part4() {
                    //var a = new Date();
                    //console.log("part 4 - start");
                    $virtus.tools.fixTable(document.getElementById('assignments-table'));
                    $virtus.tools.loader.close();
                    //console.log("part 4 - end " + (new Date() - a) + ' ms');
                }

                //Start long running task
                setImmediate(part1);
            },
        }
    }
</script>

<style>
    .training-date {
        position: absolute;
        bottom: 0;
        right: 0;
        display: inline-block;
        font-size: xx-small;
        background-color: darkorange;
        padding: 1px 2px;
        line-height: 10px;
        color: black;
    }
</style>
