<template>
    <div class="main-form rams-to-be-updated">
        <h1 class="header-title">RAMS to be updated</h1>
        <div>
            <p>
                This report shows all the RAMS with expired review date or expiring in next
                <control-text v-model="months" placeholder="x" type="number" :min="0" :max="99"></control-text>
                months.

            </p>
        </div>
        <div class="buttons">
            <!--<button type="button" class="btn btn-secondary btn-sm" @click="download(false)">Open</button>-->
            <button type="button" class="btn btn-primary btn-sm" @click="download(true)">Download</button>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                months: 1,
            }
        },
        async created() {
        },
        computed: {
        },
        methods: {
            download(download) {
                this.$store.dispatch('reports/getRAMsToBeUpdatedReport', { download, months: this.months });
            },
        }
    }
</script>

<style>
    .rams-to-be-updated .control-text {
        display: inline-block;
    }

    .rams-to-be-updated .control-text input {
        font-size: small;
        width: 3rem;
        padding: 0 2px 0 8px;
        height: 1.5rem;
    }
</style>
