<template>
    <div :class="noMainForm ? '' : 'main-form'">
        <div v-show="!showForm">
            <h1 class="header-title">{{texts && texts.listTitle ? texts.listTitle : 'Items list'}}</h1>
            <full-table ref="fullTable" :fields="fields" :items="items" :addNewTitle="texts.addButton" @addnew="addNewItem" @edit="editItem"
                        :sort-by="sortBy ? sortBy : 'name'" :allowReadWrite="true" :external-edit="editInForm" @showEdit="showEdit">
                <template v-slot:moreActions="{ item, index, showEditItem }">
                    <slot name="moreActions" :item="item" :index="index" :showEditItem="showEditItem"></slot>
                    <b-button v-if="showDelete && !editInForm" size="sm" variant="secondary" :disabled="showEditItem" @click.stop="deleteItem(item, index)" class="mr-1" right>
                        {{texts.deleteButton}}
                    </b-button>
                </template>
            </full-table>
        </div>
        <div v-show="showForm">
            <form-page ref="formPage" :fields="fields" @save="saveItem" @cancel="cancelShowForm" :title="editing ? texts.editTitle : texts.newTitle">
                <template v-slot:moreFormFields="{ item, index }">
                    <slot name="moreFormFields" :item="item" :index="index"></slot>
                </template>
                <template v-slot:moreFormActions="{ item, index }">
                    <slot name="moreFormActions" :item="item" :index="index"></slot>
                    <b-button v-if="showDelete && editInForm" size="sm" variant="secondary" @click.stop="deleteItem(item, index)" class="mr-1" right>
                        {{texts.deleteButton}}
                    </b-button>
                </template>
            </form-page>
        </div>
    </div>
</template>

<script type="text/javascript">

    import messages from '../tools/messages';

    export default {
        name: 'admin-page',
        props: ['actions', 'fields', 'texts', 'sortBy', 'updateReturnItem', 'noMainForm', 'editInForm', 'callbacks'],
        data() {
            return {
                showForm: false,
                items: [],
                editing: false,
                showErrors: false,
            }
        },
        async mounted() {
            this.loadDefaultTexts();
            await this.loadItems();
        },
        computed: {
            showDelete() {
                return (this.actions && this.actions.delete);
            },
        },
        methods: {
            loadDefaultTexts() {
                if (!this.texts) this.texts = {};
                if (!this.texts.listTitle) this.texts.listTitle = 'Items';
                if (!this.texts.newTitle) this.texts.newTitle = 'Add New Item';
                if (!this.texts.addButton) this.texts.addButton = 'Add item';
                if (!this.texts.deleteButton) this.texts.deleteButton = 'Remove';
                if (!this.texts.itemName) this.texts.itemName = 'item';
            },
            async loadItems() {
                this.items = await this.$store.dispatch(this.actions.get);
            },
            addNewItem() {
                this.editing = false;
                this.$refs.formPage.init();
                this.showForm = true;
            },
            async editItem({ item }) {
                if (this.callbacks && this.callbacks.beforeUpdate) {
                    item = this.callbacks.beforeUpdate(item);
                }
                if (this.callbacks && this.callbacks.beforeUpdateAsync) {
                    item = await this.callbacks.beforeUpdateAsync(item);
                }
                let result = await this.$store.dispatch(this.actions.update, item);
                if (result) {
                    var position = this.items.findIndex((i) => { return i.id == item.id });
                    if (position >= 0) {
                        this.$set(this.items, position, (this.updateReturnItem ? result : item));
                        this.$refs.fullTable.movePageToItem(item);
                        messages.showSaveOk(this.texts.itemName, 'updated');
                        this.$emit('updated', item);
                        this.$refs.fullTable.close();
                        this.showForm = false;
                    }
                }
            },
            async deleteItem(item) {
                var allowDelete = true;
                if (this.texts.askDelete) {
                    allowDelete = await messages.confirm('Delete ' + this.texts.itemName, this.texts.askDelete, null, 'warning', { cancel: 'No', confirm: 'Yes' });
                }
                if (allowDelete && allowDelete.isConfirmed) {
                    if (this.callbacks && this.callbacks.beforeDelete) {
                        item = this.callbacks.beforeDelete(item);
                    }
                    if (this.callbacks && this.callbacks.beforeDeleteAsync) {
                        item = await this.callbacks.beforeDeleteAsync(item);
                    }
                    let result = await this.$store.dispatch(this.actions.delete, { id: item.id });
                    if (result) {
                        var position = this.items.findIndex((i) => { return i.id == item.id });
                        if (position >= 0) {
                            this.items.splice(position, 1);
                            messages.showSaveOk(this.texts.itemName, 'deleted');
                            this.$emit('deleted', item);
                            this.showForm = false;
                        }
                    }
                }
            },
            async saveItem(item) {
                if (this.editing) {
                    this.editItem({ item });
                    return;
                }
                if (this.callbacks && this.callbacks.beforeInsert) {
                    item = this.callbacks.beforeInsert(item);
                }
                if (this.callbacks && this.callbacks.beforeInsertAsync) {
                    item = await this.callbacks.beforeInsertAsync(item);
                }
                let result = await this.$store.dispatch(this.actions.insert, item);
                if (result) {
                    this.items.push(result);
                    this.$refs.fullTable.movePageToItem(result);
                    messages.showSaveOk(this.texts.itemName, 'added');
                    this.showForm = false;
                    this.$emit('added', item);
                }
            },
            cancelShowForm() {
                this.showForm = false;
            },
            async showEdit(row) {
                this.editing = true;
                if (this.callbacks && this.callbacks.beforeShowEdit) {
                    row.item = this.callbacks.beforeShowEdit(row.item);
                }
                if (this.callbacks && this.callbacks.beforeShowEditAsync) {
                    row.item = await this.callbacks.beforeShowEditAsync(row.item);
                }
                this.$refs.formPage.init(row);
                this.showForm = true;
            },
        }
    }
</script>

<style>
</style>

