<template>
    <div class="video-viewer">
        <div v-if="returnPath" id="back" @click="goBack()">Back</div>
        <video v-if="videoPath" width="1280" height="720" id="video" controls autoplay>
            <source :src="videoPath" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="message" v-if="message">{{message}}</div>
    </div>
</template>
<script>

    export default {
        name: 'video-viewer',
        data() {
            return {
                videoPath: null,
                returnPath: null,
                message: null,
            }
        },
        async created() {
            this.loadConfig();
        },
        computed: {
        },
        methods: {
            loadConfig() {
                this.$store.state.showVideoViewer = true;
                var key = this.$route.params.key;
                if (key) {
                    var config = JSON.parse(atob(decodeURIComponent(key)));
                    if (config) {
                        this.returnPath = config.returnPath;
                        this.videoPath = config.videoPath;
                    }
                }
                if (!this.videoPath) {
                    this.message = 'There is no file to watch.';
                }
            },
            goBack() {
                this.$router.push(this.returnPath);
            },
        }
    }

</script>
<style scoped>
    #video {
        z-index: 100;
        width: 100%;
        height: 100%;
/*        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
*/        background-color: black;
    }
    #back {
        z-index: 101;
        position: fixed;
        right: 1rem;
        top: 1rem;
        background-color: rgba(255,255,255,0.25);
        color: white;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
    }
    #back:hover {
        background-color: rgba(255,255,255,0.7);
        color: black;
    }

</style>
