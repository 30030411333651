<template>
    <simple-modal ref="login" name="login" @opened="opened" dialog-class="login">
        <template slot="modal-header">
            <h5 class="modal-title">Welcome</h5>
        </template>
        <template slot="modal-content">
            <br />
            <label for="userKey">Pin / Email</label>
            <b-form-input ref="userKey" id="userKey" v-model.trim="userKey" placeholder="your pin or email"
                @keydown.enter.prevent="keyDown"></b-form-input>
            <p v-if="showErrors && !userKey" class="error">The pin / email field can't be empty.</p>
            <br />

            <label for="password">Password</label>
            <b-form-input ref="password" id="password" v-model.trim="password" placeholder="your password" type="password"
                @keydown.enter.prevent="keyDown"></b-form-input>
            <p v-if="showErrors && !password" class="error">The password field can't be empty.</p>
            <p v-if="loginError" class="error">The pin/email and/or password are not valid.</p>
        </template>
        <template slot="modal-buttons">
            <a id="forgottenPassword" href="#" @click.prevent="forgottenPassword">Forgotten password</a>
            <b-button variant="primary" size="sm" class="btn-login" @click.prevent="login">Login</b-button>
        </template>
    </simple-modal>
</template>
<script>

    export default {
        name: 'login',
        props: [],
        data() {
            return {
                userKey: '',
                password: '',
                rememberMe: false,
                showEmptyUserNameError: false,
                showErrors: false,
                loginError: false,
            }
        },
        created() {
        },
        async mounted() {
            this.$refs.login.show();
        },
        computed: {
            formIsValid() {
                return this.userKey && this.password;
            },
        },
        methods: {
            async login() {
                this.loginError = false;
                this.userKey = this.getUserKey();
                this.password = this.getPassword();
                this.showErrors = true;
                if (this.formIsValid) {
                    var result = await this.$store.dispatch('security/loginToken', { userKey: this.userKey, password: this.password });
                    this.loginError = !result;
                }
            },
            getUserKey() {
                return document.getElementById('userKey').value;
            },
            getPassword() {
                return document.getElementById('password').value;
            },
            async forgottenPassword() {
                this.$router.push('/forgotten-password');
            },
            opened() {
                this.$refs.userKey.focus();
            },
            keyDown() {
                this.userKey = this.getUserKey();
                this.password = this.getPassword();
                if (this.userKey && this.password) {
                    this.login();
                } else if (!this.userKey && !this.password) {
                    this.showErrors = true;
                } else if (!this.userKey) {
                    this.$refs.userKey.focus();
                } else {
                    this.$refs.password.focus();
                }
            }
        }
    }
</script>
<style>
    .login .modal-header {
        color: #f8f9fa !important;
        background-color: #343a40 !important;
    }

    .login .btn-login {
        width: 10rem;
    }
    .login #forgottenPassword {
        font-size: x-small;
    }

    .login .modal-footer {
        justify-content: space-between;
    }

</style>
