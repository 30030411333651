var api = $virtus.tools.api;

export default {
    namespaced: true,
    state: {
        rams: [],
        keepRams: false,
        lastSearch: {},
    },
    getters: {

    },
    mutations: {
        setRamIsRead(state, data) {
            if (data && data.ram) {
                var ramId = data.ram.ramId;
                var ram = state.rams.find(r => r.ramId == ramId);
                ram.isRead = data.isRead;
            }
        },
        setKeepRams(state, keepRams) {
            state.keepRams = keepRams;
            if (!keepRams) {
                state.lastSearch = {};
            }
        },
    },
    actions: {

        async getEmployeeRams({ state }, data) {
            let result = await api.get('/api/mywork/rams?assignmentLevelId=' + data.assignmentLevelId + '&onlyNotSigned=' + data.onlyNotSigned + '&filter=' + encodeURIComponent(data.filter ? data.filter : ''));
            result = result.map(r => {
                r.isRead = false;
                return r;
            });
            state.rams = result;
            state.lastSearch = { assignmentLevelId: data.assignmentLevelId, onlyNotSigned: data.onlyNotSigned, filter: data.filter };
        },

        async createSignatureReport(store, data) {
            var options = {
                dateTimeFields: ['signatureDate']
            };
            let result = await api.post('/api/mywork/signatures/report', data, options);
            return result;
        },

        async getEmployeeSops(store, data) {
            let result = await api.get('/api/mywork/sops?onlySopNotCompleted=' + data.onlySopNotCompleted + '&filter=' + encodeURIComponent(data.filter ? data.filter : ''));
            return result;
        },

        async getEmployeeSop(store, data) {
            let result = await api.get('/api/mywork/sops/' + data.ramId + '/' + (data.sopTestId ? data.sopTestId : ''));
            return result;
        },

        async saveEmployeeSop(store, data) {
            let result = await api.post('/api/mywork/sops/' + data.ramId + '/' + (data.sopTestId ? data.sopTestId : '0'), data.answers);
            return result;
        },

        async sendToValidationEmployeeSop(store, data) {
            let result = await api.post('/api/mywork/sops/' + data.ramId + '/' + (data.sopTestId ? data.sopTestId : '') + '/validate', data);
            return result;
        },

        async tryAgainEmployeeSop(store, data) {
            let result = await api.post('/api/mywork/sops/' + data.sopTestId + '/try-again');
            return result;
        },

        async getSopTestFile(store, data) {
            var url = '/api/mywork/sops/' + data.sopTestId + '/file?download=' + data.download + '&appId=2';
            let result = await (data.download ? api.download(url) : api.open(url));
            return result;
        },

        async getCounters() {
            let result = await api.get('/api/mywork/sops/counters');
            return result;
        },

    }
};
