<template>
    <div class="control control-datetime">
        <div class="control-label" v-if="label">{{label}}</div>
        <div v-if="readOnly" class="read-only">{{formattedDateTime}}</div>
        <date-picker type="datetime" v-model="dateValue" :placeholder="placeholder" :input-class="'form-control' + (showErrorState ? ' is-datepicker-invalid is-invalid' : '')"
                     :minute-step="5" :showSecond="false" format="DD/MM/YYYY HH:mm" @input="onInput"></date-picker>
        <div v-if="required && isEmpty" class="error">The field {{label}} is required.</div>
        <div v-if="errors">
            <div :key="index" v-for="(error, index) in errors" class="error">{{error !== true ? error : ''}}</div>
        </div>
    </div>
</template>

<script>
    import dates from '../tools/dates';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: 'control-datetime',
        props: ['label', 'value', 'placeholder', 'hasErrors', 'state', 'readOnly', 'errors', 'required'],
        components: { DatePicker },
        data() {
            return {
                dateValue: null
            };
        },
        created() {
            this.dateValue = this.value;
        },
        computed: {
            formattedDateTime() {
                return dates.formatDateTime(this.dateValue);
            },
            isEmpty() {
                return (this.dateValue === null || this.dateValue === undefined);
            },
            showErrorState() {
                return (this.errors || this.hasErrors || (this.required && this.isEmpty));
            },
        },
        watch: {
            value(newValue) {
                this.dateValue = newValue;
            }
        },
        methods: {
            onInput(newValue) {
                this.$emit('input', newValue);
            }
        }
    };
</script>

<style>
    .control-datetime input {
        cursor: pointer;
    }

    .control-datetime div.read-only {
        margin: 0.2rem 0;
        border-radius: 0.25rem;
        border: 1px solid grey;
        padding: 0.3rem 0.5rem;
        background-color: rgba(255, 255, 255, 0.4);
        flex: 1 1 auto;
        font-size: 0.9rem;
    }

    .control-datetime .el-date-editor.el-input {
        width: 100%;
    }

    input.is-datepicker-invalid.is-invalid {
        background-position: right 1.7rem center;
        padding-right: 2.5rem;
    }
</style>
