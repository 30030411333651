<template>
    <div id="loading" class="loading-mask" style="display:none;">
        <div class="loading-spinner">
            <p class="loading-text">
                <icon name="spinner" scale="4" pulse></icon>
                <br />
                Loading, please wait...
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'loading',
        props: [],
        data() {
            return {
            };
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    };
</script>

<style>
    #loading.loading-mask {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 2002;
        margin: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        -webkit-transition: opacity .3s;
        transition: opacity .3s;
    }
    #loading .loading-spinner {
        top: 50%;
        margin-top: -21px;
        width: 100%;
        text-align: center;
        position: absolute;
    }
    #loading .loading-spinner .loading-text {
        color: #409eff;
        margin: 3px 0;
        font-size: 14px;
    }

</style>
