<template>
    <div class="workers-rams">
        <h1 class="header-title">My RAMS Signatures</h1>

        <div class="header-items-bar">
            <control-text v-model="filter" placeholder="filter text" @keydown.enter.prevent="showRamsToSelect"></control-text>
            <control-checkbox v-model="onlyRamsNotSigned">Show only RAMS not signed</control-checkbox>
            <button type="button" class="btn btn-primary btn-sm ml-auto" @click="showRamsToSelect">Show RAMS</button>
        </div>

        <div class="clear"></div>
        <br />

        <div class="rams-select">
            <div class="list">
                <b-container fluid>
                    <b-row class="header">
                        <b-col cols="auto" class="title">RAMS to read and sign</b-col>
                        <b-col v-if="rams && rams.length > 0">
                            ({{rams.length}} rams{{unsignedRams.length > 0 ? ' / ' + unsignedRams.length + ' unsigned' : ''}})
                        </b-col>
                        <b-col cols="auto" v-if="selectedRams.length > 0">{{selectedRams.length}} rams selected</b-col>
                    </b-row>
                </b-container>
                <b-container class="items" fluid v-if="hasRams">
                    <b-row align-v="center" :key="index" v-for="(ram, index) in rams" :class="'item assignmentLevelId-' + ram.assignmentLevelId">
                        <b-col cols="auto" class="input-col cursor-hand" @click="markRam(ram)">
                            <icon v-if="!ram.isSigned && !ram.isRead" name="regular/square" class="input-icon square-icon"></icon>
                            <icon v-if="!ram.isSigned && ram.isRead" class="input-icon">
                                <icon name="regular/square" class="square-icon child-icon"></icon>
                                <icon name="check" scale="1.5" class="check-green-icon child-icon"></icon>
                            </icon>
                            <icon class="input-icon" v-if="ram.isSigned">
                                <icon name="regular/square" class="square-icon child-icon"></icon>
                                <icon name="check" scale="1" class="check-gray-icon child-icon"></icon>
                            </icon>
                        </b-col>
                        <b-col>
                            {{ram.reference}}. Version {{ram.version}}<br />
                            {{ram.description}}
                        </b-col>
                        <b-col v-if="ram.viewSeconds" cols="auto" class="fixed-width-100px">
                            [{{$virtus.tools.dates.formattedTime(ram.viewSeconds)}}]
                        </b-col>
                        <!--<b-col v-if="ram.hasVideo && ram.hasFile" cols="auto" class="ml-auto">
                            <video-button :video-path="getVideoPath(ram)" :primary="true"></video-button>
                        </b-col>
                        <b-col v-if="!ram.hasVideo && ram.hasFile" cols="auto" class="ml-auto"><button type="button" class="btn btn-secondary btn-sm" @click="downloadRam(ram, false)">Open</button></b-col>
                        <b-col v-if="!ram.hasVideo && ram.hasFile" cols="auto"><button type="button" class="btn btn-primary btn-sm" @click="downloadRam(ram, true)">Download</button></b-col>-->
                        <b-col v-if="ram.hasFile || ram.hasVideo" cols="auto" class="fixed-width-100px">
                            <ram-viewer-button :ram-id="ram.ramId" :has-video="ram.hasVideo" :show-timer="true" text="Open" primary></ram-viewer-button>
                        </b-col>
                        <b-col v-else cols="auto" class="fixed-width-100px">No file</b-col>
                    </b-row>
                </b-container>
                <div class="legend" v-if="hasRams">
                    <p><i>Click a checkbox to mark a RAMS as read. When you finish click on Sign RAMS button to sign selected RAMS.</i></p>
                </div>
                <div class="no-items" v-if="!hasRams">There are no RAMS to show.</div>
            </div>
        </div>

        <div class="buttons">
            <button type="button" class="btn btn-primary btn-sm" :disabled="noRamsSelected" @click="goToSignPage">Sign RAMS</button>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        data() {
            return {
                filter: null,
                onlyRamsNotSigned: true,
            };
        },
        async mounted() {
            if (this.keepRams && this.lastSearch) {
                this.filter = this.lastSearch.filter;
                this.onlyRamsNotSigned = this.lastSearch.onlyNotSigned;
                this.$store.commit('mywork/setKeepRams', false);
            } else {
                await this.showRamsToSelect();
            }
        },
        computed: {
            ...mapState('mywork', ['rams', 'keepRams', 'lastSearch']),
            ...mapState('security', ['userName']),
            noRamsSelected() {
                for (var i in this.rams) {
                    if (this.rams[i].isRead) {
                        return false;
                    }
                }
                return true;
            },
            unsignedRams() {
                var signed = (this.rams ? this.rams.filter(ram => !ram.isSigned) : []);
                return signed;
            },
            selectedRams() {
                var selected = (this.rams ? this.rams.filter(ram => ram.isRead) : []);
                return selected;
            },
            hasRams() {
                return (this.rams && this.rams.length);
            },
        },
        methods: {
            async showRamsToSelect() {
                await this.$store.dispatch('mywork/getEmployeeRams', {
                    assignmentLevelId: 1,
                    onlyNotSigned: this.onlyRamsNotSigned,
                    filter: this.filter,
                });
            },
            goToSignPage() {
                this.$router.push('/mywork/rams/sign');
            },
            async downloadRam(ram, download) {
                var result = await this.$store.dispatch('rams/getRamsFile', { ramId: ram.ramId, download });
                if (result) {
                    $virtus.tools.messages.showSaveOk('rams file', 'downloaded');
                }
            },
            markRam(ram) {
                this.$store.commit('mywork/setRamIsRead', { ram, isRead: !ram.isRead});
            },
            getVideoPath(ram) {
                return '/videos/rams/ram-' + ram.ramId + '.mp4';
            },
        }
    }
</script>
<style>

    .workers-rams .header-items-bar {
        display: flex;
        justify-content: space-between;
        padding: 5px !important;
        background-color: rgba(255,255,255,0.4);
        align-items: center;
        flex-wrap: wrap;
    }

    .workers-rams .header-items-bar .control {
        margin: 0 0.5rem 0 0;
    }

    .workers-rams .header-items-bar table,
    .workers-rams .header-items-bar button {
        margin: 0.5rem;
    }

    .workers-rams .btn {
        padding: 0 10px;
        border-radius: 10px;
    }

    .workers-rams .col-auto {
        padding: 5px;
    }

    .workers-rams .no-items {
        margin: 0.5rem;
        font-style: italic;
    }

    .workers-rams .items {
        overflow-y: auto;
        max-height: 30rem;
    }

    .workers-rams .item {
        padding: 1px 5px 1px 10px;
    }

    .workers-rams .item:nth-child(even) {
        background-color: rgba(255,255,255,0.5);
    }

    .workers-rams .item:nth-child(odd) {
        background-color: rgba(255,255,255,0.2);
    }

    .workers-rams .rams-select .header {
        background-color: #8b9870;
        color: white;
        align-items: center;
    }    

    .workers-rams .header div {
        display: inline-block;
    }

    .input-icon {
        width: 1.5rem;
        height: 1.5rem;
        overflow: visible;
    }
    .input-col {
        padding: 0 !important;
    }
    .square-icon {
        color: darkgray;
    }
    .check-green-icon {
        color: forestgreen;
    }
    .check-gray-icon {
        color: darkgray;
    }
    .items span {
        margin-left: 0.2rem;
        display: inline-block;
    }
    .rams-selected {
        margin-left: auto;
    }
    .workers-rams .fixed-width-100px {
        width: 100px;
        text-align: center;
    }
</style>
