import axios from 'axios';

axios.defaults.withCredentials = true;

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        var store = $virtus.store;
        if (store) {
            config.headers['virtus-app-id'] = store.getters['security/getAppId'];
            config.headers['virtus-site-id'] = store.getters['security/getSiteId'];
            var token = store.getters['security/getToken'];
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


//Add a response interceptor
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error && error.response && error.response.status === 401 && error.config) {
            var url = new URL(error.config.url);
            if (url.pathname != '/api/security/logout' && url.pathname != '/api/security/login') {
                $virtus.store.dispatch('security/goToLogin');
            }
        }
        return Promise.reject(error);
    }
);

