import swal from 'sweetalert2';

//Add styles for SweetAlert2
var style = document.createElement('style');
style.type = 'text/css';
style.innerHTML = '.swal2-popup.swal2-toast .swal2-title { margin: 0; } .swal2-popup.swal2-toast .swal2-html-container { margin: 0; }';
var head = document.getElementsByTagName('head')[0];
head.appendChild(style);

let messages = {
    success(title, text) {
        return swal.fire({ title: title, text: text, icon: "success" });
    },
    error(title, text) {
        return swal.fire({ title: title, text: text, icon: "error" });
    },
    info(title, text) {
        return swal.fire({ title: title, text: text, icon: "info" });
    },
    confirm(title, text, dangerMode, icon) {
        return swal.fire({
            title: title,
            text: text,
            icon: (icon || "warning"),
            showCancelButton: true,
            focusConfirm: !dangerMode,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: (dangerMode ? '#e64942' : '#3085d6'),
        });
    },
    showSaveOk(entityName, action) {
        return swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success!',
            text: 'The ' + entityName + ' has been ' + action + ' successfully.',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            toast: true,
        });
    },
    showMessage(title, message) {
        return swal.fire({
            position: 'top-end',
            icon: 'success',
            title: title,
            text: message,
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            toast: true,
        });
    },
    askDelete(entityName) {
        return swal.fire({
            title: 'Delete ' + entityName,
            icon: "warning",
            text: 'This action will delete the ' + entityName + '. Are you sure?',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#e64942',
        });
    },
    askDeleteReason() {
        return swal.queue([{
            title: 'Delete document',
            icon: "warning",
            text: 'This action will delete the document. Do you agree?',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            confirmButtonColor: '#e64942',
        }, {
            title: 'Reason to delete',
            icon: "question",
            text: 'Please, enter the reason to delete the document',
            input: 'text',
            inputPlaceholder: 'enter reason to delete',
            showCancelButton: true,
            focusConfirm: false,
            confirmButtonText: 'Delete',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#e64942',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write the reason!';
                }
            }
        }]);
    },

};

export default messages;
